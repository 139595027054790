import React, {useState} from 'react';
import useLang from "../../../../../src_shared/hooks/useLang";
import SelmoPaczkaDataModal from "./components/dataForm/SelmoPaczkaDataModal";
import OrlenSelmoPaczka from "./components/orlen/OrlenSelmoPaczka";
import DpdSelmoPaczka from "./components/dpd/DpdSelmoPaczka";
import GeneratedLabel from "./components/shared/GeneratedLabel";
import SelmoPaczkaCreateShipping from "./components/dataForm/SelmoPaczkaCreateShipping";

const SelmoPaczka = ({data, fetchData}) => {
    const {getLangText} = useLang();
    const isQualified = data.sp_active;
    const [showDataFormModal, setShowDataFormModal] = useState(false);
    const [showCreateShippingModal, setShowCreateShippingModal] = useState(false);

    const BoxHeader = () => (
        <div className="flex items-center justify-between">
            <div>
                <div className="font-bold">
                    Selmo Paczka
                </div>
                <div className="text-[13px] font-medium text-desc">{getLangText('theLowestPricesForShipping')}</div>
            </div>
            <div className="ml-auto">
                <img className="w-[96px]" src="/assets/images/svg/selmo-paczka-logo.svg" alt="Selmo Paczka"/>
            </div>
        </div>
    )


    if (!data.shipping_address_exist) {
        return (
            <div className="summary-section mb-3 border-2 border-[#5FA5FA]">
                {BoxHeader()}
                <div
                    className="form-info-box align-items-center w-100 small-line-height mt-3">
                    <div>
                        <i className="icon-info-c"/>
                    </div>
                    <div>
                        <div className="title">{getLangText('noDeliveryAddressLabel')}</div>
                    </div>
                </div>
            </div>
        )
    }

    // if ((isQualified === null || !isQualified)) {
    //     return (
    //         <div className="summary-section mb-3 border-2 border-[#5FA5FA]">
    //             {BoxHeader()}
    //             <img src="/assets/images/box-promo.webp" alt=""/>
    //             {!userData.user_info.premium_date ?
    //                 <button
    //                     type="button"
    //                     disabled={true}
    //                     className="button primary not-active w-full mt-2"
    //                 >
    //                     {getLangText('availableOnlyForSelmoClients')}
    //                 </button> :
    //                 <button
    //                     type="button"
    //                     onClick={() => setShowDataFormModal(true)}
    //                     className="button primary w-full mt-2"
    //                 >
    //                     {getLangText('trySelmoPaczka')}
    //                 </button>
    //             }
    //             {showDataFormModal &&
    //                 <SelmoPaczkaDataModal
    //                     data={data}
    //                     fetchData={fetchData}
    //                     showModal={showDataFormModal}
    //                     hide={() => setShowDataFormModal(false)}
    //                 />
    //             }
    //         </div>
    //     )
    // }
    //
    // if (+isQualified === 0) {
    //     return (
    //         <div className="summary-section mb-3 border-2 border-[#5FA5FA]">
    //             {BoxHeader()}
    //             <div className="grid items-center grid-cols-2 py-3 ">
    //                 <div className="text-2xl font-bold">
    //                     Dziękujemy <br/>za zgłoszenie
    //                 </div>
    //                 <div>
    //                     <img
    //                         className="w-[90%] ml-auto"
    //                         src="/assets/images/selmo-box-01.webp" alt="selmo box"/>
    //                 </div>
    //             </div>
    //             <div
    //                 className="form-info-box w-100 small-line-height">
    //                 <div>
    //                     <i className="icon-info-c"/>
    //                 </div>
    //                 <div>
    //                     <div className="title">Nasz zespół analizuje Twoje zgłoszenie</div>
    //                     <div className="desc">Po akceptacji, otrzymasz dostęp</div>
    //                 </div>
    //             </div>
    //         </div>
    //     )
    // }

    if (data?.sp_label_info?.label_type) {
        return (
            <div className="summary-section mb-3">
                {BoxHeader()}
                <GeneratedLabel
                    data={data}
                    fetchData={fetchData}
                />
            </div>
        )
    }

    if (!data?.sp_prices?.dpd?.length && !data?.sp_prices?.dpd_pickup?.length && !data?.sp_prices?.orlen_paczka?.length) return null;

    return (
        <div className="summary-section mb-3 border-2 border-[#5FA5FA]">
            {BoxHeader()}
            {(!!data?.sp_prices?.dpd?.length || !!data?.sp_prices?.dpd_pickup?.length) &&
                <DpdSelmoPaczka
                    data={data}
                    fetchData={fetchData}
                    options={!!data?.sp_prices?.dpd?.length ? data?.sp_prices?.dpd : data?.sp_prices?.dpd_pickup}
                    isCourier={!!data?.sp_prices?.dpd?.length}
                    showDataModal={() => setShowDataFormModal(true)}
                    isQualified={isQualified}
                />
            }
            {!!data?.sp_prices?.orlen_paczka?.length &&
                <OrlenSelmoPaczka
                    type="orlen"
                    data={data}
                    fetchData={fetchData}
                    options={data?.sp_prices?.orlen_paczka || []}
                    showDataModal={() => setShowDataFormModal(true)}
                    isQualified={isQualified}
                />
            }
            {showDataFormModal &&
                <SelmoPaczkaDataModal
                    fetchData={fetchData}
                    showModal={showDataFormModal}
                    setShowCreateShippingModal={setShowCreateShippingModal}
                    hide={() => setShowDataFormModal(false)}
                />
            }
            {showCreateShippingModal &&
                <SelmoPaczkaCreateShipping
                    showModal={showCreateShippingModal}
                    hide={() => setShowCreateShippingModal(false)}
                    fetchData={fetchData}
                />
            }
        </div>
    );
};

export default SelmoPaczka;
