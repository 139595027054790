import React, {useEffect, useState} from 'react';
import useLang from "../../../../../../src_shared/hooks/useLang";
import {useSelector} from "react-redux";
import {useUnitPrice} from "../../../../shared/helpers/Price";
import CopyButton from "../../../../../../src_shared/components/form/copyButton/CopyButton";
import SwitchField from "../../../../../../src_shared/components/form/switchField/SwitchField";
import ValidationError from "../../../../../../src_shared/form/validation/ValidationError";

const DescriptionField = ({setValue, data}) => {
    const {getLangText} = useLang();
    const {product_code: productCode} = useSelector((state) => state.session.userData);
    const defaultFooter = getLangText('orderHandledBySelmoSystemLabel')
    const {getPrice} = useUnitPrice();

    const defaultValues = {
        text: '',
        inStock: '1',
        showPrices: '0',
        withVariants: '1',
    }

    const [values, setValues] = useState(defaultValues)

    const isInStock = !!+values.inStock;

    const preparedProducts = isInStock
        ? data?.products?.map((product) => {
            const hasSizes = !!+product.has_sizes;
            if (hasSizes) {
                const filteredSizes = product.sizes?.filter((size) => !!+size.squ);
                return {...product, sizes: filteredSizes};
            } else {
                return !!+product.squ ? product : null;
            }
        }).filter(Boolean)
        : data.products;

    const getTotalProductsWithLabel = (product) => !+values.showPrices ? `\n\n${getLangText('priceLabel')}: ${getPrice(product.price)}` : '';

    const productNoVariants = (product) => `${productCode} ${product.selmo_code} ${!+values.showPrices ? '' : `- ${getPrice(product.price)}`}${getTotalProductsWithLabel(product)}`
    const productsVariants = (product) => `${productCode} ${product.selmo_code_size} ${!+values.showPrices ? '' : `- ${getPrice(product.price)}`}`

    const products = preparedProducts?.map((product) =>
        `${getLangText({
            key: 'toOrderProductTextInCommentLabel',
            data: [product.name]
        })}\n${productNoVariants(product)}\n`);

    const productsWithVariants = preparedProducts?.map((product) =>
        `${getLangText({
            key: 'toOrderProductTextInCommentLabel',
            data: [product.name]
        })}\n\n${!!+product.has_sizes ? `${product?.sizes?.map(productsVariants).join('\n')}${getTotalProductsWithLabel(product)}` : productNoVariants(product)}\n`)

    const defaultTextWithVariant = `${productsWithVariants.join('\n')}\n${defaultFooter}`
    const defaultText = `${products.join('\n')}\n${defaultFooter}`

    useEffect(() => {
        setValue('facebook_post_description', !!+values.withVariants ? defaultTextWithVariant : defaultText)
        // setValues({...values, text: !!+values.withVariants ? defaultTextWithVariant : defaultText})
    }, [values.inStock, values.showPrices, values.withVariants, values.inStock])

    return (
        <div className="form-group">
            <label className="control-label">{getLangText('input_label_package_type_description')}</label>
            <div className="border-area">
                <textarea
                    className="form-control mb-0"
                    name="facebook_post_description"
                    rows="10"
                    value={data.facebook_post_description}
                    onChange={(e) => setValue('facebook_post_description', e.target.value)}
                />
                <div className="p-3 pt-4 rounded-b-[5px] bg-[#F9FAFB] mt-3">
                    <SwitchField
                        className="switch-form inline-label align-items-start"
                        label={getLangText('variantsInStockLabel')}
                        subLabel={getLangText('variantsInStockSubLabel')}
                        setValue={(field, value) => setValues({...values, inStock: value})}
                        value={values.inStock}
                        validateErrors={false}
                        getError={ValidationError.skip}
                    />
                    <div>
                        <SwitchField
                            className="switch-form inline-label align-items-start"
                            label={getLangText('showPricesLabel')}
                            subLabel={getLangText('showPricesInVariantsLabel')}
                            setValue={(field, value) => setValues({...values, showPrices: value})}
                            value={values.showPrices}
                            validateErrors={false}
                            getError={ValidationError.skip}
                        />
                    </div>
                    <div>
                        <SwitchField
                            className="switch-form inline-label align-items-start mb-0"
                            label={getLangText('productsWithVariants')}
                            subLabel={getLangText('showProductsWithVariants')}
                            setValue={(field, value) => setValues({...values, withVariants: value})}
                            value={values.withVariants}
                            validateErrors={false}
                            getError={ValidationError.skip}
                        />
                    </div>
                    <div className="bottom-part justify-content-end">
                        <CopyButton
                            copyButtonText={getLangText('copyButton')}
                            copiedButtonText={getLangText('copiedLabel')}
                            copyText={data.facebook_post_description}
                            withIcon={false}
                            withTooltip={false}
                            buttonClassName="border-button copied"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DescriptionField;
