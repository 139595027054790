import React, {useEffect, useState} from 'react';
import Modal from "react-bootstrap/Modal";
import Loader from "../../../../../src_shared/components/loader/Loader";
import useLang from "../../../../../src_shared/hooks/useLang";
import {services} from "../../../../RestServices";
import AsyncSearchSelectField
    from "../../../../../src_shared/components/form/selectField/asyncSearchSelectField/AsyncSearchSelectField";
import {useParams} from "react-router-dom";
import useSubmitData from "../../../../../src_shared/hooks/useSubmitData";
import QRCodeView from "./components/QRCodeView";
import useWindowDimensions from "../../../messenger/hooks/useWindowDimensions/useWindowDimensions";
import {useDispatch} from "react-redux";
import {showAlert} from "../../../shared/components/alert/AlertActions";

const SCANNER_VIEW = 'SCANNER_VIEW';
const SELECT_VIEW = 'SELECT_VIEW';

const AssignToPlace = ({
                           modalVisible, hideModal, selectedVariants = [], fetchData = () => {
    }, setSelectedVariants = () => {
    },
                           rest = services.API_WAREHOUSE_PRODUCT,
                           orderId = null,
                           withScanner = true,
                       }) => {
    const {getLangText} = useLang();
    const {id, variant_id} = useParams();
    const [activeView, setActiveView] = useState(SELECT_VIEW)
    const {width} = useWindowDimensions();
    const dispatch = useDispatch();
    const [shelfId, setShelfId] = useState(null);

    const setSelectView = () => setActiveView(SELECT_VIEW);

    const [{onSave, isWaiting}] = useSubmitData(
        {
            rest: `${rest}/${orderId || id}`,
            values: {
                shelf_id: shelfId,
                selected_variants: selectedVariants,
                product_size_id: variant_id || null,
            },
            afterSubmit: () => {
                hideModal();
                fetchData();
                setSelectedVariants([]);
            }
        });

    const handleQrCodeScanner = async (result) => {
        const parsed = JSON.parse(result);
        if (parsed.shelf_id) {
            try {
                await onSave(null, {shelf_id: parsed.shelf_id});
                dispatch(showAlert('yuhuLabel', 'successfullyAssignedPlaceLabel'))
            } catch (e) {
                dispatch(showAlert('sthWentWrongWarning', '', 'alert-fail'))
            }
        } else {
            dispatch(showAlert('sthWentWrongWarning', 'Zły QR Kod', 'alert-fail'))
        }
    }

    return (
        <Modal
            dialogClassName="default-modal h-full"
            contentClassName="md:h-auto h-full"
            show={modalVisible}
            onHide={hideModal}
        >
            <Modal.Header>
                <Modal.Title>
                    Przypisz miejsce
                </Modal.Title>
                <button
                    onClick={hideModal}
                    type="button"
                    className="button"
                >
                    <i className="icon-cross"/>
                </button>
            </Modal.Header>
            <form className="grow flex flex-col" onSubmit={onSave}>
                <Modal.Body className="pt-3 pb-5 grow">
                    {activeView === SCANNER_VIEW ?
                        <QRCodeView
                            isWaiting={isWaiting}
                            handleResult={handleQrCodeScanner}
                            setSelectView={setSelectView}
                        /> :
                        <>
                            <AsyncSearchSelectField
                                id="custom-dropdown-default"
                                className="default-size big-size"
                                name="shelf_id"
                                setValue={(field, value) => setShelfId(value)}
                                value={shelfId}
                                label={getLangText('localizationLabel')}
                                rest={`${services.API_WAREHOUSE_SHELVES}`}
                                optionsParser={(options) => options?.map((i) => ({
                                    ...i,
                                    name: i.shelf
                                }))}
                                floatedLabel
                                searchOnFocus
                            />
                            {width < 991 && withScanner &&
                                <>
                                    <div className="or-separator text-center">
                                        <div>{getLangText('separatorOrLabel')}</div>
                                    </div>
                                    <button
                                        className="button border-button w-full small-size left-icon py-3"
                                        onClick={() => setActiveView(SCANNER_VIEW)}
                                        type="button"
                                    >
                                        <i className="icon-qr-code"/>
                                        Zeskanuj miejsce
                                    </button>
                                </>
                            }
                        </>
                    }
                </Modal.Body>
                {activeView !== SCANNER_VIEW &&
                    <Modal.Footer>
                        <button
                            onClick={hideModal}
                            type="button"
                            className="button border-button"
                        >
                            {getLangText('cancelButton')}
                        </button>
                        <button
                            type="submit"
                            className={`button primary ${isWaiting ? 'loading-button' : ''}`}
                        >
                            {isWaiting && <Loader isLoading/>}
                            {getLangText('saveButton')}
                        </button>
                    </Modal.Footer>
                }
            </form>
        </Modal>
    );
};

export default AssignToPlace;
