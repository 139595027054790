import React from "react";
import ListActions from "./ListActions";
import {getStateRoot} from "./listReducer";
import Table from "./components/Table";
import useLang from "../../../../src_shared/hooks/useLang";
import SearchInput from "../../shared/components/form/SearchInput";
import Pagination from "../../shared/components/pagination/Pagination";
import useList from "../../../../src_shared/list/useList";
import ConfirmRemoveModal from "../../../../src_shared/components/modal/ConfirmRemoveModal";
import FirstStepPage from "./components/FirstStepPage";
import ComponentLoader from "../../../../src_shared/components/ui/componentLoader/ComponentLoader";
import {useHistory} from "react-router-dom";
import {RouterPaths} from "../../../routes/RouterPath";
import useSubmitData from "../../../../src_shared/hooks/useSubmitData";
import {services} from "../../../RestServices";
import Loader from "../../messenger/loader/Loader";

const SalesPostList = () => {
    const {getLangText} = useLang();
    const history = useHistory();

    const {
        pagination: {
            changePage,
            getPagination,
            itemsPerPage,
            nextPage,
            prevPage,
            totalItems,
            updateItemsPerPage
        },
        list: {items, isWaiting},
        search,
        sort: {updateSortOptions, getSortClass},
        remove: {askForRemoveItem, isModalVisible, cancelItemRemoval, markedToRemove, confirmItemRemoval},
    } = useList({
        ListActions,
        getStateRoot,
    });

    const [{onSave: createPost, isWaiting: isWaitingForPost}] = useSubmitData({
        rest: services.API_VIDEO_SOCIAL_VIDEOS,
        afterSubmit: (response) => history.push(`${RouterPaths.SalesPost}/${response.id}`)
    });

    if (isWaiting && !search.searchText) return <ComponentLoader/>

    if (!items?.length && !search.searchText) return (
        <FirstStepPage
            createPost={createPost}
            isWaitingForPost={isWaitingForPost}
        />
    )

    return (
        <>
            <div className="table-list-wrapper">
                <div className="default-shadow-box pb-0">
                    <div className="table-header">
                        <div className="top-part flex-sm-nowrap flex-wrap">
                            <h1 className="title mb-sm-0 mb-2">
                                {getLangText('salesPost')}
                                {totalItems > 0 &&
                                    <div className="badge">{totalItems}</div>
                                }
                            </h1>
                            <div className="sm:ml-auto flex items-center sm:w-auto w-full">
                                <SearchInput
                                    className="sm:grow-0 grow full-sm-width-input"
                                    placeholder={getLangText('searchPlaceholder')}
                                    search={search}
                                />
                                <button
                                    onClick={createPost}
                                    type="button"
                                    className={`button primary ml-2 ${isWaitingForPost ? 'loading-button' : ''}`}
                                >
                                    {isWaitingForPost && <Loader/>}
                                    {getLangText('addPost')}
                                </button>
                            </div>
                        </div>
                    </div>
                    {isWaiting ?
                        <ComponentLoader/> :
                        <Table
                            isWaiting={isWaiting}
                            items={items}
                            updateSortOptions={updateSortOptions}
                            getSortClass={getSortClass}
                            askForRemoveItem={askForRemoveItem}
                            markedToRemove={markedToRemove}
                        />
                    }
                </div>
                <Pagination
                    getLangText={getLangText}
                    pages={getPagination()}
                    items={items}
                    itemsPerPage={itemsPerPage}
                    totalItems={totalItems}
                    onPrevPage={prevPage}
                    onChangePage={changePage}
                    onNextPage={nextPage}
                    onChangeItemsPerPage={updateItemsPerPage}
                    classes={{
                        prevIcon: 'icon-chevron left',
                        nextIcon: 'icon-chevron right',
                    }}
                    showItemsPerPageSelect
                />
            </div>
            {isModalVisible &&
                <ConfirmRemoveModal
                    show={isModalVisible}
                    title={getLangText('delete_modal_accept')}
                    subTitle={`${markedToRemove?.shelf}`}
                    hide={cancelItemRemoval}
                    confirm={confirmItemRemoval}
                    dialogClassName="w-420"
                />
            }
        </>
    );
};

export default SalesPostList;
