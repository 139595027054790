import React, {useEffect} from 'react';
import {useSelector} from "react-redux";
import {useIntercom} from "react-use-intercom";
import moment from "./shared/moment";
import {getFromLS} from "./shared/helpers/ls";
import {hotjar} from "react-hotjar";
import {NANA_SHOP_ID, PIANO_FASHION_SHOP_ID, SHOP_BAZAR_SHOP_ID} from "../../src_shared/enums/TrustedShopsIds";
import useWindowDimensions from "./messenger/hooks/useWindowDimensions/useWindowDimensions";
import usePusherEvents from "./shared/pusherEvents/usePusherEvents";
import {usePostHog} from "posthog-js/react";
import {Redirect, useLocation} from "react-router-dom";
import {RouterPaths} from "../routes/RouterPath";
import Plausible from "plausible-tracker";
import ReactPixel from 'react-facebook-pixel';
import * as Sentry from '@sentry/react';
import {convertToUserTimeZone} from "./shared/helpers/dateHelpers";

export const posthogOptions = {
    api_host: 'https://app.posthog.com',
}

const AppWrapper = ({children}) => {

    usePusherEvents();

    const {userData, isTutorial, waitingForResponse} = useSelector((state) => state.session);
    const {width} = useWindowDimensions();
    const {lang} = useSelector((state) => state.i18n);
    const {pathname} = useLocation();
    const {boot, shutdown, update} = useIntercom();
    const posthog = usePostHog();

    const plausible = Plausible({
        domain: 'app.selmo.io',
    })

    plausible.trackPageview();

    useEffect(() => {
        Sentry.setUser({email: userData.email, id: userData.id});
    }, [userData])

    useEffect(() => {
        if (userData.id && posthog) {
            posthog?.identify(userData.id, {
                name: userData.name,
                email: userData.email,
                shop_id: userData.id,
                lang: userData.lang
            })
        }
    }, [posthog, userData])

    useEffect(() => {
        if (width < 767) {
            shutdown();
        }
    }, [width])

    useEffect(() => {
        if (!!userData.intercom_hash && width > 767) {
            boot({
                email: userData.email,
                userId: userData.id,
                name: userData.name,
                userHash: userData.intercom_hash,
                languageOverride: lang,
                avatar: {
                    type: 'avatar',
                    imageUrl: userData.logo
                }
            });
        }
    }, [userData.intercom_hash, width])

    useEffect(() => {
        return () => shutdown();
    }, [])

    useEffect(() => {
        update({
            languageOverride: lang,
        })
    }, [lang])


    useEffect(() => {
        ReactPixel.init('1019873831899249');
        ReactPixel.pageView();

        if (convertToUserTimeZone(getFromLS('hotjarExpiredDate')).toDate() > convertToUserTimeZone().toDate()) {
            hotjar.initialize(3450973, 6);
        }

    }, [])

    useEffect(() => {
        ReactPixel.track('ViewContent');
    }, [pathname])

    useEffect(() => {
        if (+userData.id === PIANO_FASHION_SHOP_ID || +userData.id === SHOP_BAZAR_SHOP_ID || +userData.id === NANA_SHOP_ID) {
            hotjar.initialize(3517223, 6);
        }
    }, [userData.id])

    useEffect(() => {
        if (hotjar.initialized() && (+userData.id === PIANO_FASHION_SHOP_ID || +userData.id === SHOP_BAZAR_SHOP_ID || +userData.id === NANA_SHOP_ID)) {
            hotjar.identify(userData.id, {name: userData.name});
        }
    }, [hotjar.initialized(), userData.id])


    if ((userData.notValid && !userData.access_without_fanpage) && !waitingForResponse) {
        return <Redirect to={RouterPaths.RefreshToken}/>
    }

    if ((isTutorial || userData.isTutorial) && !waitingForResponse) {
        return <Redirect to={RouterPaths.Tutorial}/>;
    }

    return children;
};

export default AppWrapper;
