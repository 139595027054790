import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import StylesLoader from "../../loader/Loader";
import useLang from "../../../hooks/useLang";
import {selmoUrl} from "../../../api/api";
import {showAlert} from "../../../../components/app/shared/components/alert/AlertActions";
import api from "../../../../services/axios/axios";
import {setWaitingForSendAction} from "./WaitingForSendActions";

const WaitingForSend = ({
                            rest,
                            small = false,
                            title = 'summariesAreBeingSentLabel',
                            desc = 'itMakeTakeUpFiveMinLabel',
                            className = '',
                            field,
                            fetchData,
                        }) => {
    const {getLangText} = useLang();
    const dispatch = useDispatch();
    const [waiting, setWaiting] = useState(false);

    const waitingForSend = useSelector((state) => state.shared.waitingForSend);

    const checkStatus = async () => {
        setWaiting(true)
        try {
            const {data} = await api.get(`${selmoUrl}/${rest}`)
            if (data.waitingForSend) {
                dispatch(showAlert('stillSendingMessagesLabel', 'tryAgainInWhileLabel', 'alert-info'))
            } else {
                fetchData();
                dispatch(setWaitingForSendAction(field, null));
            }

        } catch (e) {
            console.warn('Cannot check status')
        } finally {
            setWaiting(false)
        }
    }

    useEffect(() => {
        if (waitingForSend[field] === false) {
            fetchData();
            dispatch(setWaitingForSendAction(field, null));
        }
    }, [waitingForSend[field]])


    return (
        <div className={`default-shadow-box summary-box mb-3 loading-box ${className} ${small ? 'small-size' : ''}`}>
            <div className="d-flex align-items-center w-100">
                <StylesLoader className="static-loader w-auto h-auto mr-3" isLoading={true}/>
                <div className="mr-2">
                    <div className="title small-size mb-0">
                        <div className={`${small ? 'leading-[18px]' : ''}`}>{getLangText(title)}</div>
                    </div>
                    <div className="sub-title extra-small-text">{getLangText(desc)}</div>
                </div>
                <button
                    onClick={checkStatus}
                    type="button"
                    className={`button border-button small-size ml-auto ${waiting ? 'loading-button' : ''}`}
                >
                    <StylesLoader isLoading={waiting}/>
                    {getLangText('refreshLabel')}
                </button>
            </div>
        </div>
    );
};

export default WaitingForSend;
