import React, {useEffect} from 'react';
import NoteForm from "../../note/Form";
import {Link} from "react-router-dom";
import {RouterPaths} from "../../../../../routes/RouterPath";
import ClientName from "./ClientName";
import useForm from "../../../../../../src_shared/form/useForm";
import FormActions from "../../note/FormActions";
import ClientPaymentReceived from "../../../../clients/details/info/clientPaymentReceived/ClientPaymentReceived";
import {useSelector} from "react-redux";
import useLang from "../../../../../../src_shared/hooks/useLang";
import {TIM_SHOP_ID} from "../../../../../../src_shared/enums/TrustedShopsIds";
import {ORDER_PAYMENT_BM_TRANSFER} from "../../../../shared/enums/PaymentStatuses";

const ClientInfo = ({data, mobile}) => {
	const {userData} = useSelector((state) => state.session);
	const {getLangText} = useLang();

	const {
		data: {values, setValue, updateValues},
		form: {onSave, getFieldProps, isWaiting},
		modal: {showModal, hideModal, modalVisible}
	} = useForm({
		FormActions,
		params: {
			id: data.id,
		},
		preventAutoLoad: true,
	});

	useEffect(() => {
		updateValues({
			id: data.id,
			tracking_link: data.shippingData.tracking_link,
			note: data.shippingData.note,
			payment_received: data.shippingData.payment_received,
			tags: data.tags
		})
	}, [data])

	const isPaypal = data.shippingData.payment === 'paypal';
	const isTransfer = data.shippingData.payment === 'transfer' || data.shippingData?.payment?.includes('transfer_') || (+userData.id === TIM_SHOP_ID && data.shippingData.payment === ORDER_PAYMENT_BM_TRANSFER);

	return (
		<>
			<section className={`summary-section mt-md-0 mt-3 mb-3 ${mobile ? 'mobile-summary-section' : ''}`}>
				<section className="full-border">
					<div className="avatar-box">
						<ClientName
							userData={userData}
							values={data}
						/>
						{data.conversation_id &&
							<>
								<Link
									to={`${RouterPaths.Messenger}${data.conversation_id}/`}
									class={`button go-to-conversation ${!data.conversation_viewed ? 'important' : ''} mt-4`}
								>
									<i className="icon-messenger"/>
									{getLangText('goToConversationButton')}
								</Link>
								{!data.conversation_viewed &&
									<div className="info-text mt-2">
										<i className="icon-alert-c"/>
										{getLangText('youHaveUnReadMessageLabel')}
									</div>
								}
							</>
						}
					</div>
				</section>
				<NoteForm
					getFieldProps={getFieldProps}
					onSave={onSave}
					values={values}
					setValue={setValue}
				/>
			</section>
			{((isPaypal || isTransfer) && !!+userData.show_payment_received) &&
				<ClientPaymentReceived
					values={values}
					data={data}
					setValue={setValue}
					onSave={onSave}
					showModal={showModal}
					hideModal={hideModal}
					isWaiting={isWaiting}
					getFieldProps={getFieldProps}
					modalVisible={modalVisible}
					showHistory={false}
					className="mb-3"
				/>
			}
		</>
	);
};

export default ClientInfo;
