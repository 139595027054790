import React, {useEffect} from 'react';
import {usePostHog} from "posthog-js/react";
import {Redirect, useHistory, useLocation} from "react-router-dom";
import {parseParams} from "../../../../src_shared/list/ListActions";
import useFetchData from "../../../../src_shared/hooks/useFetchData";
import {services} from "../../../RestServices";
import {RouterPaths} from "../../../routes/RouterPath";
import {useIntercom} from "react-use-intercom";
import {useSelector} from "react-redux";
import Plausible from "plausible-tracker";
import {hotjar} from "react-hotjar";
import ReactPixel from "react-facebook-pixel";

const TutorialWrapper = ({children}) => {
    const posthog = usePostHog();
    const history = useHistory();
    const {search, pathname, hash} = useLocation();
    const query = parseParams(search);
    const {boot} = useIntercom();
    const {lang} = useSelector((state) => state.i18n);
    const accessToken = hash?.includes('#access_token');

    useEffect(() => {
        const plausible = Plausible({
            domain: 'app.selmo.io',
        })

        ReactPixel.init('1019873831899249');
        plausible.trackPageview();
        hotjar.initialize(3450973, 6);
    }, [])

    const afterSubmit = (response) => {
        if (response.fromInstagram) {
            return;
        }
        if ((response.url !== pathname && (!query.code || !accessToken || !query.connectAgain))) {
            history.push(response.url)
        }
    }

    const [{errorMessage}] = useFetchData({
        rest: services.API_TUTORIAL_STATUS,
        afterSubmit,
        initialAutoLoad: (!query.code && !accessToken && !query.connectAgain)
    });

    useEffect(() => {
        if ((!!query.email && posthog && !+query.emailNotExist)) {
            posthog?.identify(query.email, {
                email: query.email,
                origin: query.origin
            })
            boot({
                email: query.email,
                languageOverride: lang,
            })
            history.replace({
                search: '',
            })
        }
    }, [posthog, query.email, query.emailNotExist])

    if (errorMessage && errorMessage === 'User not logged in') {
        return <Redirect to={RouterPaths.Auth}/>
    }

    return children;
};

export default TutorialWrapper;
