import React from 'react';
import PickupPointSelected from "../shared/PickupPointSelected";
import {services} from "../../../../../../RestServices";

const OrlenSelmoPaczka = ({data, options, fetchData, showDataModal, isQualified, type = 'dpd'}) => {
    const selectedPointName = data.pointMachineCode ? `${data.pointMachineStreet}, ${data.pointMachineCity}` : null;

    return (
        <PickupPointSelected
            rest={services.API_PACKAGE_ORLEN_PACZKA_LABEL}
            orderId={data.id}
            fetchData={fetchData}
            selectedPointName={selectedPointName}
            options={options}
            showDataModal={showDataModal}
            isQualified={isQualified}
            type={type}
        />
    );
};

export default OrlenSelmoPaczka;
