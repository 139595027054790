import createReducer from "../../shared/helpers/createReducer";
import DataReducer from "../../../../src_shared/form/data/DataReducer";
import {getReduceMap} from "../../../../src_shared/filters/filterReducer";
import history from "../../../history";
import {parseParams} from "../../../../src_shared/list/ListActions";
import {PRODUCTS_TYPE_ALL} from "../../shared/enums/ProductsType";
import {PRODUCTS_TYPE_ACTIVE_ALL} from "../../shared/enums/ProductsActiveType";
import moment from "moment";
import {ALL_ORDERS_VIEW, INTERVAL} from "../../shared/enums/OrderViewTypes";
import {ProductsSourceTypes} from "../../shared/enums/ProductSourceType";
import {PRODUCTS_SHOP_VISIBILITY_TYPE_ACTIVE_ALL} from "../../shared/enums/ProductsShopVisibilityType";
import {ORDER_STATUES} from "../../shared/enums/OrderStatuses";
import {ExpendituresReasonTypes} from "../../shared/enums/ExpendituresTypes";
import {PRODUCTS_MOBILE_APP_VISIBILITY_TYPE_ACTIVE_ALL} from "../../shared/enums/ProductsMobileAppVisibilityType";
import {convertToUserTimeZone} from "../../shared/helpers/dateHelpers";

const queryParams = history.location.search;
const parsedParams = parseParams(queryParams)

const preparedType = parsedParams.type || PRODUCTS_TYPE_ALL;
const preparedDeleted = parsedParams.deleted || '0';
const preparedActiveType = parsedParams.active || PRODUCTS_TYPE_ACTIVE_ALL;
const preparedShopActiveType = parsedParams.shopActive || PRODUCTS_SHOP_VISIBILITY_TYPE_ACTIVE_ALL;
const preparedMobileAppActiveType = parsedParams.mobileAppActive || PRODUCTS_MOBILE_APP_VISIBILITY_TYPE_ACTIVE_ALL;
const preparedCategories = parsedParams['categories[]'] || [];
const preparedManufacturers = parsedParams['manufacturers[]'] || [];
const preparedSource = parsedParams['source[]'] || [];
const preparedViewType = !!queryParams ? (parsedParams.dateFrom ? INTERVAL : ALL_ORDERS_VIEW) : INTERVAL;
const preparedStatuses = parsedParams['status[]']?.map((i) => +i) || [];
const preparedReasons = parsedParams['reasons[]'] || [];

const preparedDate = (initialState, paramsDate) => {
	if (!!queryParams) {
		if (parsedParams[paramsDate]) {
			return moment(parsedParams[paramsDate]).toDate()
		} else {
			return null;
		}
	} else {
		return initialState;
	}
}

export const getStateRoot = (state) => state.products.filters;

export const getInitState = () => DataReducer.getInitState({
	type: preparedType,
	activeType: preparedActiveType,
	shopActive: preparedShopActiveType,
	mobileAppActive: preparedMobileAppActiveType,
	categories: preparedCategories,
	categoriesSelectAll: false,
	manufacturers: preparedManufacturers,
	manufacturersSelectAll: false,
	source: preparedSource,
	sourceSelectAll: preparedSource.length === ProductsSourceTypes.length,
	fromDate: preparedDate(moment(new Date()).subtract(1, 'M').toDate(), 'dateFrom'),
	toDate: preparedDate(new Date(), 'dateTo'),
	viewType: preparedViewType,
	statuses: preparedStatuses || [],
	filtersSelectAll: preparedStatuses.length === ORDER_STATUES.length,
	reasons: preparedReasons || [],
	reasonsSelectAll: preparedReasons.length === ExpendituresReasonTypes.length,
	deleted: preparedDeleted,
}, {
	statuses: [],
	filtersSelectAll: false,
	type: PRODUCTS_TYPE_ALL,
	activeType: PRODUCTS_TYPE_ACTIVE_ALL,
	shopActive: PRODUCTS_SHOP_VISIBILITY_TYPE_ACTIVE_ALL,
	mobileAppActive: PRODUCTS_MOBILE_APP_VISIBILITY_TYPE_ACTIVE_ALL,
	categories: [],
	categoriesSelectAll: false,
	manufacturers: [],
	manufacturersSelectAll: false,
	source: [],
	sourceSelectAll: false,
	reasons: [],
	reasonsSelectAll: false,
	fromDate: moment(new Date()).subtract(1, 'M').toDate(),
	toDate: moment().toDate(),
	viewType: INTERVAL,
	deleted: '0',
})

export const prefix = 'PRODUCTS_FILTERS_';

export const getInstance = () => createReducer(
	getInitState(),
	getReduceMap(),
	prefix,
);

export default getInstance();
