import React from 'react';
import {Redirect, Route, Switch} from "react-router-dom";
import {RouterPaths} from "./RouterPath";
import SalesPostForm from "../app/salesPost/form/Form";
import SalesPostList from "../app/salesPost/list/List";
import {USER_PERMISSIONS_SALES_POSTS_MENU_VISIBILITY} from "../app/shared/enums/UserPermissionsTypes";
import UserPermissionRoute from "./UserPermissionRoute";
import {SELMO_APP_PERMISSION} from "../app/shared/enums/SelmoPermissions";
import PermissionRoute from "./PermissionRoute";

const SalesPostRoute = ({path}) => {

    return (
        <PermissionRoute
            path={path}
            allowedPermissions={[SELMO_APP_PERMISSION]}
        >
            <UserPermissionRoute
                allowedPermissions={[USER_PERMISSIONS_SALES_POSTS_MENU_VISIBILITY]}
                path={path}
            >
                <Switch>
                    <Route
                        exact
                        path={RouterPaths.SalesPostEdit}
                        component={SalesPostForm}
                    />
                    <Route
                        exact
                        path={path}
                        component={SalesPostList}
                    />
                    <Redirect to={path}/>
                </Switch>
            </UserPermissionRoute>
        </PermissionRoute>
    );
};

export default SalesPostRoute;
