import React, {useEffect, useState} from 'react';
import useLang from "../../../../src_shared/hooks/useLang";
import useForm from "../../../../src_shared/form/useForm";
import FormActions from "./FormActions";
import {Link, Redirect, useHistory, useParams} from "react-router-dom";
import {RouterPaths} from "../../../routes/RouterPath";
import VideoComponent from "./components/videoComponent/VideoComponent";
import {services} from "../../../RestServices";
import {selmoAPI2Url} from "../../../../src_shared/api/api";
import AssignProducts from "./components/assignProducts/AssignProducts";
import ComponentLoader from "../../../../src_shared/components/ui/componentLoader/ComponentLoader";
import PublishInSelmoApp from "./components/publishInSelmoApp/PublishInSelmoApp";
import PublishOnFacebook from "./components/publishOnFacebook/PublishOnFacebook";
import SaveAndPublish from "./components/saveAndPublish/SaveAndPublish";
import VideoPreview from "./components/videoPreview/VideoPreview";
import PostNotification from "./components/postNotification/PostNotification";
import {Helmet} from "react-helmet";

const SalesPostForm = () => {

    const {getLangText} = useLang();
    const {id} = useParams();
    const history = useHistory();
    const [isVideoUploading, setIsVideoUploading] = useState(false);

    const {
        data: {values, updateValues, setValue},
        form: {isWaiting, onSave, getFieldProps, loadData},
        validation,
    } = useForm({
        FormActions,
        params: {
            id,
        },
    });

    const isError = (field) => {
        const errorKey = validation.validateErrors[field](values[field], values);
        return errorKey ? errorKey : false;
    }

    const getError = (field) => !!isError(field) && !!validation.isActive;

    useEffect(() => {
        const unblock = history.block(() => {
            if (isVideoUploading) {
                if (window.confirm(getLangText('videoIsBeingSentWantToQuit'))) {
                    unblock();
                } else {
                    return false;
                }
            }
        });

        return () => {
            unblock();
        };
    }, [isVideoUploading, history]);

    if (isWaiting) return <ComponentLoader/>

    if (values.notFound) return <Redirect to={RouterPaths.SalesPost}/>

    return (
        <>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{getLangText('salesPost')} - Selmo</title>
            </Helmet>
            <div className="header-bar breadcrumbs-in-react-app">
                <ul className="breadcrumbs">
                    <li>
                        <Link to={RouterPaths.SalesPost}>
                            {getLangText('salesPost')}
                        </Link>
                    </li>
                    <li className="active">
                        <div>{getLangText('editionLabel')}</div>
                    </li>
                </ul>
                <Link
                    to={RouterPaths.SalesPost}
                    className="button back-to-panels sm:mb-5 sm:mt-0 mt-3.5">
                    <i className="icon-arrow-left"/>
                    {getLangText('backToList')}
                </Link>
            </div>
            <div className="grid sm:grid-cols-5 gap-5">
                <div className="sm:col-span-3">
                    <VideoComponent
                        updateValues={updateValues}
                        values={values}
                        restService={services.API_VIDEO}
                        restUrl={selmoAPI2Url}
                        validation={validation}
                        hasError={getError('video_id')}
                        setIsVideoUploading={setIsVideoUploading}
                        isVideoUploading={isVideoUploading}
                    />
                    <AssignProducts
                        values={values}
                        setValue={setValue}
                        hasError={getError('products')}
                        isActive={!!values.video_id || isVideoUploading}
                    />
                    <PublishInSelmoApp
                        getFieldProps={getFieldProps}
                        setValue={setValue}
                        isActive={!!values.products?.length}
                        values={values}
                    />
                    <PublishOnFacebook
                        getFieldProps={getFieldProps}
                        setValue={setValue}
                        isActive={!!values.products?.length}
                        values={values}
                        loadData={loadData}
                    />
                    <SaveAndPublish
                        onSave={onSave}
                        setValue={setValue}
                        getFieldProps={getFieldProps}
                        values={values}
                    />
                </div>
                <div className="sm:col-span-2">
                    <PostNotification
                        loadData={loadData}
                        values={values}
                    />
                    <VideoPreview values={values}/>
                </div>
            </div>
        </>
    );
};

export default SalesPostForm;
