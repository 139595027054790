import React, {useState} from 'react';
import useFetchData from "../../../src_shared/hooks/useFetchData";
import {services} from "../../RestServices";
import {useParams} from "react-router-dom";
import {getGETParamsUrl} from "../../../src_shared/list/ListActions";
import useLang from "../../../src_shared/hooks/useLang";

const MobileApRedirect = () => {

    const params = useParams();
    const {getLangText} = useLang();
    const [url, setUrl] = useState("");
    useFetchData({
        rest: `${services.API_MOBILE_PUBLIC_REDIRECT_PAGE}/${params.id}${getGETParamsUrl(params)}`,
        afterSubmit: (response) => {
            setUrl(response.url);
            window.location.href = response.url
        },
    })

    return (
        <div className="h-full">
            <div className="loader-parent" style={{
                height: '100%',
                background: '#fff',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center'
            }}>
                <div>
                    <img className="mx-auto mb-5" alt="selmo" width={100} src="/assets/images/selmo-logo.svg"/>
                    {url ?
                        <div className="font-bold text-[#000] text-center">
                            <div className="mb-2">{getLangText('readyLabel')}</div>
                            <a
                                rel="nofollow"
                                className="button primary black w-full"
                                href={url}
                            >
                                {getLangText('goToApp')}
                            </a>
                            <div className="text-left mt-3 bg-[#F9FAFB] text-xs p-3 rounded-[5px]">
                                <div className="flex text-[#000]">
                                    <i class="icon-info-c text-desc mt-[2px] mr-1"/>
                                    <div>
                                        <div className="font-bold">{getLangText('ifButtonDoesntWorkClickHere')}</div>
                                        <div
                                            className="text-desc">{getLangText('ifButtonDoesntWorkClickHereSubLabel')}</div>
                                    </div>
                                </div>
                                <a
                                    rel="nofollow"
                                    className="button border-button mt-2 w-full"
                                    href={`https://onelink.to/selmo?utm_source=selmo&utm_medium=panel&utm_campaign=panel_connecting`}
                                >
                                    {getLangText('goToApp')}
                                </a>
                            </div>

                        </div> :
                        <div>
                            <div
                                style={{display: 'block', height: 'auto'}}
                                className={`loader-container static-loader`}
                            >
                                <div className="circle-loader">
                                    <span className="circle"/>
                                </div>
                            </div>
                            <div className="font-bold text-[#000] text-center">{getLangText('loadingLabel')}</div>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
        ;
};

export default MobileApRedirect;
