import React, {useEffect, useState} from 'react';
import {Link, NavLink} from "react-router-dom";
import {RouterPaths} from "../../../routes/RouterPath";
import EditProductViews, {
    ACTIVITY_HISTORY_LIST,
    ACTIVITY_HISTORY_LIST_PRODUCT_VIEW,
    CURRENT_BASKET_PRODUCT_VIEW,
    EDIT_PRODUCT_VIEW,
    EXPENDITURES_LIST_PRODUCT_VIEW,
    FINISHED_CART_PRODUCT_VIEW,
    GALLERY_PRODUCT_VIEW,
    REMOVED_LIST_PRODUCT_VIEW,
    RESERVE_LIST_PRODUCT_VIEW
} from "../../shared/enums/EditProductViews";
import {useDispatch, useSelector} from "react-redux";
import {createSelector} from "reselect";
import {getStateRoot} from "./edit/formReducer";
import {getStateRoot as getSharedEditProductStateRoot} from './shared/sharedEditProductReducer'
import ProductForm from "./edit/Form";
import CurrentCart from "./currentCart/List";
import SharedEditProductActions from "./shared/SharedEditProductActions";
import ReserveList from "./reserveList/List";
import FinishedCart from "./finishedCart/List";
import useLang from "../../../../src_shared/hooks/useLang";
import ProductGallery from "./gallery/Gallery";
import ExpendituresList from "./expenditures/List";
import ExportProductCard from "./components/ExportProductCard";
import {SHOP_ORIGIN_DA} from "../../shared/enums/ShopOrigins";
import UserPermissionsWrapper from "../../../routes/UserPermissionsWrapper";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import ActivityHistoryList from "./activityHistory/List";
import {USER_PERMISSIONS_PRODUCTS_STATISTICS_VISIBILITY} from "../../shared/enums/UserPermissionsTypes";
import SelmoProBoxWrapper from "../../shared/components/SelmoProBox/SelmoProBox";
import RemovedList from "./removed/List";

const ProductFormPage = () => {
    const dispatch = useDispatch();
    const {getLangText} = useLang();
    const [name, setName] = useState('');
    const {depot_system, origin, selmo_pro_active, edit_cart_enabled} = useSelector((state) => state.session.userData);

    const setProductFormView = (formView) => dispatch(SharedEditProductActions.setProductFormView(formView))
    const [data, waiting, editProductView] = useSelector(createSelector(getStateRoot, getSharedEditProductStateRoot, (x1, x2) => [
        x1.data,
        x1.waiting,
        x2.editProductView,
    ]));


    useEffect(() => {
        return () => setProductFormView(EDIT_PRODUCT_VIEW)
    }, [])

    useEffect(() => {
        setName(data.name)
    }, [waiting])

    return (
        <>
            <div className="header-bar breadcrumbs-in-react-app">
                <ul className="breadcrumbs">
                    <li>
                        <Link to={RouterPaths.ProductsList}>{getLangText('asideProductsLabel')}</Link>
                    </li>
                    <li className="active">
                        <div className="max-w-[300px] text-ellipsis overflow-hidden">{name}</div>
                    </li>
                </ul>
                <Link to={RouterPaths.ProductsList} className="button back-to-panels mb-lg-3 mt-lg-0 mt-3">
                    <i className="icon-arrow-left"/>
                    {getLangText('backToProductsLabel')}
                </Link>
            </div>
            <div
                className={`settings-page sub-settings-page list-with-tabs loader-parent ${waiting ? 'active-loader-parent' : ''}`}>
                <div className={`top-part flex-md-nowrap flex-wrap pb-2`}>
                    <h1 className="title">
                        {waiting ?
                            <div className="animate-pulse relative">
                                <div className="h-[23px] bg-gray-200 rounded-[5px] dark:bg-gray-700 w-[250px]"/>
                            </div> :
                            name
                        }
                    </h1>
                    {origin === SHOP_ORIGIN_DA &&
                        <ExportProductCard/>
                    }
                    {data.ecommerce_type === '1' &&
                        <OverlayTrigger
                            placement='bottom'
                            overlay={
                                <Tooltip id="full-screen">
                                    {getLangText('productSourceFromIntegrations')}
                                </Tooltip>
                            }
                        >
                            <img
                                style={{width: 'auto', marginLeft: 'auto', height: '25px'}}
                                src="/assets/images/woocommerce-logo.webp"
                                alt="woocommerce"
                            />
                        </OverlayTrigger>
                    }
                    {data.ecommerce_type === '2' &&
                        <OverlayTrigger
                            placement='bottom'
                            overlay={
                                <Tooltip id="full-screen">
                                    {getLangText('productSourceFromIntegrations')}
                                </Tooltip>
                            }
                        >
                            <img
                                style={{width: 'auto', marginLeft: 'auto', height: '12px'}}
                                src="/assets/images/svg/presta-logo.svg"
                                alt="PrestaShop"
                            />
                        </OverlayTrigger>
                    }
                    {data.ecommerce_type === '3' &&
                        <OverlayTrigger
                            placement='bottom'
                            overlay={
                                <Tooltip id="full-screen">
                                    {getLangText('productSourceFromIntegrations')}
                                </Tooltip>
                            }
                        >
                            <img
                                style={{width: 'auto', marginLeft: 'auto', height: '25px'}}
                                src="/assets/images/svg/shopify.svg"
                                alt="PrestaShop"
                            />
                        </OverlayTrigger>
                    }
                </div>
                <div
                    style={{borderRadius: editProductView === EDIT_PRODUCT_VIEW ? '0 0 5px 5px' : '0'}}
                    className="tabs-wrapper scroll-enabled">
                    {EditProductViews.map((i) => (
                        <UserPermissionsWrapper allowedPermissions={[i.permission]}>
                            <button
                                type="button"
                                key={i.id}
                                className={editProductView === i.id ? 'active' : ''}
                                onClick={() => setProductFormView(i.id)}
                            >
                                {getLangText(i.label)}
                                {i.totalField &&
                                    <span className={!data[i.totalField] ? 'grey' : ''}>{data[i.totalField]}</span>
                                }
                                {(i.id === ACTIVITY_HISTORY_LIST_PRODUCT_VIEW && !+selmo_pro_active) &&
                                    <img className="w-[20px] ml-1 inline-block mb-[2px]" src="/assets/images/svg/pro.svg" alt=""/>
                                }
                            </button>
                        </UserPermissionsWrapper>
                    ))}
                    {!!+depot_system &&
                        <button
                            type="button"
                            className={editProductView === EXPENDITURES_LIST_PRODUCT_VIEW ? 'active' : ''}
                            onClick={() => setProductFormView(EXPENDITURES_LIST_PRODUCT_VIEW)}
                        >
                            {getLangText('expendituresLabel')}
                        </button>
                    }
                    {!!+edit_cart_enabled &&
                        <button
                            className={editProductView === REMOVED_LIST_PRODUCT_VIEW ? 'active' : ''}
                            onClick={() => setProductFormView(REMOVED_LIST_PRODUCT_VIEW)}
                            type="button"
                        >
                            {getLangText('removed1')}
                        </button>
                    }
                </div>
                {editProductView === EDIT_PRODUCT_VIEW &&
                    <ProductForm/>
                }
                {editProductView === GALLERY_PRODUCT_VIEW &&
                    <ProductGallery/>
                }
                {editProductView === CURRENT_BASKET_PRODUCT_VIEW &&
                    <CurrentCart data={data}/>
                }
                {editProductView === RESERVE_LIST_PRODUCT_VIEW &&
                    <ReserveList data={data}/>
                }
                {editProductView === FINISHED_CART_PRODUCT_VIEW &&
                    <FinishedCart data={data}/>
                }
                {editProductView === EXPENDITURES_LIST_PRODUCT_VIEW &&
                    <ExpendituresList data={data}/>
                }
                {editProductView === REMOVED_LIST_PRODUCT_VIEW &&
                    <RemovedList data={data}/>
                }
                {editProductView === ACTIVITY_HISTORY_LIST_PRODUCT_VIEW &&
                    <SelmoProBoxWrapper
                        title="activityHistory"
                        desc="seeActivitiesWhichYourTeamHasDoneInProduct"
                        variant={2}
                    >
                        <ActivityHistoryList data={data}/>
                    </SelmoProBoxWrapper>
                }
            </div>
        </>
    );
};

export default ProductFormPage;
