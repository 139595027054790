import React from 'react';
import Modal from "react-bootstrap/Modal";
import useDataApi from "../../../../../src_shared/hooks/useDataApi";
import ComponentLoader from "../../../../../src_shared/components/ui/componentLoader/ComponentLoader";
import useLang from "../../../../../src_shared/hooks/useLang";

const RegulationModal = ({modalVisible, hideModal, rest, shopId, title}) => {

    const [{data, isLoading}] = useDataApi(`${rest}?shop_id=${shopId}`, '')
    const {getLangText} = useLang();

    return (
        <Modal
            dialogClassName="default-modal terms-modal"
            show={modalVisible}
            onHide={hideModal}
        >
            <Modal.Header className="with-border">
                <Modal.Title>
                    {title}
                </Modal.Title>
                <button
                    onClick={hideModal}
                    type="button"
                    className="button"
                >
                    <i className="icon-cross"/>
                </button>
            </Modal.Header>
            <Modal.Body>
                {isLoading ?
                    <ComponentLoader/> :
                    <div dangerouslySetInnerHTML={{__html: data}}/>
                }
            </Modal.Body>
            <Modal.Footer>
                <button
                    onClick={hideModal}
                    type="button"
                    className="button border-button"
                >
                    {getLangText('closeButton')}
                </button>
            </Modal.Footer>
        </Modal>
    );
};

export default RegulationModal;
