import React from 'react';
import Modal from "react-bootstrap/Modal";
import Loader from "../../../../../messenger/loader/Loader.jsx";
import useLang from "../../../../../../../src_shared/hooks/useLang";

const ConfirmModal = ({
                          show,
                          hide,
                          headerClassName = '',
                          isWaiting,
                          title,
                          SubTitle,
                          saveButton,
                          onSubmit,
                          children,
                          saveButtonClass = 'primary',
                          dialogClassName = '',
                          withModalHeader = true,
                      }) => {
    const {getLangText} = useLang();

    return (
        <Modal
            dialogClassName={`default-modal small-modal ${dialogClassName}`}
            show={show}
            onHide={hide}
        >
            <Modal.Header className={headerClassName}>
                {withModalHeader &&
                    <Modal.Title>
                        {title}
                        {SubTitle &&
                            <div className="description">
                                <SubTitle/>
                            </div>
                        }
                    </Modal.Title>
                }
                <button
                    onClick={hide}
                    type="button"
                    className="button"
                >
                    <i className="icon-cross"/>
                </button>
            </Modal.Header>
            <Modal.Body>
                {children}
            </Modal.Body>
            <Modal.Footer>
                <button
                    onClick={hide}
                    type="button"
                    className="button border-button"
                >
                    {getLangText('cancelButton')}
                </button>
                <button
                    onClick={onSubmit}
                    type="button"
                    className={`button ${saveButtonClass} px-0 ${isWaiting ? 'loading-button' : ''}`}
                >
                    {isWaiting && <Loader/>}
                    {saveButton}
                </button>
            </Modal.Footer>
        </Modal>
    );
};

export default ConfirmModal;
