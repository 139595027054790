import PageLink from "./PageLink.jsx";
import classNames from 'classnames';
import React from "react";
import CustomSelect from "../form/Select.jsx";

const uiConfig = {
	css: {
		nav: '',
		ul: 'pagination',
		prevIcon: 'glyphicon glyphicon-chevron-left',
		active: 'active',
		nextIcon: 'glyphicon glyphicon-chevron-right',
	},
};

class Pagination extends React.Component {
	getUiConfig() {
		return uiConfig;
	}

	getMockedPages() {
		const {mockedPages} = this.props;

		// eslint-disable-next-line prefer-spread
		return Array.apply(null, {length: mockedPages})
			.map((item, idx) => ({
				number: idx + 1,
				active: idx + 1 === 1,
			}));
	}

	getRealPages() {
		const {pages} = this.props;

		const activePageIdx = pages.findIndex(page => page.active);
		const lastPageIdx = pages.length;
		const ellipsisArrayHead = [
			pages[0],
			{ellipsis: true, number: 'ellipsis-1'},
		];
		const ellipsisArrayTail = [
			{ellipsis: true, number: 'ellipsis-2'},
			pages[pages.length - 1],
		];

		if (pages.length > 5 && activePageIdx <= 2) {
			return [
				...pages.slice(0, 4),
				...ellipsisArrayTail,
			];
		}
		if (pages.length > 5 && activePageIdx > 2 && activePageIdx < lastPageIdx - 3) {
			return [
				...ellipsisArrayHead,
				...pages.slice(activePageIdx - 1, activePageIdx + 2),
				...ellipsisArrayTail,
			];
		}
		if (pages.length > 5 && activePageIdx > 2 && activePageIdx >= lastPageIdx - 3) {
			return [
				...ellipsisArrayHead,
				...pages.slice(lastPageIdx - 4, lastPageIdx + 1),
			];
		}

		return pages;
	}

	getPages() {
		const {mockData} = this.props;

		return mockData ? this.getMockedPages() : this.getRealPages();
	}

	isFirstPageActive() {
		const {mockData, pages} = this.props;

		const allPages = mockData ? this.getMockedPages() : pages;

		return allPages[0] ? !!allPages[0].active : false;
	}

	isLastPageActive() {
		const {mockData, pages} = this.props;

		const allPages = mockData ? this.getMockedPages() : pages;

		const lastPageIdx = allPages.length - 1;
		return allPages[lastPageIdx] ? !!allPages[lastPageIdx].active : false;
	}

	render() {
		const {
			onPrevPage,
			onNextPage,
			onChangePage,
			showItemsPerPageSelect,
			itemsPerPage,
			onChangeItemsPerPage,
			totalItems,
			getLangText,
		} = this.props;

		const prevButtonStyle = classNames(
			'prev-button',
			{
				disabled: this.isFirstPageActive(),
			},
		);
		const nextButtonStyle = classNames(
			'next-button',
			{
				disabled: this.isLastPageActive(),
			},
		);

		const activePageStyle = (page) => classNames(
			{
				active: page.active,
			},
		);

		const itemsPerPageOptions = [
			{value: 10, label: '10'},
			{value: 25, label: '25'},
			{value: 50, label: '50'},
			{value: 100, label: '100'}
		]

		const filteredItemsPerPageOptions = () => {
			if (totalItems < 10) {
				return [];
			}
			if (totalItems > 100 || totalItems > 50 && totalItems < 100) {
				return itemsPerPageOptions;
			}
			if (totalItems > 10 && totalItems < 25) {
				return itemsPerPageOptions.filter((i) => i.value !== 50 && i.value !== 100);
			}
			if (totalItems > 25 && totalItems < 50) {
				return itemsPerPageOptions.filter((i) => i.value !== 100);
			}
			return [];
		}

		return (
			<div className="react-pagination-wrapper">
				{totalItems > itemsPerPage && (
					<ul className="pagination">
						<li className={prevButtonStyle}>
							<button
								type="button"
								onClick={onPrevPage}
							>
								<i className="icon-arrows left"/>
							</button>
						</li>
						{this.getPages().map((page) => (
							<li key={page.number} className={activePageStyle(page)}>
								{page.ellipsis
									? <button type="button">...</button>
									: <PageLink onClick={onChangePage} page={page}/>}
							</li>
						))}
						<li className={nextButtonStyle}>
							<button
								type="button"
								onClick={onNextPage}
							>
								<i className="icon-arrows"/>
							</button>
						</li>
					</ul>
				)}
				{showItemsPerPageSelect && !!filteredItemsPerPageOptions().length && (
					<div className="items-per-page-select">
						<span>{getLangText('showLabel')}</span>
						<CustomSelect
							id="custom-dropdown-default"
							value={itemsPerPage}
							options={filteredItemsPerPageOptions()}
							onChange={({value}) => onChangeItemsPerPage('itemsPerPage', value)}
							extraProps={{
								menuPlacement: "top"
							}}
						/>
						<span>{getLangText('positionsLabel')}</span>
					</div>
				)}
			</div>
		)
	}
}


Pagination.defaultProps = {
	getLangText: () => {},
}

export default Pagination;
