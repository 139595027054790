import React from 'react';
import moment from "moment/moment";
import useLang from "../../../../../src_shared/hooks/useLang";
import {useSelector} from "react-redux";
import {RouterPaths} from "../../../../routes/RouterPath";
import {Link} from "react-router-dom";
import {convertToUserTimeZone} from "../../helpers/dateHelpers";

const VacationButton = ({mobile = false, closeMobileMenu}) => {
    const {getLangText} = useLang();
    const {userData} = useSelector((state) => state.session);

    if (!!+userData.selmo_pro_active || convertToUserTimeZone(userData.user_info.premium_date).toDate() > convertToUserTimeZone('23.12.2024', 'DD.MM.YYYY').toDate()) {
        return null;
    }

    if (!mobile) {
        return (
            <Link
                to={RouterPaths.Vacation}
                className="p-1 pr-2 mr-2 leading-[12px] flex items-center text-xs font-bold bg-[#fff] shadow-default rounded-[8px] hover:text-[#000] hover:bg-[#f9f9f9] transition-all duration-200 hover:no-underline">
                <img className="w-[24px] mr-1 rounded-[5px] overflow-hidden" src="/assets/images/svg/sun2.svg"
                     alt="icon"/>
                <div>
                    <div>{getLangText('vacationPromo')}</div>
                    <div className="text-desc text-[10px] font-medium">{getLangText('limitedCount')}</div>
                </div>
            </Link>
        )
    }

    return (
        <Link
            onClick={closeMobileMenu}
            to={RouterPaths.Vacation}
            className="flex items-center leading-[16px] text-sm font-bold rounded-[8px] border mt-2 hover:text-[#000] hover:bg-[#f9f9f9] transition-all duration-200 hover:no-underline">
            <img
                style={{display: 'block'}}
                className="w-[24px] mr-2 rounded-[5px] overflow-hidden" src="/assets/images/svg/sun2.svg"
                alt="icon"/>
            <div>
                <div className="text-[#000]">{getLangText('vacationPromo')}</div>
                <div className="text-desc text-xs font-medium">{getLangText('limitedCount')}</div>
            </div>
        </Link>
    );
};

export default VacationButton;
