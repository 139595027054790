import React from 'react';
import useLang from "../../../../../src_shared/hooks/useLang";
import {useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {RouterPaths} from "../../../../routes/RouterPath";
import {SHOP_ORIGIN_PL} from "../../enums/ShopOrigins";

const SelmoProBoxWrapper = ({
                                title = '', desc = '', variant = 1, className = '', children, HeaderComponent = () => {
    }, FooterComponent = () => {
    }, buttonClass = 'button primary w-full small-size mt-4 mb-2 group-hover:bg-[#1c4ed8]', buttonText = '', redirect = null
                            }) => {
    const {getLangText} = useLang();
    const {userData} = useSelector((state) => state.session);
    const history = useHistory();
    const {origin} = useSelector((state) => state.session.userData);

    if (!+userData.selmo_pro_active && redirect && origin === SHOP_ORIGIN_PL) {
        history.push(redirect);
    }

    if (!!+userData.selmo_pro_active && children) return children;

    if (variant === 2) {
        return (
            <a
                href={RouterPaths.SelmoPro}
                target="_blank"
                className="bg-[#fff] sm:py-20 py-12 shadow-default rounded-b block hover:no-underline group"
            >
                {HeaderComponent && HeaderComponent()}
                <div className={`max-w-[300px] mx-auto text-center block w-full ${className}`}>
                    <div className="mx-auto w-[36px] mb-2">
                        <img className="w-full" src="/assets/images/svg/locked.svg" alt=""/>
                    </div>
                    <div>
                        <div className="font-bold text-xl text-[#101827] mb-1">{getLangText(title)}</div>
                        <div
                            className="font-medium text-sm text-[#6B7280] leading-[21px] whitespace-pre-wrap">{getLangText(desc)}</div>
                    </div>
                    <div
                        style={{fontSize: 14, borderRadius: 8}}
                        className="button primary w-full small-size mt-4 mb-2 group-hover:bg-[#1c4ed8]"
                    >
                        {getLangText('seeMoreButton')}
                    </div>
                    <div className="flex items-center">
                        <div className="mr-1">
                            <i className="icon-info-c text-[#5FA5FA]"/>
                        </div>
                        <div
                            className="text-xs text-[#1F2937]"
                            dangerouslySetInnerHTML={{__html: getLangText('functionalityAvailableInSelmoPro')}}
                        />
                    </div>
                    {FooterComponent && FooterComponent()}
                </div>
            </a>
        )
    }

    return (
        <a
            href={RouterPaths.SelmoPro}
            target="_blank"
            className={`text-center block w-full bg-[#fff] rounded-[5px] p-[16px] pt-8 border-[#9CC7FC] border shadow-default relative hover:no-underline ${className}`}
        >
            <div className="mx-auto w-[36px] mb-1">
                <img className="w-full" src="/assets/images/svg/locked.svg" alt=""/>
            </div>
            <div>
                <div className="font-bold text-xl text-[#101827] mb-1">{getLangText(title)}</div>
                <div
                    className="font-medium text-sm text-[#6B7280] leading-[21px] whitespace-pre-wrap">{getLangText(desc)}</div>
            </div>
            <div className="flex items-center bg-[#F0FAFF] border border-[#d7ebf6] p-2 rounded-[5px] mt-4">
                <div
                    className="whitespace-pre-wrap text-sm text-[#0D3364] text-left"
                    dangerouslySetInnerHTML={{__html: getLangText('functionalityAvailableInSelmoPro')}}
                />
                <div
                    style={{fontSize: 14}}
                    className="button primary ml-auto small-size">{getLangText('seeMoreButton')}</div>
            </div>
            <img className="w-[22px] absolute top-2 right-2" src="/assets/images/svg/pro.svg" alt=""/>
        </a>
    );
};

export default SelmoProBoxWrapper;
