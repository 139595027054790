import React, {useState} from 'react';
import StylesLoader from "../../../../../shared/Loader";
import useLang from "../../../../../../../src_shared/hooks/useLang";
import {services} from "../../../../../../RestServices";
import {useDownloadFile} from "../../../../../../../src_shared/hooks/useDownloadFile";
import {usePrintFile} from "../../../../../../../src_shared/hooks/usePrintFile";
import {useRemoveModal} from "../../../../../shared/hooks/useRemoveModal";
import ConfirmModal from "../../../../list/components/export/ConfirmModal";
import {useDispatch} from "react-redux";
import SessionActions from "../../../../../shared/session/SessionActions";
import {convertToUserTimeZone} from "../../../../../shared/helpers/dateHelpers";
import PackageStatusModal from "./PackageStatusModal";

const GeneratedLabel = ({data, fetchData}) => {
    const {getLangText} = useLang();
    const dispatch = useDispatch();
    const [showStatusModal, setShowStatusModal] = useState(false);

    const isOrlenPaczka = data?.sp_label_info?.label_type === 'op';

    const getRest = () => {
        if (isOrlenPaczka) {
            return services.API_PACKAGE_ORLEN_PACZKA_LABEL
        }
        return services.API_PACKAGE_DPD_PICKUP_LABEL
    }

    const {download, waiting: waitingForLabel} = useDownloadFile({
        rest: `${getRest()}/${data.id}`,
        fileName: 'Inpost',
    })

    const {print, waiting: waitingForPrint} = usePrintFile({
        rest: `${getRest()}/${data.id}`,
    })

    const afterRemove = (response) => {
        dispatch(SessionActions.updateUserData('sp_balance', response))
        fetchData();
    }

    const [
        modalOpen,
        setModalOpen,
        waiting,
        remove
    ] = useRemoveModal(`${getRest()}/${data.id}`, afterRemove)

    return (
        <>
            <div className="loader-parent mt-3">
                <StylesLoader
                    className="small-loader"
                    isLoading={waitingForLabel || waitingForPrint || waiting}
                />
                <div className="tripple-buttons">
                    <div>
                        <button
                            onClick={print}
                            type="button"
                            className="button border-button big-width position-relative"
                        >
                            {getLangText('printButton')}
                            <i className="icon-print"/>
                        </button>
                    </div>
                    <div>
                        <button
                            onClick={download}
                            type="button"
                            className="button border-button big-width position-relative"
                        >
                            {getLangText('previewButton')}
                            <i className="icon-eye"/>
                        </button>
                    </div>
                    <div>
                        <button
                            onClick={() => setModalOpen(true)}
                            type="button"
                            className="button border-button big-width"
                        >
                            {getLangText('removeButton')}
                            <i className="icon-bin"/>
                        </button>
                    </div>
                </div>
            </div>
            <button
                onClick={() => setShowStatusModal(true)}
                type="button"
                className="button border-button w-full mt-2 position-relative"
            >
                {getLangText('packageStatus')}
            </button>
            {data?.sp_label_info?.inpot_label_date &&
                <div className="info-label mt-3">
                    <i className="icon-tick-c"/>
                    {getLangText('labelHasBennGeneratedLabel')}
                    <div className="date-box">
                        <span>{convertToUserTimeZone(data.shippingData.inpot_label_date).format('DD.MM.YYYY')}</span>
                        <span>{convertToUserTimeZone(data.shippingData.inpot_label_date).format('HH:mm:ss')}</span>
                    </div>
                </div>
            }
            {showStatusModal &&
                <PackageStatusModal
                    hideModal={() => setShowStatusModal(false)}
                    show={showStatusModal}
                    data={data}
                    rest={isOrlenPaczka ? services.API_PACKAGE_OP_PACKAGE_STATUS : services.API_PACKAGE_DPD_PACKAGE_STATUS}
                />
            }
            {modalOpen &&
                <ConfirmModal
                    dialogClassName="small-modal w-420"
                    title={getLangText('wantToRemoveLabelLabel')}
                    SubTitle={() => <>{getLangText('actionCannotBeUnDoneLabel')}</>}
                    saveButton={getLangText('removeButton')}
                    show={modalOpen}
                    isWaiting={waiting}
                    onSubmit={remove}
                    hide={() => setModalOpen(false)}
                    submitButtonClass="danger"
                />
            }
        </>
    );
};

export default GeneratedLabel;
