import React, {useState} from 'react';
import useLang from "../../../src_shared/hooks/useLang";
import SentHistory from "./components/SentHistory";
import FaqSection from "./components/FaqSection";
import {notificationsSteps} from "./landing/Landing";
import ClientsVideo from "./components/ClientsVideo";
import CopyButton from "../../../src_shared/components/form/copyButton/CopyButton";
import {services} from "../../RestServices";
import SendNotificationsModal from "./components/sendNotificationsModal/SendNotificationsModal";
import useFormLocal from "../../../src_shared/hooks/useFormLocal";
import ShopVisibilityForm from "./components/ShopVisibilityForm";
import NotificationsModel from "../../../modules/models/notifications/NotificationsModel";
import {useSelector} from "react-redux";
import WhyWorthIt from "./components/WhyWorthIt";
import WaitingForSend from "../../../src_shared/components/ui/waitingForSend/WaitingForSend";

const NotificationsPage = () => {
    const {getLangText} = useLang();
    const [showModal, setShowModal] = useState(false);
    const {userData} = useSelector((state) => state.session);

    const [{onSave, isWaiting, getFieldProps, isLoading, values, fetchData, setValue}] = useFormLocal({
        rest: services.API_MOBILE_NOTIFICATIONS,
        model: NotificationsModel,
    });

    if (isLoading) {
        return (
            <div className="md:grid md:grid-cols-5 gap-4">
                <div className="md:col-span-3">
                    <div className="animate-pulse">
                        <div className="h-[180px] bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-3 p-3">
                            <div className="h-[19px] bg-gray-300 rounded-md dark:bg-gray-800 w-[200px] mb-3.5"/>
                            <div className="h-[110px] bg-gray-300 rounded-md dark:bg-gray-800 w-full mb-3.5"/>
                        </div>
                        <div className="h-[290px] bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-3 p-3">
                            <div className="h-[19px] bg-gray-300 rounded-md dark:bg-gray-800 w-[250px] mb-3.5"/>
                            <div className="h-[60px] bg-gray-300 rounded-md dark:bg-gray-800 w-full mb-2"/>
                            <div className="h-[60px] bg-gray-300 rounded-md dark:bg-gray-800 w-full mb-2"/>
                            <div className="h-[60px] bg-gray-300 rounded-md dark:bg-gray-800 w-full mb-2"/>
                        </div>
                    </div>
                </div>
                <div className="md:col-span-2 sm:mt-0 mt-[16px]">
                    <div className="animate-pulse">
                        <div className="h-[180px] bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-3 p-3">
                            <div className="h-[19px] bg-gray-300 rounded-md dark:bg-gray-800 w-[200px] mb-3.5"/>
                            <div className="h-[110px] bg-gray-300 rounded-md dark:bg-gray-800 w-full mb-3.5"/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className="md:grid md:grid-cols-5 gap-4">
            <div className="md:col-span-3">
                <div className="default-shadow-box bigger-y-padd mb-3">
                    <h2 className="font-bold mb-3.5">{getLangText('notificationsInSelmoLabel')}</h2>
                    <div className="border flex items-center p-3 rounded-[5px] w-full">
                        <div className="grow">
                            <div
                                className="text-sm text-desc font-medium">{getLangText('submittedInAppLabel')}</div>
                            <div className="text-[30px] font-bold">
                                {values.total}
                            </div>
                        </div>
                        {!values.waitingForSend &&
                            <button
                                type="button"
                                onClick={() => setShowModal(true)}
                                className="button primary small-size px-3"
                            >
                                <i className="icon-notify mr-1"/>
                                <span className="text-xs">{getLangText('sendNotificationsLabel')}</span>
                            </button>
                        }
                    </div>
                    {values.waitingForSend &&
                        <WaitingForSend
                            className="mt-2"
                            small
                            title="notificationsAreBeingSentLabel"
                            field="waitingForSendMobileNotifications"
                            fetchData={fetchData}
                            rest={services.API_MOBILE_SEND_NOTIFICATIONS_STATUS}
                            setValue={setValue}
                        />
                    }
                </div>
                <SentHistory history={values.history}/>
                <div className="default-shadow-box mb-3">
                    <h2 className="font-bold mb-3.5">{getLangText('whatHaveToDoYourClientsLabel')}</h2>
                    <div className="space-y-3">
                        {notificationsSteps.map((step) => (
                            <div
                                key={step.id}
                                className="p-[14px] rounded-[10px] flex items-center space-x-3 bg-[#F9FAFB]">
                                <div
                                    className="sm:min-w-[32px] sm:text-[16px] text-sm min-w-[22px] h-[22px] sm:h-[32px] bg-[#E5E7EB] rounded-[5px] flex items-center justify-center font-bold">
                                    {step.id}
                                </div>
                                <div
                                    className="text-sm"
                                    dangerouslySetInnerHTML={{__html: getLangText(step.title)}}/>
                            </div>
                        ))}
                    </div>
                </div>
                <FaqSection/>
                <ShopVisibilityForm
                    getFieldProps={getFieldProps}
                    onSave={onSave}
                    isWaiting={isWaiting}
                />
            </div>
            <div className="md:col-span-2 sm:mt-0 mt-[16px]">
                <WhyWorthIt/>
                <ClientsVideo/>
                <div className="default-shadow-box mb-3">
                    <h2 className="font-bold mb-3.5">{getLangText('appLinkLabel')}</h2>
                    <div className="flex items-center space-x-1 w-full">
                        <a
                            target="_blank"
                            rel="noreferrer"
                            className="block"
                            href={`https://onelink.to/selmo?utm_source=selmo&utm_medium=panel&utm_campaign=panel_landing&utm_content=${userData.id}`}
                        >
                            <img
                                className="min-h-[35px]"
                                src={`/assets/images/svg/app-store.svg`}
                            />
                        </a>
                        <a target="_blank" rel="noreferrer" className="block"
                           href={`https://onelink.to/selmo?utm_source=selmo&utm_medium=panel&utm_campaign=panel_landing&utm_content=${userData.id}`}
                        >
                            <img
                                className="min-h-[35px]"
                                src={`/assets/images/svg/google-play.svg`} alt=""/>
                        </a>
                        <div className="ml-auto">
                            <CopyButton
                                copyText={`https://onelink.to/selmo?utm_source=selmo&utm_medium=button&utm_campaign=panel_landing&utm_content=${userData.id}`}
                                copyButtonText={getLangText('copyButton')}
                                copiedButtonText={getLangText('copyButton')}
                                buttonClassName="border-button left-icon copied ml-auto"
                            />
                        </div>
                    </div>
                </div>
            </div>
            {showModal &&
                <SendNotificationsModal
                    fetchData={fetchData}
                    showModal={showModal}
                    hideModal={() => setShowModal(false)}
                    usersCount={values.total}
                />
            }
        </div>
    );
};

export default NotificationsPage;
