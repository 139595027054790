import React, {useState} from 'react';
import Modal from "react-bootstrap/Modal";
import Furgonetka from "./furgonetka/Furgonetka";
import Inpost from "./inpost/Inpost";
import Summary from "./summary/Summary";
import {services} from "../../../../../../RestServices";
import {useSelector} from "react-redux";
import {createSelector} from "reselect";
import {getStateRoot} from "../../../listReducer";
import {useDownloadFile} from "../../../../../../../src_shared/hooks/useDownloadFile";
import {getGETParamsUrl} from "../../../../../../../src_shared/list/ListActions";
import SelmoPaczka from "./selmoPaczka/SelmoPaczka";
import {RouterPaths} from "../../../../../../routes/RouterPath";

const FURGONETKA_VIEW = 'FURGONETKA_VIEW';
const INPOST_VIEW = 'INPOST_VIEW';
const SELMO_PACZKA_VIEW = 'SELMO_PACZKA_VIEW';
const EMPTY_VIEW = 'EMPTY_VIEW';

const GenerateModal = ({modalVisible, hideModal, waitingForDocument, successGenerated, generatedOrdersCount}) => {

        const [activeView, setActiveView] = useState(SELMO_PACZKA_VIEW);
        const {isTemplateSelectedError, selectedItems} = useSelector(createSelector(getStateRoot, (stateRoot) => stateRoot));
        const {userData} = useSelector((state) => state.session);

        const isFurgonetkaInActive = userData.shop_furgonetka_active === '0' || userData.shop_furgonetka_active === null;
        const isInpostInActive = userData.shop_inpost_active === '0' || userData.shop_inpost_active === null;

        const {download, waiting} = useDownloadFile({
            rest: `${services.API_PRINT_LABELS}${getGETParamsUrl({'orders[]': selectedItems.map((i) => i.id)})}`,
            fileName: 'Etykieta',
            afterSubmit: hideModal,
        })

        return (
            <Modal
                show={modalVisible}
                dialogClassName="default-modal w-380"
                onHide={hideModal}
            >
                {successGenerated ?
                    <Summary
                        waitingForDocument={waiting}
                        hideModal={hideModal}
                        generatedOrdersCount={generatedOrdersCount}
                        onPrint={download}
                    /> :
                    <>
                        <Modal.Header className="with-tabs">
                            <Modal.Title>
                                Wygeneruj etykiety
                            </Modal.Title>
                            <div className="tabs-wrapper">
                                <button
                                    type="button"
                                    className={activeView === SELMO_PACZKA_VIEW ? 'active' : ''}
                                    onClick={() => setActiveView(SELMO_PACZKA_VIEW)}
                                >
                                    Selmo Paczka
                                </button>
                                {!isFurgonetkaInActive &&
                                    <button
                                        type="button"
                                        className={activeView === FURGONETKA_VIEW ? 'active' : ''}
                                        onClick={() => setActiveView(FURGONETKA_VIEW)}
                                    >
                                        Furgonetka
                                    </button>
                                }
                                {!isInpostInActive &&
                                    <button
                                        type="button"
                                        className={activeView === INPOST_VIEW ? 'active' : ''}
                                        onClick={() => setActiveView(INPOST_VIEW)}
                                    >
                                        Inpost
                                    </button>
                                }
                            </div>
                            <button
                                onClick={hideModal}
                                type="button"
                                className="button"
                            >
                                <i className="icon-cross"/>
                            </button>
                        </Modal.Header>
                        <Modal.Body className="pb-0">
                            <div className="orders-labels">
                                {activeView === FURGONETKA_VIEW &&
                                    <Furgonetka
                                        hideModal={hideModal}
                                        waitingForDocument={waitingForDocument}
                                        validationActive={isTemplateSelectedError}
                                    />
                                }
                                {activeView === INPOST_VIEW &&
                                    <Inpost
                                        hideGenerateModal={hideModal}
                                        waitingForDocument={waitingForDocument}
                                        validationActive={isTemplateSelectedError}
                                    />
                                }
                                {activeView === SELMO_PACZKA_VIEW &&
                                    <SelmoPaczka
                                        selectedItems={selectedItems}
                                        hideModal={hideModal}
                                        waitingForDocument={waitingForDocument}
                                        validationActive={isTemplateSelectedError}
                                    />
                                }
                                {activeView === EMPTY_VIEW &&
                                    <div className="empty-view pb-4">
                                        <div className="labels-header">
                                            <div className="labels-title mb-2">
                                                Brak integracji do generowania etykiet
                                            </div>
                                        </div>
                                        <a
                                            className="button border-button big-width w-100"
                                            href={RouterPaths.SettingsIntegration}
                                            target="_blank"
                                        >
                                            Skonfiguruj
                                        </a>
                                    </div>
                                }
                            </div>
                        </Modal.Body>
                    </>
                }
            </Modal>
        );
    }
;

export default GenerateModal;
