import React, {useRef, useState} from 'react';
import {selmoUrl} from "../../../../../../src_shared/api/api";
import {useDispatch, useSelector} from "react-redux";
import useLang from "../../../../../../src_shared/hooks/useLang";
import api from "../../../../../../services/axios/axios";
import {showAlert} from "../../../../shared/components/alert/AlertActions";
import Loader from "../../../../../../src_shared/components/loader/Loader";
import ValidationBox from "../ValidationBox";
import * as tus from 'tus-js-client'
import {services} from "../../../../../RestServices";
import MobileAppPermissionsModal from "./MobileAppPermissionsModal";
import {TEST_ACCOUNT_SHOP_ID} from "../../../../../../src_shared/enums/TrustedShopsIds";

const VideoComponent = ({
                            values,
                            updateValues,
                            restService,
                            field,
                            restUrl = selmoUrl,
                            hasError,
                            setIsVideoUploading,
                            isVideoUploading
                        }) => {
    const dispatch = useDispatch();
    const {getLangText} = useLang();
    const [isToBig, setIsToBig] = useState(false);
    const [isInvalidFormat, setIsInvalidFormat] = useState(false);
    const [progress, setProgress] = useState(0);
    const [showMobilePermissionsModal, setShowMobilePermissionsModal] = useState(false);
    const {userData} = useSelector((state) => state.session);

    const dropRef = useRef(null);
    const fileInputRef = useRef(null);

    const validExtensions = [
        '.mp4', '.mov', '.webm', '.flv', '.avi',
        '.mkv', '.wmv', '.mpeg', '.3gp'
    ];

    const onSubmit = async (file) => {
        setIsVideoUploading(true)

        const formData = new FormData();
        formData.append(field, file);

        const method = values.id ? 'put' : 'post'

        try {
            const {data} = await api[method](`${restUrl}/${restService}${values.id ? `/${values.id}` : ''}`, formData)
            updateValues({
                video_id: data.id,
                is_being_transmitted: true,
            })
            // dispatch(showAlert('imageSaveSuccessLabel'))

        } catch (e) {
            dispatch(showAlert('sthWentWrongWarning', '', 'alert-fail'))
            console.error('Error submitEditForm')
        } finally {
            setIsVideoUploading(false)
        }
    }

    const handleDragOver = (e) => {
        e.preventDefault();
        e.stopPropagation();
        dropRef?.current?.classList?.add('bg-[#F9FAFB]');
    };

    const handleDragLeave = (e) => {
        e.preventDefault();
        e.stopPropagation();
        dropRef?.current?.classList?.remove('bg-[#F9FAFB]');
    };

    const handleDrop = async (e) => {
        e.preventDefault();
        e.stopPropagation();
        dropRef?.current?.classList?.remove('bg-[#F9FAFB]');
        setIsToBig(false);
        setIsInvalidFormat(false);

        const files = e.dataTransfer.files;
        if (files && files.length > 0) {
            const file = files[0]

            const fileExtension = file.name.substring(file.name.lastIndexOf('.')).toLowerCase();

            if (!validExtensions.includes(fileExtension)) {
                setIsInvalidFormat(true);
                e.dataTransfer?.clearData();
                return;
            }

            if ((file.size / 1000000) > 2000) {
                setIsToBig(true);
                return;
            }

            // if ((file.size / 1000000) > 200) {
            await uploadToCloudflareServer(file)
            // return;
            // }

            // await onSubmit(file)
            e.dataTransfer?.clearData();

        }
    };

    const sendVideoUrl = async (video_url) => {
        try {
            const {data} = await api.put(`${restUrl}/${services.API_TUS_VIDEO}/${values.id}`, {
                video_url
            })

            updateValues({
                video_id: data.id,
                is_being_transmitted: true,
            })

        } catch (error) {
            console.log(error);
        } finally {
            setIsVideoUploading(false);
        }
    };

    const handleFileChange = async (e) => {
        setIsToBig(false);
        setIsInvalidFormat(false);

        const files = e.target.files;
        if (files && files.length > 0) {
            const file = files[0]

            const fileExtension = file.name.substring(file.name.lastIndexOf('.')).toLowerCase();

            if (!validExtensions.includes(fileExtension)) {
                setIsInvalidFormat(true);
                e.dataTransfer?.clearData();
                return;
            }

            if ((file.size / 1000000) > 2000) {
                setIsToBig(true);
                return;
            }

            // if ((file.size / 1000000) > 200) {
            await uploadToCloudflareServer(file)
            // return;
            // }

            // await onSubmit(file)
        }
    };

    const uploadToCloudflareServer = async (file) => {
        setIsVideoUploading(true);
        setIsToBig(false);
        setIsInvalidFormat(false);

        try {
            const upload = new tus.Upload(file, {
                endpoint: 'https://api.cloudflare.com/client/v4/accounts/9b0b490bc703e5cdf991c84548c1d5cb/stream',
                chunkSize: 50 * 1024 * 1024,
                headers: {
                    Authorization: 'Bearer yFBSL5ZLNdDCVjNBnOXiWFhd7aE0I9i19iLsu0rT',
                },
                retryDelays: [0, 3000, 5000, 10000, 20000],
                metadata: {
                    name: file.name,
                    filetype: file.type,
                },
                onError: function (error) {
                    console.log('Failed because: ' + error);
                },
                onProgress: function (bytesUploaded, bytesTotal) {
                    const percentage = ((bytesUploaded / bytesTotal) * 100).toFixed(2);
                    setProgress(percentage)
                },
                onSuccess: function () {
                    sendVideoUrl(upload.url)
                },
            });

            upload.findPreviousUploads().then(function (previousUploads) {
                // if (previousUploads.length) {
                //     console.log(previousUploads)
                //     upload.resumeFromPreviousUpload(previousUploads[0]);
                // }
                upload.start();
            });
        } catch (e) {
            console.log('cos nie tak')
        }
    };


    return (
        <section className="bg-[#fff] rounded-[5px] p-[25px]">
            <div className="font-bold text-xl mb-3">{getLangText('addVideo')}</div>
            {(!!values.video_id || isVideoUploading) &&
                <div className="text-sm font-medium mb-2">{getLangText('addedVideo')}</div>
            }
            {(isVideoUploading || !!+values.is_being_transmitted) ?
                <div className="flex items-start bg-[#F5FCFF] border border-[#5FA5FA] px-6 py-3 rounded-[5px]">
                    <Loader
                        className="small-loader static-loader mr-2.5 mt-0.5 w-auto bg-transparent"
                        isLoading
                    />
                    <div>
                        <div className="font-bold text-sm">
                            {getLangText(!!+values.is_being_transmitted ? 'theVideoIsBeingProcessed' : 'videoIsBeingSent')}
                        </div>
                        <div className="text-desc text-xs whitespace-pre-wrap"
                             dangerouslySetInnerHTML={{__html: getLangText(!!+values.is_being_transmitted ? 'sthWentWrongBatch' : 'itMakeTakeAWhileDoNotClosePage')}}/>
                        {!!progress &&
                            <div className="w-full h-1.5 bg-gray-200 rounded-lg mt-2">
                                <div
                                    className="h-1.5 bg-[#5fa5fa] rounded-lg transition-all"
                                    style={{width: `${progress}%`}}
                                />
                            </div>
                        }
                    </div>
                </div> :
                !!values.facebook_published_date ?
                    <div className="bg-[#F9FAFB] p-3 rounded-[5px] tooltip-parent">
                        <div className="tooltip-content small-size leading-[16px] text-center">
                            {getLangText('videoHasBeenPublishedOnFacebook')}
                        </div>
                        <div className="flex items-center">
                            <img className="min-w-[16px]" alt="tick" width={16}
                                 src="/assets/images/svg/circle-check.svg"/>
                            <div className="ml-2">
                                <div className="text-sm font-bold">{getLangText('addedAndProcessedVideo')}</div>
                                <div className="text-xs text-desc">{values.video_name}</div>
                            </div>
                        </div>
                    </div>
                    :
                    <div
                        ref={dropRef}
                        onDragOver={handleDragOver}
                        onDragLeave={handleDragLeave}
                        onDrop={handleDrop}
                        onClick={() => fileInputRef?.current?.click()}
                        className={`hover:bg-[#F9FAFB] cursor-pointer rounded-[5px] w-full transition-background duration-300 border overflow-hidden ${!!values.video_id ? 'border-[#DFE1E4]' : 'border-dashed'}`}
                    >
                        {!!+values.is_error ?
                            <div className="bg-[#F9FAFB] p-3">
                                <div className="flex items-center">
                                    <img className="min-w-[16px]" alt="cross" width={16}
                                         src="/assets/images/svg/cross.svg"/>
                                    <div className="ml-2">
                                        <div
                                            className="text-sm font-bold">{getLangText('sthWentWrongWarning')}</div>
                                        <div className="text-xs text-desc">{getLangText('selectAgain')}</div>
                                    </div>
                                    <div className="ml-auto">
                                        <input
                                            type="file"
                                            accept="video/*"
                                            onChange={handleFileChange}
                                            className="hidden"
                                            id="fileUpload"
                                            ref={fileInputRef}
                                        />
                                        <div
                                            className="button border-button left-icon mb-0 whitespace-nowrap"
                                        >
                                            <i className="icon-upload mr-1"/>
                                            {getLangText('selectAgain')}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :
                            !!values.video_id ?
                                <div className="bg-[#F9FAFB] p-3">
                                    <div className="flex items-center">
                                        <img className="min-w-[16px]" alt="tick" width={16}
                                             src="/assets/images/svg/circle-check.svg"/>
                                        <div className="ml-2 mr-2">
                                            <div
                                                className="text-sm font-bold">{getLangText('addedAndProcessedVideo')}</div>
                                            <div className="text-xs text-desc break-all">{values.video_name}</div>
                                        </div>
                                        <div className="ml-auto">
                                            <input
                                                type="file"
                                                accept="video/*"
                                                onChange={handleFileChange}
                                                className="hidden"
                                                id="fileUpload"
                                                ref={fileInputRef}
                                            />
                                            <div
                                                className="button border-button left-icon mb-0 whitespace-nowrap"
                                            >
                                                <i className="icon-upload mr-1"/>
                                                {getLangText('changeVideo')}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                :
                                <div className="text-center py-16">
                                    <div
                                        className="w-[30px] mx-auto h-[30px] rounded-[5px] bg-[#E5E7EB] flex items-center justify-center text-[#230C34] mb-2">
                                        <i className="icon-upload"/>
                                    </div>
                                    <div className="font-bold sm:text-lg">{getLangText('selectVideoToUpload')}</div>
                                    <div
                                        className="font-medium text-sm text-desc">{getLangText('orDragAndDropHere')}</div>
                                    <div className="mt-3">
                                        <input
                                            type="file"
                                            accept="video/*"
                                            onChange={handleFileChange}
                                            className="hidden"
                                            id="fileUpload"
                                            ref={fileInputRef}
                                        />
                                        <div
                                            style={{fontSize: 14}}
                                            className="button primary small-size mb-0"
                                        >
                                            {getLangText('selectVideo')}
                                        </div>
                                    </div>
                                </div>
                        }
                    </div>
            }
            {isToBig &&
                <div className="bg-[#FEF2F2] rounded-[5px] flex p-2 text-[#981B1B] mt-2">
                    <div>
                        <i className="icon-alert-c"/>
                    </div>
                    <div className="ml-2">
                        <div className="font-bold text-sm">
                            {getLangText('maxVideo200')}
                        </div>
                        <a
                            href="https://www.freeconvert.com/video-compressor"
                            target="_blank"
                            className="font-medium text-sm underline"
                        >
                            {getLangText('useConverterOnline')}
                        </a>
                    </div>
                </div>
            }
            {isInvalidFormat &&
                <div className="bg-[#FEF2F2] rounded-[5px] flex items-center p-2 text-[#981B1B] mt-2">
                    <div>
                        <i className="icon-alert-c"/>
                    </div>
                    <div>
                        <div className="font-bold text-sm ml-2">
                            {getLangText('invalidFileType')}
                        </div>
                        <div className="font-medium text-xs ml-2">
                            {getLangText({key: 'acceptableFormats', data: [validExtensions.join(', ')]})}
                        </div>
                    </div>
                </div>
            }
            <ValidationBox
                visible={hasError}
                title={isVideoUploading ? 'theVideoIsBeingUploadedSavingWillBeAvailableOnceItIsFinished' : 'completeThisStepToGo'}
            />
            {!!+userData.selmo_pro_active &&
                <div className="form-info-box w-100 mt-3">
                    <div>
                        <i className="icon-info-c"/>
                    </div>
                    <div>
                        <div className="title">
                            {getLangText('youCanDoItDirectlyByPhone')}
                        </div>
                        <div className="desc">
                            <button
                                onClick={() => setShowMobilePermissionsModal(true)}
                                className="underline"
                                type="button">
                                {getLangText('seeHowToDoIt')}
                            </button>
                        </div>
                    </div>
                </div>
            }
            {showMobilePermissionsModal &&
                <MobileAppPermissionsModal
                    modalVisible={showMobilePermissionsModal}
                    hideModal={() => setShowMobilePermissionsModal(false)}
                />
            }
        </section>
    );
};

VideoComponent.defaultProps = {
    restService: 'api/media',
    field: 'file',
}

export default VideoComponent;
