import React from 'react';
import useFormLocal from "../../../src_shared/hooks/useFormLocal";
import {services} from "../../RestServices";
import useLang from "../../../src_shared/hooks/useLang";
import SwitchField from "../../../src_shared/components/form/switchField/SwitchField";
import Loader from "../../app/shared/Loader";
import ComponentLoader from "../../app/shared/components/componentLoader/ComponentLoader";
import {Link} from "react-router-dom";
import {RouterPaths} from "../../routes/RouterPath";
import Model from "../../../src_shared/modules/model/Model";
import {Helmet} from "react-helmet";

const MobileApp = () => {
	const {getLangText} = useLang();

	const [{
		onSave,
		isWaiting,
		getFieldProps,
		isLoading,
	}] = useFormLocal({
		rest: services.API_MOBILE_NOTIFICATIONS,
		model: new Model(),
	});

	if (isLoading) return <ComponentLoader/>;

	return (
		<>
			<Helmet>
				<meta charSet="utf-8"/>
				<title>{getLangText('mobileAppSelmo')} - Selmo</title>
			</Helmet>
			<div className="header-bar breadcrumbs-in-react-app">
				<ul className="breadcrumbs">
					<li>
						<Link to={RouterPaths.Settings}>{getLangText('asideSettingsLabel')}</Link>
					</li>
					<li>
						<Link to={RouterPaths.SettingsShop}>{getLangText('settings_nav_shop_settings')}</Link>
					</li>
					<li className="active">
						<div>{getLangText('mobileAppSelmo')}</div>
					</li>
				</ul>
			</div>
			<div className="settings-page sub-settings-page">
				<div className="header-wrapper">
					<h1>
						{/*<img src="/assets/images/settings/live-settings.svg" alt="live-settings"/>*/}
						{getLangText('mobileAppSelmo')}
					</h1>
					<div className="description">
						{getLangText('mobileAppSelmoSubLabel')}
					</div>
				</div>
				<div className="settings-form">
					<section>
						<div className="left-side">
							<h2 className="label">
								{getLangText('visibilityInApplication')}
							</h2>
						</div>
						<div className="form-col">
							<SwitchField
								{...getFieldProps('mobile_app_orders')}
								label={getLangText('orderHistoryVisible')}
								subLabel={getLangText('orderHistoryVisibleSubLabel')}
								id="mobile_app_orders"
								className="switch-form inline-label big-label align-items-start light-version mb-3"
							/>
							<SwitchField
								{...getFieldProps('mobile_app_reserve_list')}
								label={getLangText('reserveListVisible')}
								subLabel={getLangText('reserveListVisibleSubLabel')}
								id="mobile_app_reserve_list"
								className="switch-form inline-label align-items-start big-label light-version mb-3"
							/>
							<SwitchField
								{...getFieldProps('mobile_app')}
								label={getLangText('shopVisibleInAppLabel')}
								id="mobile_app"
								className="switch-form inline-label  big-label light-version mb-0"
							/>
						</div>
					</section>
					<div className="flex items-center w-full">
						<button
							type="button"
							className={`button primary ml-auto small-size ${isWaiting ? 'loading-button' : ''}`}
							onClick={onSave}
						>
							{isWaiting && <Loader isLoading/>}
							{getLangText('saveButton')}
						</button>
					</div>
				</div>
			</div>
		</>
	);
};

export default MobileApp;
