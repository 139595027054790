import React from "react";
import {Helmet} from "react-helmet";
import Banners from "./components/Banners";
import Categories from "./components/categories/Categories";
import useLang from "../../../../src_shared/hooks/useLang";
import ShopNameBar from "../../../routes/shopSettingsRoute/ShopNameBar";

const ShopMainPage = () => {
	const {getLangText} = useLang();

	return (
		<>
			<ShopNameBar/>
			<div className="settings-page sub-settings-page loader-parent">
				<Helmet>
					<meta charSet="utf-8"/>
					<title>{getLangText('shopSettingsMainPageLabel')}Selmo</title>
				</Helmet>
				<div className="settings-form">
					<div className="section-header">
						<h1>{getLangText('mainPageLabel')}</h1>
						<div className="desc">{getLangText('editTextsOnMainPageLabel')}</div>
					</div>
					<Banners/>
					<Categories/>
				</div>
			</div>
		</>
	);
};

export default ShopMainPage;



