import Model, {convertObjectProperties} from "../../src_shared/modules/model/Model";
import ValidationError from "../../src_shared/form/validation/ValidationError";
import _ from "lodash";
import {VANITY_SHOP_ID} from "../../src_shared/enums/TrustedShopsIds";

const squValidator = (value, data) => {
	if (!!+data.unlimited_squ) {
		return false;
	}
	if (!+data.has_sizes) {
		if (!value) {
			return 'fieldIsRequiredLabel'
		}
		return value >= 0 ? false : "incorrectValueWarning"
	}
	return false
}

const moreThanZero = (value) => {
	if (!value) {
		return 'fieldIsRequiredLabel'
	}
	return value >= 0 ? false : "incorrectValueWarning"
}

const sizeValidator = (value, data) => {
	if (!+data.has_sizes) {
		return false
	}
	const deletedSizes = data.sizes.filter((i) => i.deleted === '1');
	if (data.sizes.length === deletedSizes.length) {
		return false
	}

	return data.sizes.some((item) => !+item.deleted && (!item.sizeName || !item.sizePrice || !item.sizeSqu)) ? 'Error' : false;
}

const sizesConverter = (sizes, initialId) => {
	if (!sizes.length) {
		return [{
			id: initialId,
			sizePrice: '',
			sizeName: '',
			sizeSqu: 1,
		}]
	}
	return sizes.map((i) => ({
		id: i.id,
		sizeName: i.size,
		sizePrice: i.price,
		sizeSqu: +i.squ < 0 ? '0' : i.squ,
		deleted: +i.deleted ? i.deleted : '0',
		is_product_used: i.is_product_used,
		photo: i.photo,
		external_id: i.external_id,
		reserve_list_sum: i.reserve_list_sum,
	}))
}


const convertedSizes = (sizes, data) => sizes.map((size) => {
	if (size.id?.includes('temp')) {
		return {
			size: size.sizeName,
			squ: size.sizeSqu,
			price: size.sizePrice,
			selmo_code_size: `${data.selmo_code} ${size.sizeName}`,
			deleted: size.deleted,
			photo: size.photo,
			external_id: size.external_id || null
		}
	}
	return {
		size_id: size.id,
		size: size.sizeName,
		squ: size.sizeSqu,
		price: size.sizePrice,
		deleted: size.deleted,
		selmo_code_size: `${data.selmo_code} ${size.sizeName}`,
		photo: size.photo,
		external_id: size.external_id || null
	};
})

export class ProductModel extends Model {
	getModel() {
		return {
			id: 0,
			name: '',
			squ: 1,
			price: '',
			selmo_code: '',
			sizes: [{
				id: this.createNewId(), sizePrice: '', sizeName: '', sizeSqu: '0', deleted: '0',
			}],
			has_sizes: '0',
			active: '1',
			deleted: '0',
			description: '',
			localPhoto: null,
			current_cart: [],
			finished_cart: [],
			reserve_list: [],
			reserveListTotal: 0,
			finishedCartTotal: 0,
			currentCartTotal: 0,
			categories: [],
			old_price: null,
			purchase_price: null,
			weight: '0',
			is_shop_active: 0,
			tags: [],
			digital: '0',
			attachment_name: null,
			attachment_path: null,
			manufacturers_code: '',
			external_id: '',
			warehouse_location: '',
			is_mobile_app_active: '0',
			manufacturer_id: null,
			vat_rate: null,
		};
	}

	getValidators() {
		return {
			name: ValidationError.notEmpty,
			squ: squValidator,
			price: moreThanZero,
			selmo_code: ValidationError.notEmpty,
			sizes: sizeValidator,
			sizeName: ValidationError.notEmpty,
			sizePrice: moreThanZero,
			sizeSqu: ValidationError.notEmpty,
			localPhoto: ValidationError.skip,
			has_sizes: ValidationError.skip,
			active: ValidationError.skip,
			deleted: ValidationError.skip,
			categories: ValidationError.skip,
			description: ValidationError.skip,
			old_price: ValidationError.skip,
			purchase_price: ValidationError.skip,
			weight: ValidationError.skip,
			is_mobile_app_active: ValidationError.skip,
			manufacturer_id: ValidationError.skip,
			vat_rate: ValidationError.skip,
		};
	}

	parseDTO(data) {
		return convertObjectProperties(data, {
			squ: () => +data.shop_id === VANITY_SHOP_ID ? data.squ : (+data.squ < 0 ? 0 : data.squ),
			sizes: (sizes) => sizesConverter(sizes, this.createNewId()),
			categories: (categories) => categories.map((category) => ({value: category.id, label: category.name})),
			old_price: (old_price) => !old_price ? '' : old_price,
			vat_rate: (vat_rate) => !vat_rate ? '' : vat_rate,
			manufacturers_code: (manufacturers_code) => !manufacturers_code ? '' : manufacturers_code,
			external_id: (external_id) => !external_id ? '' : external_id,
			manufacturer_id: (manufacturer_id) => !manufacturer_id ? '' : manufacturer_id,
		}, {
			current_cart: [],
			finished_cart: [],
			reserve_list: [],
			photos: [],
			tags: [],
			realSqu: data.squ,
			purchase_price: '',
			weight: '0',
			manufacturer_id: '',
			vat_rate: '',
			description: '',
			old_price: '',
			manufacturers_code: '',
			external_id: ''
		});
	}

	buildDTO(data, emptyData, inEditForm = false) {
		const diffSizes = _.differenceWith(data.sizes, emptyData.sizes, _.isEqual);

		const preparedSizes = () => {

			if (!+data.has_sizes) {
				return null;
			}

			if (data.selmo_code !== emptyData.selmo_code) {
				return convertedSizes(data.sizes, data);
			}

			if (!diffSizes?.length) {
				return [];
			}

			return convertedSizes(diffSizes, data)
		}

		if ((emptyData.squ === data.squ) && inEditForm) {
			delete data.squ
		}

		return {
			name: data.name,
			price: data.price,
			old_price: data.old_price,
			purchase_price: data.purchase_price,
			weight: data.weight || '0',
			selmo_code: data.selmo_code,
			active: data.active,
			is_shop_active: data.is_shop_active,
			has_sizes: data.has_sizes,
			squ: data.squ,
			photo: data.photo,
			warehouse_location: data.warehouse_location,
			manufacturers_code: data.manufacturers_code || null,
			external_id: data.external_id || null,
			sizes: preparedSizes(),
			description: data.description,
			categories: data.categories.map((i) => i.value),
			digital: data.digital,
			attachment_name: data.attachment_name,
			attachment_path: data.attachment_path,
			is_mobile_app_active: data.is_mobile_app_active,
			manufacturer_id: data.manufacturer_id,
			vat_rate: data.vat_rate
		};
	}
}

export default new ProductModel();
