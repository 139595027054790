import React, {useCallback} from 'react';
import {Link} from "react-router-dom";
import {RouterPaths} from "../../../../../routes/RouterPath";
import ClientAvatar from "../../../../live/shared/Avatar";
import SetUnreadButton from "../setUnreadButton";
import SetConversationStatusButton from "../setConversationStatusButton";
import OrderModal from "../../../orderModal/OrderModal";
import AddOrderModal from "../../../addOrderModal/addOrderModal";
import Tags from "../Tags";
import {useDispatch, useSelector} from "react-redux";
import useLang from "../../../../../../src_shared/hooks/useLang";
import {setRightSideVisible} from "../../ChatBoxActions";
import MobileChatHeaderDropdown from "./MobileChatHeaderDropdown";
import useWindowDimensions from "../../../hooks/useWindowDimensions/useWindowDimensions";
import {
    USER_PERMISSIONS_MESSENGER_ORDERS_MENAGE,
    USER_PERMISSIONS_PRODUCTS_ADD
} from "../../../../shared/enums/UserPermissionsTypes";
import UserPermissionsWrapper from "../../../../../routes/UserPermissionsWrapper";

const ChatHeader = ({setStatusVisible, messageScrollRef, lastId}) => {

    const {getLangText} = useLang();
    const dispatch = useDispatch();
    const {width} = useWindowDimensions();
    const {userData} = useSelector((state) => state.session);
    const {isLoading, activeConversation} = useSelector((state) => state.messenger.chatBox)

    const hideRightSide = () => dispatch(setRightSideVisible(false))

    return (
        <div className="user-header">
            <div className="top-part">
                <Link
                    to={RouterPaths.Messenger}
                    onClick={hideRightSide}
                    className="go-to-conversations hover:no-underline text-gray-500"
                >
                    <i className="icon-arrows"/>
                </Link>
                <div className="profile-box lg:block flex">
                    <div className="name grow">
                        <div>
                            <ClientAvatar
                                photo={activeConversation.user_avatar}
                                type={activeConversation.facebook_conversation_id ? 'facebook' : 'instagram'}
                                fbLogo={userData.facebook_id && userData.instagram_id}
                            />
                        </div>
                        <div>
                            {activeConversation.user_name}
                            {activeConversation.shop_client_id &&
                                <div className="client-number">
                                    {getLangText('clientNumberFullLabel')}: {activeConversation.shop_client_id}
                                </div>
                            }
                        </div>
                    </div>
                    {width < 991 &&
                        <MobileChatHeaderDropdown
                            setStatusVisible={setStatusVisible}
                            lastId={lastId}
                            activeConversation={activeConversation}
                            hideRightSide={hideRightSide}
                            messageScrollRef={messageScrollRef}
                        />
                    }
                </div>
                {(!isLoading && width > 991) &&
                    <div className="top-bar-icons">
                        <SetUnreadButton activeConversation={activeConversation}/>
                        {activeConversation.conversation_status === '4' ?
                            <SetConversationStatusButton
                                tooltipLang={getLangText('moveToMainFolderLabel')}
                                icon="icon-cross"
                                statusNumber="0"
                                withRedirect
                                setVisibleRightSide={hideRightSide}
                            /> :
                            <SetConversationStatusButton
                                tooltipLang={getLangText('moveToReadyFolderLabel')}
                                icon="icon-tick"
                                statusNumber="4"
                                withRedirect
                                setVisibleRightSide={hideRightSide}
                            />
                        }
                        <UserPermissionsWrapper allowedPermissions={[USER_PERMISSIONS_MESSENGER_ORDERS_MENAGE]}>
                            <OrderModal activeConversation={activeConversation}/>
                            <AddOrderModal
                                setStatusVisible={setStatusVisible}
                                lastId={lastId}
                                activeConversation={activeConversation}
                                messageScrollRef={messageScrollRef}
                            />
                        </UserPermissionsWrapper>
                    </div>
                }
            </div>
            <Tags/>
        </div>
    );
};

export default ChatHeader;
