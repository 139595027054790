import React from 'react';

const ServerImage = ({src, alt, className, style, onClick = () => {}}) => {

    const preparedSrc = () => {
        if (src?.includes('.fb') || src?.includes('r2.') || src?.includes('https://app.selmo.io')) {
            return src;
        }

        return `https://app.selmo.io${src}`
    }

    return (
        <img
            onClick={onClick}
            src={preparedSrc()} alt={alt} className={className} style={style}
        />
    );
};

export default ServerImage;
