import React from 'react';
import useLang from "../../../../../../../src_shared/hooks/useLang";
import Loader from "../../../../Loader";
import moment from "../../../../moment";
import {convertToUserTimeZone} from "../../../../helpers/dateHelpers";

const productWithSizesQRCodesPdf = 'productWithSizesQRCodesPdf'
const productQRCodesPdf = 'productQRCodesPdf'
const shelvesQRCodesPdf = 'shelvesQRCodesPdf'
const chosenProductSizesQRCodesPdf = 'chosenProductSizesQRCodesPdf'

const fileTypes = [
    {id: productWithSizesQRCodesPdf, label: 'productWithSizesQRCodeLabel'},
    {id: productQRCodesPdf, label: 'productQRCodeLabel'},
    {id: shelvesQRCodesPdf, label: 'shelvesQRCodesPdfLabel'},
    {id: chosenProductSizesQRCodesPdf, label: 'shelvesQRCodesPdfLabel'},
]

const File = ({item}) => {
    const {getLangText} = useLang();

    return (
        <div
            key={item.id}
            className="flex"
        >

            {item.url ?
                <img
                    className="w-[12px] min-w-[12px] mt-[6px] self-start"
                    src="/assets/images/svg/circle-check.svg"
                    alt="check"
                /> :
                <Loader isLoading={true}
                        className="w-[12px] static-loader extra-small-loader mt-[6px] transform-none"/>
            }
            <div className="text-sm ml-1">
                <strong className="font-bold text-xs">{getLangText(fileTypes.find((i) => i.id === item.export_type)?.label) || item.export_type}</strong>
                <div className="text-desc font-medium text-xs">{convertToUserTimeZone(item.date).format('DD.MM.YYYY HH:mm')}</div>
                {item.url ?
                    <div>
                        <a
                            href={item.url}
                            download
                            target="_blank"
                            className="inline-block text-[10px] leading-[12px] hover:no-underline px-2 py-[4px] rounded-[3px] no-underline bg-[#3C61EA] font-bold text-[white] hover:text-[white]"
                        >
                            {getLangText('downloadLabel')}
                        </a>
                    </div> :
                    <div className="text-[10px] leading-[12px] font-medium text-desc">Trwa generowanie</div>
                }
            </div>
        </div>
    );
};

export default File;
