import ValidationError from "../../../src_shared/form/validation/ValidationError";
import {Model} from "../../../src_shared/modules/model/Model";

class SelmoPaczkaPointActivationModal extends Model {
    getModel() {
        return {
            dpd_active: '1',
            dpd_price: '',
            op_active: '1',
            op_price: '',
        };
    }

    getValidators() {
        return {
            dpd_price: (value, data) => !!+data.dpd_active ? ValidationError.greaterThan(0)(value, data) : ValidationError.skip() ,
            op_price: (value, data) => !!+data.op_active ? ValidationError.greaterThan(0)(value, data) : ValidationError.skip() ,
            dpd_active: ValidationError.skip,
            op_active: ValidationError.skip,
        };
    }
}

export default new SelmoPaczkaPointActivationModal();
