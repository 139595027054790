import React, {useEffect} from 'react';
import Modal from "react-bootstrap/Modal";
import useLang from "../../hooks/useLang";
import Loader from "../loader/Loader";
import {useDownloadFile} from "../../hooks/useDownloadFile";
import {useDispatch, useSelector} from "react-redux";
import useFormLocal from "../../hooks/useFormLocal";
import Field from "../form/field/Field";
import ExportModel from "../../../modules/models/export/ExportModel";
import useSubmitData from "../../hooks/useSubmitData";
import {showAlert} from "../../../components/app/shared/components/alert/AlertActions";
import {services} from "../../../components/RestServices";
import useFetchData from "../../hooks/useFetchData";

const ExportModal = ({
                         show,
                         hide,
                         rest,
                         fileName,
                         fileType = 'pdf',
                         dialogClassName = 'small-modal',
                         submitButtonClass = 'primary',
                         headerClassName = '',
                         title,
                         subTitle,
                         saveButton,
                         children,
                         backdropClassName,
                         modalClasName,
                         disabledSubmitButton = false,
                         initialCheckBigFile = true,
                     }) => {
    const {getLangText} = useLang();
    const {userData} = useSelector((state) => state.session);
    const dispatch = useDispatch();

    const checkRestHasLink = (link) => {
        if (link.includes('?')) {
            link += '&' + 'check_size=1';
        } else {
            link += '?' + 'check_size=1';
        }
        return link;
    }

    const [{getFieldProps, values, setValue, updateValues}] = useFormLocal({
        model: ExportModel,
        initialAutoLoad: false,
    });

    const [{isLoading}] = useFetchData({
        rest: checkRestHasLink(rest),
        initialAutoLoad: initialCheckBigFile,
        afterSubmit: (response) => updateValues(response)
    })

    const [{onSave, isWaiting}] = useSubmitData(
        {
            rest: services.API_EXPORT_BIG_XLSX_FILE,
            values,
            afterSubmit: () => {
                dispatch(showAlert('yuhuLabel', 'successfullyCommissionedSentFileOnEmailLabel'))
                hide();
            }
        });

    const {download, waiting} = useDownloadFile({
        rest,
        fileName,
        afterSubmit: hide,
        fileType
    })

    const onSubmit = async () => {
        if (values.bigFile) {
            await onSave();
        } else {
            await download();
        }
    }

    useEffect(() => {
        if (values.bigFile && userData.email) {
            setValue('email', userData.email)
        }
    }, [userData.email, values.bigFile])

    return (
        <Modal
            dialogClassName={`default-modal ${dialogClassName}`}
            show={show}
            onHide={hide}
            backdropClassName={backdropClassName}
            className={modalClasName}
        >
            <div className="loader-parent">
                <Loader isLoading={isLoading}/>
                <Modal.Header className={headerClassName}>
                    <Modal.Title>
                        {title}
                        {subTitle &&
                            <div className="description" dangerouslySetInnerHTML={{__html: subTitle}}/>
                        }
                    </Modal.Title>
                    <button
                        onClick={hide}
                        type="button"
                        className="button"
                    >
                        <i className="icon-cross"/>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    {children}
                    {values.bigFile &&
                        <>
                            <div className="form-info-box mb-3 w-full">
                                <div>
                                    <i className="icon-info-c"/>
                                </div>
                                <div>
                                    <div className="title">{getLangText('fileIsToLargeLabel')}</div>
                                    <div className="desc">{getLangText('fileIsToLargeSubLabel')}</div>
                                    <div
                                        className="desc mt-2 font-weight-bolder">{getLangText('fileIsToLargeTimeInfoLabel')}</div>
                                </div>
                            </div>
                            <Field
                                {...getFieldProps('email')}
                                value={values.email}
                                className="big-size"
                                label={getLangText('emailLabel')}
                                type="email"
                                floatedLabel
                                extraProps={{
                                    autoFocus: true
                                }}
                            />
                        </>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <button
                        onClick={hide}
                        type="button"
                        className="button border-button"
                    >
                        {getLangText('cancelButton')}
                    </button>
                    <button
                        disabled={disabledSubmitButton}
                        onClick={onSubmit}
                        type="button"
                        className={`button px-0 ${submitButtonClass} ${(waiting || isWaiting) ? 'loading-button' : ''}`}
                    >
                        {(waiting || isWaiting) && <Loader isLoading/>}
                        {saveButton}
                    </button>
                </Modal.Footer>
            </div>
        </Modal>
    )
        ;
};

export default ExportModal;
