import Model, {convertObjectProperties} from "../../../src_shared/modules/model/Model";
import ValidationError from "../../../src_shared/form/validation/ValidationError";
import {SHOP_ORIGIN_US} from "../../../components/app/shared/enums/ShopOrigins";

export const validatePhone = (value, data) => {
    if (!data.shipping_cart) {
        return false;
    }
    const countryName = data.selectedCountry?.name;
    if (countryName && countryName.toLowerCase() === 'polska') {
        return ValidationError.combine(
            ValidationError.notEmpty,
            ValidationError.validatePhone,
            ValidationError.maxLength(9),
        )(value, data)
    }
    return ValidationError.notEmpty(value);
}

export const validateZipCode = (value, data) => {
    if (!data.shipping_cart) {
        return false;
    }
    const countryName = data.selectedCountry?.name;
    if (countryName && countryName.toLowerCase() === 'polska') {
        return ValidationError.combine(
            ValidationError.notEmpty,
            ValidationError.validateZipCode,
        )(value, data)
    }
    return ValidationError.notEmpty(value);
}

const checkShippingCartValidation = (value, data) => {
    if (!data.shipping_cart) {
        return false;
    }
    return ValidationError.notEmpty(value, data);
}

export class DeliveryAddressModel extends Model {
    getModel() {
        return {
            id: 0,
            first_name: '',
            last_name: '',
            email: '',
            country: '',
            street: '',
            building_number: '',
            city: '',
            zip_code: '',
            phone: '',
            extra_description: '',
            countries: [],
            selectedCountry: {},
            nip: null,
            want_invoice: '0'
        };
    }

    getValidators() {
        return {
            first_name: checkShippingCartValidation,
            last_name: checkShippingCartValidation,
            email: ValidationError.combine(
                ValidationError.notEmpty,
                ValidationError.validateEmail,
            ),
            country: ValidationError.notEmpty,
            street: checkShippingCartValidation,
            building_number: checkShippingCartValidation,
            city: checkShippingCartValidation,
            zip_code: validateZipCode,
            phone: validatePhone,
            state_name: ValidationError.skip,
            origin: ValidationError.skip,
            state: (value, data) => data?.selectedCountry?.name?.toLowerCase()?.includes('united states') ? ValidationError.notEmpty(value) : false,
            extra_description: ValidationError.skip,
            nip: (value, data) => (data?.selectedCountry?.name?.toLowerCase().includes('polska') && !!+data.want_invoice) ? ValidationError.validateNip(value) : ValidationError.skip(),
        };
    }

    parseDTO(data) {
        return convertObjectProperties(
            data,
            {},
            {
                selectedCountry: {
                    id: data.country_id,
                    name: data.country_name
                },
                country: data.country_id,
                want_invoice: !!data.nip
            },
        );
    }

    buildDTO(data) {
        const {
            agreement,
            building_number,
            city,
            country,
            email,
            extra_description,
            first_name,
            last_name,
            magic_url,
            phone,
            shop_id,
            street,
            zip_code,
            nip,
            state,
        } = data;

        return {
            agreement,
            building_number,
            city,
            country,
            email,
            extra_description,
            first_name,
            last_name,
            magic_url,
            phone,
            shop_id,
            street,
            zip_code,
            state: data.origin === SHOP_ORIGIN_US ? state : null,
            nip: !!+data.want_invoice ? nip : null,
        };
    }
}

export default new DeliveryAddressModel();
