import React, {useMemo} from 'react';
import useLang from "../../../../../src_shared/hooks/useLang";
import {Link} from "react-router-dom";
import {RouterPaths} from "../../../../routes/RouterPath";
import {convertToUserTimeZone} from "../../helpers/dateHelpers";
import {useSelector} from "react-redux";
import {getCorrectPriceCurrency} from "../../../vacation/Vacation";
import {useCountdown} from "../../../../../src_shared/hooks/useCountDown";
import moment from "moment";

const RedTopBar = () => {
    const {getLangText} = useLang();
    const {userData} = useSelector((state) => state.session);
    const {origin} = useSelector((state) => state.session.userData);
    const newDate = convertToUserTimeZone(moment('28.06.2024 12:00:00', 'DD.MM.YYYY HH:mm:ss').toDate()).toDate()
    const todayDate = convertToUserTimeZone(moment(new Date(), 'DD.MM.YYYY HH:mm:ss').toDate()).toDate()
    const {hours, minutes, seconds} = useCountdown(newDate);

    const countDownVisible = newDate >= todayDate

    const price = useMemo(() => `${getCorrectPriceCurrency(origin).price} ${getCorrectPriceCurrency(origin).shortcut}`, [origin])

    const countBox = (time) => (
        <div
            className="text-center w-[20px] h-[16px] flex items-center justify-center text-[#fff] rounded-md bg-[rgba(255,255,255,0.35)]">
            <div className="text-xs font-bold">
                {time}
            </div>
        </div>
    )

    if (!countDownVisible) return null

    if (!!+userData.selmo_pro_active || convertToUserTimeZone(userData.user_info.premium_date).toDate() > convertToUserTimeZone('23.12.2024', 'DD.MM.YYYY').toDate()) {
        return null;
    }

    return (
        <Link
            to={RouterPaths.Vacation}
            className="w-full bg-[#EF4444] flex-wrap flex text-[#fff] items-center font-semibold text-xs justify-center min-h-[40px] py-1 px-2 sm:px-6 hover:text-[#fff] hover:no-underline group">
            {countDownVisible ?
                <>
                    <div className="flex items-center space-x-0.5 mr-1">
                        {/*{!!+days && <>*/}
                        {/*    {countBox(days)}*/}
                        {/*    <div>:</div>*/}
                        {/*</>}*/}
                        {countBox(hours)}
                        <div>:</div>
                        {countBox(minutes)}
                        <>
                            <div>:</div>
                            {countBox(seconds)}

                        </>
                    </div>
                    {getLangText({
                        key: 'toEndPromoVariable',
                        data: [price],
                    })}
                </> :
                <span className="whitespace-nowrap">
                        {getLangText({
                            key: 'newOfferLastDaysOfPromoV2', data: [price],
                        })}
                </span>
            }
            <div
                className={`bg-[#fff] relative button white px-[8px] py-[3px] font-bold rounded-[5px] whitespace-nowrap ml-2 text-[#EF4444] group-hover:text-red-700 transition-all`}
            >
                {getLangText('seeMoreButton')}
            </div>
        </Link>
    );
};

export default RedTopBar;
