import ValidationError from "../../../src_shared/form/validation/ValidationError";
import {Model} from "../../../src_shared/modules/model/Model";

const validatePhone = (value, data) => {
    return ValidationError.combine(
        ValidationError.notEmpty,
        ValidationError.validatePhone,
        ValidationError.maxLength(9, 'incorrectPhoneWarning'),
    )(value, data)
}

export const validateZipCode = (value, data) => {
    return ValidationError.combine(
        ValidationError.notEmpty,
        ValidationError.validateZipCode,
    )(value, data)
}

class SelmoPaczkaActivateModal extends Model {
    getModel() {
        return {
            first_name: '',
            last_name: '',
            email: '',
            street: '',
            building_number: '',
            company_name: '',
            city: '',
            zip_code: '',
            phone: '',
            nip: '',
            agreement: '0',
        };
    }

    getValidators() {
        return {
            first_name: ValidationError.notEmpty,
            last_name: ValidationError.notEmpty,
            email: ValidationError.combine(
                ValidationError.notEmpty,
                ValidationError.validateEmail,
            ),
            country: ValidationError.notEmpty,
            nip: ValidationError.combine(
                ValidationError.validateNip,
                ValidationError.minLength(5),
                ValidationError.notEmpty,
            ),
            street: ValidationError.notEmpty,
            building_number: ValidationError.notEmpty,
            company_name: ValidationError.notEmpty,
            city: ValidationError.notEmpty,
            zip_code: validateZipCode,
            phone: validatePhone,
            agreement: ValidationError.notFalse,
        };
    }
}

export default new SelmoPaczkaActivateModal();
