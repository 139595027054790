import React, {useRef} from 'react';
import useDropdown from "../../../hooks/useDropdown";
import useLang from "../../../hooks/useLang";

const FiltersDropdown = ({
                             children,
                             onSave,
                             onClose,
                             buttonTittle,
                             className,
                             onSecondButtonClick,
                             tooltipTitle,
                             withSubLabel,
                             withClear
                         }) => {
    const dropdownRef = useRef(null);
    const buttonRef = useRef(null);
    const {getLangText} = useLang();

    const onSaveClick = () => {
        onSave();
        setOpen(false);
    };

    const onCloseClick = () => {
        onClose();
        setOpen(false);
    };

    const {open, setOpen} = useDropdown(buttonRef, dropdownRef, onCloseClick);

    return (
        <div className={`filters-wrapper ${className}`}>
            {(withSubLabel && buttonTittle.defaultSelectedLabel) &&
                <div
                    className="mb-1"
                    style={{fontSize: '10px', color: '#6B7280'}}
                >
                    {buttonTittle.defaultSelectedLabel}
                </div>
            }
            <button
                ref={buttonRef}
                type="button"
                onClick={() => setOpen(!open)}
                className={`button filters-button ${tooltipTitle ? 'tooltip-parent' : ''} ${buttonTittle.className} ${open ? 'active' : ''}`}
            >
                {tooltipTitle &&
                    <div className="tooltip-content">
                        {tooltipTitle}
                    </div>
                }
                <div
                    className="d-flex sm:text-left align-items-center flex-grow-1 font-weight-bold"
                    dangerouslySetInnerHTML={{__html: `${buttonTittle.text}`}}
                />
            </button>
            <div
                ref={dropdownRef}
                className={`dropdown-menu ${open ? 'show' : ''}`}
            >
                {children}
                <div className={`filters-buttons ${withClear ? '' : 'justify-content-end'}`}>
                    {withClear &&
                        <button
                            type="button"
                            className="reset-filter"
                            onClick={onSecondButtonClick}
                        >
                            <span>{getLangText('clearLabel')}</span>
                        </button>
                    }
                    <button
                        className={`button primary ${withClear ? '' : 'mr-0'}`}
                        type="button"
                        onClick={onSaveClick}
                    >
                        {getLangText('saveButton')}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default FiltersDropdown;
