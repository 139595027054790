import React from 'react';
import useLang from "../../../src_shared/hooks/useLang";
import { useSelector } from "react-redux";
import useFetchData from "../../../src_shared/hooks/useFetchData";
import { services } from "../../RestServices";
import AfterTrialView from "./AfterTrialView";
import TrialView from "./TrialView";
import useIsPremium from "../shared/hooks/useIsPremium";
import { Helmet } from "react-helmet";

const Dashboard = () => {
    const { getLangText } = useLang();
    const { userData } = useSelector((state) => state.session);
    const { isOnTrial } = useIsPremium();

    const [{ data, isLoading }] = useFetchData({
        rest: services.API_DASHBOARD,
        initialData: {
            trialPoints: {
                firstProduct: false,
                firstNotifications: false,
                firstBatchSummary: false,
                firstMessengerOrder: false,
                firstAutomation: false,
            },
            orderStatusSummary: {},
            ordersCounter: [],
            latestNews: {},
        }
    })

    return (
        <div className="max-w-[600px] w-full mx-auto sm:p-0 py-6 px-3">
            <Helmet>
                <title>{getLangText('dashboardLabel')} - Selmo</title>
            </Helmet>
            {isLoading ?
                <div>
                    <div className="animate-pulse">
                        <div className="h-7 bg-gray-200 rounded-xl dark:bg-gray-700 w-48 mb-6"></div>
                        <div className="h-60 bg-gray-200 rounded-xl dark:bg-gray-700 w-full mb-3"></div>
                        <div className="h-60 bg-gray-200 rounded-xl dark:bg-gray-700 w-full mb-3"></div>
                    </div>
                </div> :
                <>
                    <div
                        className="sm:text-2xl text-xl font-bold mb-6">{getLangText('startTrialEmailText1')}&nbsp;{userData.name}!
                        👋
                    </div>
                    {isOnTrial ?
                        <TrialView
                            data={data}
                        /> :
                        <AfterTrialView
                            data={data}
                        />
                    }
                </>
            }
        </div>
    );
};

export default Dashboard;
