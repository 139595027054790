import Model from "../../../../src_shared/modules/model/Model";
import ValidationError from "../../../../src_shared/form/validation/ValidationError";

export class LeadsModel extends Model {
	getModel() {
		return {
			id: 0,
			phone: '',
			comment: '',
			type: 1,
			seller: 0,
			lang: 'pl',
		}
	}

	getValidators() {
		return {
			phone: ValidationError.notEmpty,
			comment: ValidationError.skip,
			type: ValidationError.skip,
			seller: ValidationError.skip,
			lang: ValidationError.notEmpty,
		};
	}

	buildDTO(data) {
		return {
			...data,
			seller: !!+data.seller ? data.seller : null,
		};
	}
}

export default new LeadsModel();
