import React from 'react';
import OrdersBoxes from "./components/OrdersBoxes";
import OrdersChart from "./components/OrdersChart";
import DoMoreBoxes from "./components/DoMoreBoxes";
import BlogPost from "./components/BlogPost";
import AfterTrialMainVideo from "./components/AfterTrialMainVideo";

const AfterTrialView = ({data}) => {

    return (
        <div className="space-y-6 fadeInProgressAnimation">
            <BlogPost
                item={data?.latestNews}
            />
            <OrdersBoxes data={data?.orderStatusSummary}/>
            <OrdersChart data={data?.ordersCounter}/>
            <AfterTrialMainVideo />
            <DoMoreBoxes/>
        </div>
    );
};

export default AfterTrialView;
