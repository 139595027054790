const COMMISSION_PRICE_TYPE_PERCENT = '2'
const COMMISSION_PRICE_TYPE_CURRENCY = '1'
export const CommissionPriceTypes = [
	{
		value: COMMISSION_PRICE_TYPE_PERCENT,
		label: '%',
	},
	{
		value: COMMISSION_PRICE_TYPE_CURRENCY,
		label: 'input_addon_pln',
	},
];
