import React from 'react';
import useLang from "../../../../src_shared/hooks/useLang";
import {useSelector} from "react-redux";
import {
    SHOP_ORIGIN_DA,
    SHOP_ORIGIN_FR,
    SHOP_ORIGIN_PL,
    SHOP_ORIGIN_SK, SHOP_ORIGIN_US
} from "../../../app/shared/enums/ShopOrigins";
import {
    TEST_ACCOUNT_SHOP_ID
} from "../../../../src_shared/enums/TrustedShopsIds";
import {Link} from "react-router-dom";
import {RouterPaths} from "../../../routes/RouterPath";
import useFetchData from "../../../../src_shared/hooks/useFetchData";
import {services} from "../../../RestServices";
import ServerImage from "../../../app/shared/components/serverImage/ServerImage";
import {supportedOptionLangs} from "../../../../services/i18n/i18n";
import SettingsBox from "../../components/SettingsBox";

export const SETTINGS_INTEGRATIONS = [
    {
        id: 0,
        title: 'settings_nav_furgonetka',
        desc: 'settings_nav_furgonetka_description',
        imgSrc: '/assets/images/export/furgonetka-logo.svg',
        path: RouterPaths.SettingsIntegrationFurgonetka,
        origins: [SHOP_ORIGIN_PL],
    },
    {
        id: 1,
        title: 'settings_nav_inpost',
        desc: 'settings_nav_inpost_description',
        imgSrc: '/assets/images/inpost.svg',
        path: RouterPaths.SettingsIntegrationInpost,
        origins: [SHOP_ORIGIN_PL],
    },
    {
        id: 2,
        title: 'settings_nav_fakturownia',
        desc: 'settings_nav_fakturownia_description',
        imgSrc: '/assets/images/fakturownia-logo.svg',
        path: RouterPaths.SettingsIntegrationFakturownia,
        origins: [SHOP_ORIGIN_PL, SHOP_ORIGIN_FR, SHOP_ORIGIN_SK],
    },
    {
        id: 3,
        title: 'Shippy Pro',
        desc: 'configureLabelsOnShippyProLabel',
        imgSrc: '/assets/images/shippypro.png',
        path: RouterPaths.SettingsIntegrationShippyPro,
        shopIds: [TEST_ACCOUNT_SHOP_ID],
        origins: supportedOptionLangs.filter((item) => item.value !== SHOP_ORIGIN_PL && item.value !== SHOP_ORIGIN_SK).map((i) => i.value),
    },
    {
        id: 4,
        title: 'Paxy',
        desc: 'configureLabelsOnPaxyLabel',
        imgSrc: '/assets/images/paxy-logo.png',
        path: RouterPaths.SettingsIntegrationPaxy,
        shopIds: [TEST_ACCOUNT_SHOP_ID],
    },
    {
        id: 5,
        title: 'Mondial Relay',
        desc: 'configureLabelsOnPaxyLabel',
        imgSrc: '/assets/images/svg/logomondial-relay.svg',
        path: RouterPaths.SettingsIntegrationMondialRelay,
        origins: [SHOP_ORIGIN_FR],
        shopIds: [TEST_ACCOUNT_SHOP_ID],
    },
    {
        id: 7,
        title: 'Shipmondo',
        desc: 'configureLabelsOnShipmondoLabel',
        imgSrc: '/assets/images/shipmondo.png',
        path: RouterPaths.SettingsIntegrationShipmondo,
        shopIds: [TEST_ACCOUNT_SHOP_ID],
        origins: [SHOP_ORIGIN_DA],
    },
    {
        id: 8,
        title: 'Woocommerce',
        desc: 'configureIntegrationWooCommerce',
        imgSrc: '/assets/images/woocommerce-logo.webp',
        path: RouterPaths.SettingsIntegrationWoocommerce,
        shopIds: [TEST_ACCOUNT_SHOP_ID, 5075],
    },
    {
        id: 9,
        title: 'PrestaShop',
        desc: 'configureIntegrationPrestaShop',
        imgSrc: '/assets/images/svg/presta-logo.svg',
        path: RouterPaths.SettingsIntegrationPrestaShop,
        shopIds: [TEST_ACCOUNT_SHOP_ID],
    },
    {
        id: 10,
        title: 'DPD',
        desc: 'configureIntegrationDPD',
        imgSrc: '/assets/images/svg/dpd-logo.svg',
        path: RouterPaths.SettingsIntegrationDpd,
        origins: [SHOP_ORIGIN_SK],
    },
    {
        id: 10,
        title: 'GLS',
        desc: 'configureIntegrationGLS',
        imgSrc: '/assets/images/gls.png',
        path: RouterPaths.SettingsIntegrationsGls,
        origins: [SHOP_ORIGIN_SK],
        shopIds: [TEST_ACCOUNT_SHOP_ID],
    },
    {
        id: 11,
        title: 'GLS',
        desc: 'configureIntegrationGLS',
        imgSrc: '/assets/images/gls.png',
        path: RouterPaths.SettingsIntegrationsGlsFrance,
        origins: [SHOP_ORIGIN_FR],
        shopIds: [TEST_ACCOUNT_SHOP_ID],
    },
    {
        id: 12,
        title: 'Shopify',
        desc: 'configureIntegrationWith',
        imgSrc: '/assets/images/svg/shopify.svg',
        path: RouterPaths.SettingsIntegrationShopify,
        shopIds: [TEST_ACCOUNT_SHOP_ID],
        origins: [SHOP_ORIGIN_US],
    },
]


const IntegrationBoxes = () => {
    const {getLangText} = useLang();
    const {userData} = useSelector((state) => state.session);

    const [{data, isLoading}] = useFetchData({
        rest: services.API_SETTINGS_INTEGRATIONS,
        initialData: [],
    })

    return (
        <section>
            <div className="section-title">{getLangText('settings_nav_integrations')}</div>
            <div className="settings-box-wrapper border-boxes">
                {SETTINGS_INTEGRATIONS.map((item) => (
                    <SettingsBox
                        key={item.id}
                        item={{...item, imgStyle: {height: 18, width: 'auto', maxWidth: 85}}}
                    />
                ))}
                {userData.origin === SHOP_ORIGIN_US &&
                    <>
                        <div className="settings-box">
                            <div className="tooltip-parent">
                                <div className="tooltip-content">
                                    {getLangText('waitingForApproval')}
                                </div>
                                <div className="title">
                                    PirateShip
                                    <div style={{width: 'auto', height: '20px'}} className="right-img inpost-img">
                                        <img src="/assets/images/pirate-ship.png" alt="PirateShip"/>
                                    </div>
                                </div>
                                <div className="desc">
                                    {getLangText({key: 'configureIntegrationWith', data: ['PirateShip']})}
                                </div>
                                <div className="button-place">
                                    <div className="button border-button">
                                        {getLangText('button_go_to')}
                                        <i className="icon-arrows"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                }
                {isLoading &&
                    <div className="settings-box relative min-h-[120px]">
                        <span
                            className="animate-pulse h-full flex flex-col mb-1 bg-gray-200 rounded-md dark:bg-gray-600 w-full"/>
                        <span
                            className="absolute animate-pulse top-6 left-6 inline-block rounded-md w-[90px] h-[20px] bg-gray-300 dark:bg-gray-600"
                        />
                        <span
                            className="absolute animate-pulse top-12 left-6 inline-block rounded-md w-[160px] h-[16px] bg-gray-300 dark:bg-gray-600"
                        />
                        <span
                            className="absolute animate-pulse bottom-6 right-6 inline-block rounded-md w-[73px] h-[31px] bg-gray-300 dark:bg-gray-600"
                        />
                    </div>
                }
                {data.map((item) => (
                    <div className="settings-box">
                        <Link to={`${RouterPaths.SettingsIntegration}${item.name_url}`}>
                            <div className="title">
                                {item.name}
                                <div style={{width: 'auto', height: '18px'}} className="right-img inpost-img">
                                    <ServerImage
                                        src={item.photo}
                                    />
                                </div>
                            </div>
                            <div className="desc">
                                {item.description}
                            </div>
                            <div className="button-place">
                                <div className="button border-button">
                                    {getLangText('button_go_to')}
                                    <i className="icon-arrows"/>
                                </div>
                            </div>
                        </Link>
                    </div>
                ))}
            </div>
        </section>
    );
};

export default IntegrationBoxes;
