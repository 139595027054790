import React, {useState} from 'react';
import Loader from "../../../../../shared/Loader";
import {usePrintFile} from "../../../../../../../src_shared/hooks/usePrintFile";
import {services} from "../../../../../../RestServices";
import useLang from "../../../../../../../src_shared/hooks/useLang";
import AssignToPlace from "../AssignToPlace";
import {useDispatch} from "react-redux";
import FormActions from "../../FormActions";
import Variants from "./Variants";

const ShelfInfo = ({values}) => {
    const dispatch = useDispatch();
    const fetchData = () => dispatch(FormActions.loadData(values.id))

    const {print, waiting: waitingForQRCode} = usePrintFile({
        rest: `${services.API_MOBILE_QR_CODE}/${values.id}`,
    })

    const {getLangText} = useLang();

    const [showModal, setShowModal] = useState(false);

    return (
        <section className="default-shadow-box big-padd mb-3">
            <div className="left-side">
                <h2 className="font-bold mb-3.5">{getLangText('warehouseLocationLabel')}</h2>
            </div>
            <div className="form-col">
                <div className="flex items-center justify-between">
                    {!+values.has_sizes &&
                        <button
                            className={`button border-button small-size left-icon ${waitingForQRCode ? 'loading-button' : ''}`}
                            onClick={print}
                            type="button"
                        >
                            {waitingForQRCode && <Loader isLoading/>}
                            <i className="icon-qr-code"/>
                            QR kod produktu
                        </button>
                    }
                </div>
                {!!+values.has_sizes ?
                    <Variants values={values}/> :
                    <div
                        className={`rounded-[5px] inline-block p-2 text-sm mt-2 font-bold ${values.shelf_name ? 'bg-[#F9FAFB] border' : 'bg-[#FEF2F2] border border-[#F87171] text-[#981B1B]'}`}>
                        {values.shelf_name ?
                            values.shelf_name :
                            getLangText('lackLabel')
                        }
                    </div>
                }
                <div>
                    <button
                        className="button text-blue-button add-new-row no-focus"
                        type="button"
                        onClick={() => setShowModal(true)}
                    >
                        {getLangText('assignPlace')}
                    </button>
                </div>
                {showModal &&
                    <AssignToPlace
                        modalVisible={showModal}
                        hideModal={() => setShowModal(false)}
                        fetchData={fetchData}
                        values={{
                            ...values,
                            sizes: values.sizes_locations.map((i) => ({
                                ...i,
                                size: i.size,
                                squ: i.squ,
                            }))
                        }}
                    />
                }
            </div>
        </section>
    );
};

export default ShelfInfo;