import React, {useEffect, useState} from 'react';
import FiltersDropdown from "../filtersDropdown/FiltersDropdown";
import {batch, useDispatch, useSelector} from "react-redux";
import {createSelector} from "reselect";
import useLang from "../../../hooks/useLang";

const SingleSelectFiltersStatus = ({
                                       ListActions,
                                       FiltersActions,
                                       getFiltersStateRoot,
                                       className,
                                       extraProps,
                                       options,
                                       defaultSelectedLabel,
                                       name,
                                       allFieldName,
                                       withSubLabel = false,
                                       withClear,
                                   }) => {

    const dispatch = useDispatch();
    const {getLangText} = useLang();

    const selectedState = useSelector(createSelector(getFiltersStateRoot, (stateRoot) => stateRoot.data[name]));

    const [packageStateFilter, setPackageStateFilter] = useState(selectedState);

    useEffect(() => {
        setPackageStateFilter(selectedState);
    }, [selectedState]);

    const filterButtonTitle = () => {
        const matched = options.find((i) => i.value === selectedState)

        if (selectedState === allFieldName) {
            return {
                text: defaultSelectedLabel,
                className: '',
            };
        }
        return {
            text: getLangText(matched?.label) || matched?.label,
            className: 'filtered',
            defaultSelectedLabel: defaultSelectedLabel,
        };
    };

    const saveFilters = () => {
        batch(() => {
            dispatch(FiltersActions.data.setValue(name, packageStateFilter));
            dispatch(ListActions.loadPage(1, extraProps.id));
        });
    };

    const getCheckedValue = (status) => packageStateFilter === status;

    return (
        <FiltersDropdown
            onClose={() => setPackageStateFilter(selectedState)}
            onSave={saveFilters}
            buttonTittle={filterButtonTitle()}
            onSecondButtonClick={() => setPackageStateFilter(allFieldName)}
            className={className}
            withSubLabel={withSubLabel}
            withClear={withClear}
        >
            <div className="checkbox-filter-wrapper">
                {options.map((status) => (
                    <div
                        key={status.value}
                        className="form-group checkbox-form"
                    >
                        <input
                            className="checkbox-input form-control"
                            type="checkbox"
                            onChange={() => setPackageStateFilter(status.value)}
                            checked={getCheckedValue(status.value)}
                        />
                        <span className="checkbox-checkmark"/>
                        <label className="control-label">{getLangText(status.label) || status.label}</label>
                    </div>
                ))}
            </div>
        </FiltersDropdown>
    );
};

SingleSelectFiltersStatus.defaultProps = {
    className: '',
    defaultSelectedLabel: 'Wszystkie',
    extraProps: {},
    options: [],
    allFieldName: 'all',
    withClear: true
}

export default SingleSelectFiltersStatus;
