import {getStateRoot, prefix} from "./listReducer";
import {RouterPaths} from "../../../routes/RouterPath";
import {services} from "../../../RestServices";
import ListActions, {getGETParamsUrl} from "../../../../src_shared/list/ListActions";
import api from "../../../../services/axios/axios";
import {selmoUrl} from "../../../../src_shared/api/api";
import {batch} from "react-redux";
import SessionActions from "../../shared/session/SessionActions";

export class SalesPostListActions extends ListActions {
    setRefreshTokenLink(link) {
        return {
            type: `${this.prefix}SET_REFRESH_TOKEN_LINK`,
            link,
        };
    }


    loadData(id, signal) {
        return async (dispatch, getState) => {
            const {firstInit} = getState().session;
            const {itemsPerPage, sortBy} = this.getStateRoot(getState());
            dispatch(this.waiting.startWaiting())
            if (firstInit) {
                dispatch(this.onFirstInit());
            }
            const params = this.getLoadParams(getState(), id)

            const restUrl = +id ? `/${id}${getGETParamsUrl(params)}` : getGETParamsUrl(params);
            try {
                const response = await api.get(`${selmoUrl}/${this.restService}${restUrl}`, {signal})
                const {items, total, isFirstStepPage, refresh_token_link} = response.data;
                if (+total <= +itemsPerPage && this.defaultSortBy) {
                    items.sort((a, b) => parseFloat(b[this.defaultSortBy]) - parseFloat(a[this.defaultSortBy]))
                }
                if (!sortBy) {
                    // dispatch(this.table.updateSort(this.defaultSortBy, 'desc'));
                }

                if (refresh_token_link) {
                    dispatch(this.setRefreshTokenLink(refresh_token_link))
                } else {
                    batch(() => {
                        dispatch(this.table.updateAllItems(items))
                        dispatch(this.setFirstStepPage(isFirstStepPage))
                        dispatch(this.pagination.updateTotalItems(total))
                    })
                }

            } catch (e) {
                dispatch(this.table.getListError(e))
                console.error(e)
            } finally {
                if (firstInit) {
                    dispatch(SessionActions.setFirstInit());
                }
                dispatch(this.waiting.stopWaiting())
            }
        }
    }
}

export const getInstance = () => new SalesPostListActions({
    getStateRoot,
    restService: services.API_VIDEO_SOCIAL_VIDEOS,
    pathname: RouterPaths.SalesPost,
    prefix,
});
export default getInstance();
