import React from 'react';
import SelmoPaczkaPage from "../SelmoPaczkaPage";
import useLang from "../../../../src_shared/hooks/useLang";
import {Link} from "react-router-dom";
import {RouterPaths} from "../../../routes/RouterPath";

const options = [
    {id: 1, logo: '/assets/images/svg/dpd-logo.svg', price: '7.82'},
    {id: 2, logo: '/assets/images/svg/orlen-paczka.svg', price: '8.99'},
]
const benefits = [
    {id: 1, title: 'Brak wymagań ilościowych'},
    {id: 2, title: 'Bardzo korzystne stawki'},
    {id: 3, title: 'Sprawdzeni partnerzy logistyczni'},
]

const Landing = () => {
    const {getLangText} = useLang();
    return (
        <SelmoPaczkaPage>
            <div className="bg-[#fff] p-6 mt-3 rounded-[5px] py-12 sm:py-24">
                <div className="grid sm:grid-cols-2 mx-auto max-w-[800px]">
                    <div>
                        <img
                            className="w-[110px] mb-4"
                            src="/assets/images/svg/selmo-paczka-logo.svg" alt=""/>
                        <div className="relative inline-block">
                            <img
                                className="absolute top-8 sm:top-12 -left-4 right-0 sm:-right-4"
                                src="/assets/images/svg/selmo-paczka-circle-path.svg" alt=""/>
                            <span
                                className="whitespace-pre-wrap text-[40px] sm:text-[50px] relative z-[1] leading-[42px] sm:leading-[52px] font-bold"
                                dangerouslySetInnerHTML={{__html: getLangText('eventSevenCheaper')}}/>
                        </div>
                        <Link
                            to={RouterPaths.SelmoPaczkaConfiguration}
                            className="button text-center primary sm:w-auto w-full big-size mt-5"
                            style={{fontSize: 14, borderRadius: 8}}
                        >
                            {getLangText('trySelmoPaczka')}
                        </Link>
                    </div>
                    <div className="sm:mt-0 mt-3.5">
                        <img src="/assets/images/selmo-box-02.webp" alt=""/>
                    </div>
                </div>
                <div className="max-w-[540px] mx-auto mt-12">
                    <div className="text-center sm:-mx-12">
                        <div className="font-bold text-2xl sm:text-[32px] mb-4">Dostępni przewoźnicy</div>
                        <div className="grid grid-cols-2 max-w-[450px] mx-auto">
                            {options.map((option) => (
                                <div className="text-center">
                                    <img
                                        className="h-[40px] max-w-[50px] mx-auto object-contain"
                                        src={option.logo} alt=""/>
                                    <div className="text-[#4B5563] text-sm font-medium mt-2">
                                        Cena za przesyłkę
                                    </div>
                                    <div className="font-bold text-[20px]">{option.price} zł</div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="text-center mt-24">
                        <div className="font-bold text-2xl sm:text-[32px] mb-4">{getLangText('pricingWhyWorthyTitle')}</div>
                        <div className="space-y-2">
                            {benefits.map((benefit) => (
                                <div
                                    key={benefit.id}
                                    className="rounded-[8px] bg-[#F0FAFF] flex items-center p-3"
                                >
                                    <i className="icon-tick-c text-[#5FA5FA] text-2xl mr-2"/>
                                    <div className="text-[#0D3364] font-bold">{benefit.title}</div>
                                </div>
                            ))}
                        </div>
                        <Link
                            to={RouterPaths.SelmoPaczkaConfiguration}
                            className="button primary w-full big-size mt-3"
                            style={{fontSize: 14, borderRadius: 8}}
                        >
                            {getLangText('trySelmoPaczka')}
                        </Link>
                    </div>
                </div>
            </div>
        </SelmoPaczkaPage>
    );
};

export default Landing;
