import Model from "../../../src_shared/modules/model/Model";
import ValidationError from "../../../src_shared/form/validation/ValidationError";
import {getPreparedPointMachineData} from "../../../components/app/basket/secondStep/SecondStep";

class DeliveryTypesModel extends Model {
	getModel() {
		return {
			deliveries: [],
			shipping: 0,
		};
	}

	getValidators() {
		return {
			shipping: ValidationError.notEmpty,
		};
	}

	buildDTO(data) {
		console.log(data)
		const findDelivery = data.deliveries.find((i) => i.id === data.shipping)
		return {
			id: findDelivery.id,
			name: findDelivery.name,
			price: findDelivery.price,
			...getPreparedPointMachineData(data),
		};
	}
}

export default new DeliveryTypesModel();
