import React from 'react';
import {
    updateFieldInCustomersProduct, updateFieldInLiveDetails,
} from "../../LiveDetailsActions.js";
import {batch, useDispatch, useSelector} from "react-redux";
import NumberInput from "../../../../../shared/components/form/NumberInput.jsx";
import ProductDropdown from "./ProductDropdown.jsx";
import AssignModalActions from "../assignModal/AssignModalActions.js";
import ProductSearchInput from "../../../../shared/productSearch/ProductSearchInput.jsx";
import VariantsSelect from "../../../../shared/productSearch/VariantsSelect.jsx";
import {isOutOfStock} from "../../../../../messenger/addOrderModal/components/hintDropdown.jsx";
import {components} from "react-select";
import optimizedMemo from "../../../../../../../src_shared/helpers/optimizedMemo.js";
import useLang from "../../../../../../../src_shared/hooks/useLang";
import {useUnitPrice} from "../../../../../shared/helpers/Price";

const ProductRow = ({item, clientId, isOrderCreated, products = [], shop_client_id}) => {

    const dispatch = useDispatch();
    const {hasProductInCustomerError} = useSelector((state) => state.lives.details);
    const {getLangText} = useLang();
    const {getPrice} = useUnitPrice();

    const quantityValidation = (value) => !value || value <= 0;
    const isEmptyValidation = (value) => !value;
    const isDisabled = isOrderCreated || +item.is_cancelled;

    const updateField = (field, value) => dispatch(updateFieldInCustomersProduct(field, value, shop_client_id, item.id));

    const onProductPriceChange = (e) => {
        batch(() => {
            updateField('price', e.target.value);
            updateField('hasError', false);
        });
    }

    const showAssignModal = () => {
        batch(() => {
            dispatch(AssignModalActions.modal.showModal());
            dispatch(AssignModalActions.data.updateValues({
                clientId: clientId,
                productId: item.id,
            }))
        })
    }

    const getVariantsCount = () => {
        const selectedProductsWithTheSameSize = products.filter((i) => (!+i.is_deleted && !+i.is_cancelled) && (i.product_id && i.variant_id === item.variant_id));
        const selectedProductsWithNoVariants = products.filter((i) => (!+i.is_deleted && !+i.is_cancelled) && (i.product_id && !i.sizes?.length));

        const preparedArray = !!item.sizes?.length ? selectedProductsWithTheSameSize.slice(0, selectedProductsWithTheSameSize.indexOf(item) + 1) : selectedProductsWithNoVariants.slice(0, selectedProductsWithNoVariants.indexOf(item) + 1)

        return preparedArray.reduce((prev, curr) => {
            return +prev + +curr.quantity
        }, 0);
    }

    const getNotAvailabilityClass = () => {
        const selectedVariant = !!item.sizes?.length ? item.sizes.find((i) => i.id === item.variant_id) : item;

        if (isOrderCreated) {
            return ''
        }

        if (!selectedVariant && !item.variant_id) {
            return '';
        }

        if (+selectedVariant?.squ < +getVariantsCount()) {
            return 'not-available';
        }

        return ''
    }

    const productRowClass = () => {
        const isDeleted = +item.is_deleted ? 'd-none' : '';
        const isCancelled = +item.is_cancelled ? 'cancelled' : '';
        const withVariant = item.product_id ? 'with-variant' : '';
        return `inputs-row form-row small-inputs with-variant ${isDeleted} ${isCancelled} ${withVariant} ${getNotAvailabilityClass()}`
    }

    const onHintSelect = (hintItem) => {
        if (isOutOfStock(hintItem)) {
            return;
        }
        updateField('product_id', hintItem.id)
        updateField('name', hintItem.name)
        updateField('code', hintItem.selmo_code)
        updateField('price', hintItem.price)
        updateField('photo', hintItem.photo)
        updateField('sizes', hintItem.sizes)
        updateField('squ', hintItem.squ)
        dispatch(updateFieldInLiveDetails('storageProducts', []));
    }

    const removeProduct = () => {
        updateField('product_id', null)
        updateField('name', '')
        updateField('code', '')
        updateField('price', '')
        updateField('photo', null)
        updateField('sizes', [])
        updateField('squ', 1)
        updateField('variant_id', null)
    }

    const ValueContainer = ({children, ...props}) => {
        const {getValue, hasValue} = props;
        if (!hasValue) {
            return (
                <components.ValueContainer {...props}>
                    <span className="select-variant-label">{getLangText('selectVariantLabel')}</span>
                    <span className="hidden-value-container">
					{children}
				</span>
                </components.ValueContainer>
            );
        }
        return (
            <components.ValueContainer className="custom-value-container" {...props}>
                <div className="floated-small-label">{getLangText('variantLabel')}</div>
                <div className="custom-value">{getValue()[0].size} • {getPrice(getValue()[0].price)}</div>
                <span className="hidden-value-container">
					{children}
				</span>
            </components.ValueContainer>
        );
    }

    const onNameChange = (e) => updateField('name', e.target.value);

    return (
        <div className={productRowClass()}>
            <ProductSearchInput
                product={item}
                className={`form-group ${item.product_id ? 'storage-product' : ''} ${isEmptyValidation(item.name) && hasProductInCustomerError ? 'error-group' : ''}`}
                onHintSelect={onHintSelect}
                onNameChange={onNameChange}
                removeProduct={removeProduct}
                inputValue={item.name}
                disabled={isDisabled || !!item.product_id}
                isOrderCreated={isOrderCreated}
                tabIndex={item.tabIndex}
                label={getLangText('productLabel')}
                isSelectedProduct={item.product_id}
                autoFocus={false}
            />
            {item.product_id ?
                <VariantsSelect
                    product={item}
                    validateErrors={hasProductInCustomerError}
                    variantId={item.variant_id || null}
                    updateField={updateField}
                    ValueContainer={ValueContainer}
                    label={'priceLabel'}
                    disabled={isOrderCreated}
                />
                :
                <div>
                    <div
                        className={`form-group ${isEmptyValidation(item.price) && hasProductInCustomerError ? 'error-group' : ''}`}>
                        <label className="control-label">{getLangText('priceLabel')}</label>
                        <NumberInput
                            onChange={onProductPriceChange}
                            value={item.price}
                            min={0}
                            name="price"
                            disabled={isDisabled}
                            tabIndex={item.tabIndex}
                        />
                    </div>
                </div>
            }
            <div className="quantity-col">
                <div
                    className={`form-group ${quantityValidation(item.quantity) && hasProductInCustomerError ? 'error-group' : ''}`}>
                    <label className="control-label">{getLangText('quantityLabel')}</label>
                    <NumberInput
                        onChange={(e) => updateField('quantity', e.target.value)}
                        value={item.quantity}
                        min={1}
                        name="quantity"
                        disabled={isDisabled}
                        tabIndex={item.tabIndex}
                    />
                </div>
            </div>
            {!isOrderCreated &&
                <div className="button-col pencil-col">
                    <ProductDropdown
                        icon="icon-edit"
                    >
                        <button
                            className="custom-option"
                            onClick={() => updateField('is_deleted', '1')}
                        >
                            {getLangText('removeButton')}
                        </button>
                        {item.shop_id &&
                            <button
                                className="custom-option"
                                onClick={showAssignModal}
                            >
                                {getLangText('assignButton')}
                            </button>
                        }
                        {+item.is_cancelled ?
                            <button
                                className="custom-option"
                                onClick={() => updateField('is_cancelled', '0')}
                            >
                                {getLangText('revertButton')}
                            </button> :
                            <button
                                className="custom-option"
                                onClick={() => updateField('is_cancelled', '1')}
                            >
                                {getLangText('cancelButton')}
                            </button>
                        }
                    </ProductDropdown>
                </div>
            }
        </div>
    );
};

export default optimizedMemo(ProductRow);
