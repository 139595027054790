import React, {useEffect, useMemo, useRef, useState} from 'react';
import DeliveryMethods from "../deliveryMethods/DeliveryMethods";
import OrderInfo from "../deliveryMethods/components/OrderInfo";
import useForm from "../../../../src_shared/form/useForm";
import FormActions from "../deliveryMethods/FormActions";
import PaymentMethods from "../paymentMethods/PaymentMethods";
import AgreementCheckbox from "../components/AgreementCheckbox";
import {services} from "../../../RestServices";
import Loader from "../../shared/Loader";
import {useDispatch, useSelector} from "react-redux";
import {createSelector} from "reselect";
import {getStateRoot} from "../deliveryMethods/formReducer";
import {getStateRoot as getPaymentMethodsStateRoot} from "../paymentMethods/formReducer";
import {showAlert} from "../../shared/components/alert/AlertActions";
import SwitchBoxes from "../../../../src_shared/components/form/switchBoxes/SwitchBoxes";
import useLang from "../../../../src_shared/hooks/useLang";
import CustomerBasketPackageType, {
    CUSTOMER_BASKET_OPEN_CART_STATUS
} from "../../shared/enums/CustomerBasketPackageType";
import InpostShippingModal from "../../orders/details/deliveryType/inpostShipping/Form";
import {
    KOKARDECZKA_SHOP_ID,
    SPLENDOR_ROOM_SHOP_ID,
    TEST_ACCOUNT_SHOP_ID
} from "../../../../src_shared/enums/TrustedShopsIds";
import MondialRelayPointModal from "../../shared/components/MondialRelaisPointModal/MondialRelaisPointModal";
import OrlenPointModal from "../../shared/components/OrlenPointModal/OrlenPointModal";
import AutomaticTransfersModal from "../../shared/components/paymentMethods/components/AutomaticTransfersModal";
import DpdPointModal from "../../shared/components/DpdPointModal/DpdPointModal";
import GlsPointModal from "../../shared/components/GlsPointModal/GlsPointModal";

export const MONDIAL_RELAY_POINT_MODAL = 'MONDIAL_RELAY_POINT_MODAL'
export const ORLEN_POINT_MODAL = 'ORLEN_POINT_MODAL'
export const INPOST_POINT_MODAL = 'INPOST_POINT_MODAL'
export const DPD_POINT_MODAL = 'DPD_POINT_MODAL'
export const GLS_POINT_MODAL = 'GLS_POINT_MODAL'

export const getPointMachineType = (data) => {
    if (data.inpost_box_code) {
        return INPOST_POINT_MODAL;
    }
    if (data.mondial_box_code) {
        return MONDIAL_RELAY_POINT_MODAL;
    }
    if (data.orlen_paczka_box_psd_code) {
        return ORLEN_POINT_MODAL;
    }
    if (data.dpd_box_code) {
        return DPD_POINT_MODAL;
    }
    if (data.box_code) {
        return GLS_POINT_MODAL;
    }
}

export const getPreparedPointMachineData = (data) => {
    if (data.pointMachineType === INPOST_POINT_MODAL) {
        return {
            inpost_box_code: data.pointMachineCode,
            inpost_box_street: data.pointMachineStreet,
            inpost_box_city: data.pointMachineCity,
        }
    }
    if (data.pointMachineType === ORLEN_POINT_MODAL) {
        return {
            orlen_paczka_box_code: data.pointMachineId,
            orlen_paczka_box_city: data.pointMachineCity,
            orlen_paczka_box_street: data.pointMachineStreet,
            orlen_paczka_box_psd_code: data.pointMachineCode,
        }
    }
    if (data.pointMachineType === MONDIAL_RELAY_POINT_MODAL) {
        return {
            mondial_box_name: data.pointMachineName,
            mondial_box_street: data.pointMachineStreet,
            mondial_box_code: data.pointMachineCode,
            mondial_box_city: data.pointMachineCity,
            mondial_zip_code_box: data.pointMachineCityCodeBox,
        }
    }
    if (data.pointMachineType === DPD_POINT_MODAL) {
        return {
            dpd_box_code: data.pointMachineCode,
            dpd_box_name: data.pointMachineName,
            dpd_box_street: data.pointMachineStreet,
            dpd_box_city: data.pointMachineCity,
        }
    }

    if (data.pointMachineType === GLS_POINT_MODAL) {
        return {
            box_code: data.pointMachineCode,
            box_name: data.pointMachineName,
            box_street: data.pointMachineStreet,
            box_city: data.pointMachineCity,
            box_type: data.pointMachineType,
            box_zipcode: data.pointMachineZipCode,
        }
    }
}

const SecondStep = ({magicUrl, shopId, shopName, data}) => {

    const dispatch = useDispatch();
    const {getLangText} = useLang();
    const openCartSectionRef = useRef();
    const [openCartValueError, setOpenCartValueError] = useState(false);
    const [isPointModalOpen, setIsPointModalOpen] = useState(null);
    const [activeShippingId, setActiveShippingId] = useState(null);

    const setValueOnServer = (rest, field, value) => dispatch(FormActions.setValueOnServer(rest, field, value, magicUrl, shopId))
    const hideAutomaticTransferModal = () => dispatch(FormActions.setShowAutomaticTransfersModal(false))

    const {
        paymentsMethodsVisible,
        waitingForSubmit,
        waitingForValueOnServer,
        showAutomaticTransfersModal,
    } = useSelector(createSelector(getStateRoot, (stateRoot) => stateRoot));

    const {payment} = useSelector(createSelector(getPaymentMethodsStateRoot, (stateRoot) => stateRoot.data));

    const {
        data: {values, updateValues},
        form: {onSave, getFieldProps},
        validation,
    } = useForm({
        FormActions,
        params: {},
        requireParentId: false,
        preventAutoLoad: true,
        beforeDestroy: () => {
        }
    });

    const preparedPackedTypeOptions = useMemo(() => CustomerBasketPackageType.map((i) => ({
        ...i,
        label: i.id === CUSTOMER_BASKET_OPEN_CART_STATUS ? 'customerBasketOpenCartLabel' : 'customerBasketClosedCartLabel',
    })), [CustomerBasketPackageType, data.shopInfo.id])

    const packageTypesOptions = useMemo(() => values.packagesTypes.map((i) => ({
        ...i,
        label: i.name,
    })), [values.packagesTypes])

    const updateValuesInInpostModalOnServer = async (obj) => {
        console.log(obj)
        await setValueOnServer(services.API_CART_SHIPPING_METHODS, 'shipping', {
            ...getPreparedPointMachineData(obj),
            shipping: obj.shipping,
        })
        updateValues(obj);
    }

    const setOpenCartValue = async (field, value) => {
        await setValueOnServer(services.API_CART_OPEN_CART, field, value)
        dispatch(FormActions.setPaymentsMethodsVisible(true))
        setOpenCartValueError(false);
    }

    const handleMethodPayment = () => {
        if (!+data.shopInfo?.open_cart_enabled) {
            return;
        }
        if (!values.open_cart_status) {
            dispatch(showAlert(getLangText('customerBasketPackedStatusErrorLabel'), '', 'alert-fail'))
            window.scrollTo({behavior: 'smooth', top: openCartSectionRef.current?.offsetTop - 20});
            setOpenCartValueError(true)
        }
    }

    const isValidatedData = () => {
        const openCartStatusValidator = !!values.openCartEnabled ? !!values.open_cart_status : true;
        const packageTypeValidator = !!values.packagesTypes?.length ? !!+values.package_type_id : true
        const checkOrderStageValidator = values.orderStage <= 1 ? (!!+values.shipping && !!payment) : true;
        if (data.shipping_cart) {
            return openCartStatusValidator && packageTypeValidator && checkOrderStageValidator && !!+values.agreement;
        } else {
            return !!payment && !!+values.agreement
        }
    }

    const saveWithScrollToError = () => {
        const errorSwitchBoxes = document.querySelector('.data-boxes-wrapper.error-group')
        if (validation.isActive && errorSwitchBoxes) {
            //TODO
            // window.scrollTo({behavior: 'smooth', top: errorSwitchBoxes.offsetTop - 50});
        }
        onSave()
    }

    useEffect(() => {
        if (+shopId === KOKARDECZKA_SHOP_ID || +shopId === TEST_ACCOUNT_SHOP_ID) {
            dispatch(FormActions.getDeliveryOptions(services.API_CART_PACKAGES_TYPES, 'packagesTypes', magicUrl, shopId))
        }
    }, [])

    useEffect(() => {
        updateValues({
            magic_url: magicUrl,
            shop_id: shopId,
            shopName,
        })
    }, []);

    return (
        <div className="delivery-step-wrapper">
            <OrderInfo
                setIsPointModalOpen={setIsPointModalOpen}
                setActiveShippingId={setActiveShippingId}
                data={data}
                shopId={shopId}
            />
            {data.shipping_cart &&
                <DeliveryMethods
                    magicUrl={magicUrl}
                    shopId={shopId}
                    data={data}
                    setIsPointModalOpen={setIsPointModalOpen}
                    setActiveShippingId={setActiveShippingId}
                />
            }
            {(data.shipping_cart && ((+shopId === SPLENDOR_ROOM_SHOP_ID && +data.overall.products_value >= 200) || +shopId !== SPLENDOR_ROOM_SHOP_ID)) &&
                <>
                    {!!+data.shopInfo?.open_cart_enabled &&
                        <section
                            ref={openCartSectionRef}
                            className={openCartValueError ? 'error-section' : ''}
                        >
                            <div className="section-title">
                                {getLangText('customerBasketPackedStatusLabel')}
                            </div>
                            <div
                                className="section-title__description">{getLangText('customerBasketPackedStatusDescription')}</div>
                            {(openCartValueError && !validation.isActive) &&
                                <div className="form-info-box alert-style red w-100 mb-2">
                                    <div>
                                        <i className="icon-info-c"/>
                                    </div>
                                    <div className="title">
                                        {getLangText('customerBasketPackedStatusErrorLabel')}
                                    </div>
                                </div>
                            }
                            <SwitchBoxes
                                {...getFieldProps('open_cart_status')}
                                options={preparedPackedTypeOptions}
                                setValue={setOpenCartValue}
                                disabled={waitingForValueOnServer}
                                className="separated"
                                ErrorComponent={() => (
                                    <div className="form-info-box alert-style red w-100 mb-2">
                                        <div>
                                            <i className="icon-info-c"/>
                                        </div>
                                        <div className="title">
                                            {getLangText('customerBasketPackedStatusErrorLabel')}
                                        </div>
                                    </div>
                                )}
                            />
                        </section>
                    }
                </>
            }
            {(!!packageTypesOptions.length && data.shipping_cart) &&
                <section>
                    <div className="section-title">
                        {getLangText('customerBasketPackedTypeLabel')}
                    </div>
                    <SwitchBoxes
                        {...getFieldProps('package_type_id')}
                        options={packageTypesOptions}
                        setValue={(field, value) => setValueOnServer(services.API_CART_PACKAGES_TYPES, field, value)}
                        className="separated"
                        ErrorComponent={() => (
                            <div className="form-info-box alert-style red w-100 mb-2">
                                <div>
                                    <i className="icon-info-c"/>
                                </div>
                                <div className="title">
                                    {getLangText('customerBasketPackedTypeLabel')}
                                </div>
                            </div>
                        )}
                    />
                </section>
            }
            {+data.shopInfo?.order_stage <= 1 &&
                <section
                    className={`payment-methods-section ${(!+data.shopInfo?.open_cart_enabled || paymentsMethodsVisible || !data.shipping_cart) ? 'open' : ''}`}>
                    <button
                        type="button"
                        className={`toggle-section-header ${paymentsMethodsVisible ? 'active' : ''}`}
                        onClick={() => handleMethodPayment(!paymentsMethodsVisible)}
                    >
                        <div className="section-title">
                            {getLangText('customerBasketPaymentTypeLabel')}
                        </div>
                    </button>
                    {(!+data.shopInfo?.open_cart_enabled || paymentsMethodsVisible || !data.shipping_cart) &&
                        <PaymentMethods
                            isActiveValidation={validation.isActive}
                            data={data}
                        />
                    }
                </section>
            }
            <AgreementCheckbox
                getFieldProps={getFieldProps}
                setValue={(field, value) => setValueOnServer(services.API_CART_SHIPPING_ADDRESS, field, value)}
            />
            <section>
                <div className="buttons-row payment-buttons-row justify-content-end mt-4">
                    <div className="safety-payment-buttons">
                        <button
                            onClick={saveWithScrollToError}
                            type="button"
                            className={`button primary btn-proceed ${waitingForSubmit ? 'loading-button' : ''} ${!isValidatedData() ? 'not-active' : ''}`}
                        >
                            {waitingForSubmit && <Loader isLoading/>}
                            {getLangText('customerBasketPaymentButton')}
                        </button>
                        <div className="safety-payment">
                            <i className="icon-safe"/>
                            {getLangText('customerBasketSafetyPaymentButton')}
                        </div>
                    </div>
                </div>
            </section>
            {isPointModalOpen === INPOST_POINT_MODAL &&
                <InpostShippingModal
                    data={{
                        shippingData: {
                            zip_code: data.shippingAddress.zip_code,
                        }
                    }}
                    updateValues={updateValuesInInpostModalOnServer}
                    formValues={values}
                    show={isPointModalOpen === INPOST_POINT_MODAL}
                    hideModal={() => setIsPointModalOpen(null)}
                    shippingId={activeShippingId}
                />
            }
            {isPointModalOpen === MONDIAL_RELAY_POINT_MODAL &&
                <MondialRelayPointModal
                    params={{
                        city: data.shippingAddress.city,
                        country: data.shippingAddress.country_id,
                        zip_code: data.shippingAddress.zip_code,
                        shop_id: shopId,
                    }}
                    updateValues={updateValuesInInpostModalOnServer}
                    show={isPointModalOpen === MONDIAL_RELAY_POINT_MODAL}
                    hideModal={() => setIsPointModalOpen(null)}
                    shippingId={activeShippingId}
                />
            }
            {isPointModalOpen === ORLEN_POINT_MODAL &&
                <OrlenPointModal
                    updateValues={updateValuesInInpostModalOnServer}
                    show={isPointModalOpen === ORLEN_POINT_MODAL}
                    hideModal={() => setIsPointModalOpen(null)}
                    shippingId={activeShippingId}
                />
            }
            {isPointModalOpen === DPD_POINT_MODAL &&
                <DpdPointModal
                    updateValues={updateValuesInInpostModalOnServer}
                    show={isPointModalOpen === DPD_POINT_MODAL}
                    hideModal={() => setIsPointModalOpen(null)}
                    shippingId={activeShippingId}
                    params={{
                        city: data.shippingAddress.city,
                        zip_code: data.shippingAddress.zip_code,
                        street: data.shippingAddress.street,
                    }}
                />
            }
            {isPointModalOpen === GLS_POINT_MODAL &&
                <GlsPointModal
                    updateValues={updateValuesInInpostModalOnServer}
                    show={isPointModalOpen === GLS_POINT_MODAL}
                    hideModal={() => setIsPointModalOpen(null)}
                    shippingId={activeShippingId}
                    params={{
                        city: data.shippingAddress.city,
                        zip_code: data.shippingAddress.zip_code,
                        street: data.shippingAddress.street,
                    }}
                />
            }
            {showAutomaticTransfersModal &&
                <AutomaticTransfersModal
                    shopId={shopId}
                    modalVisible={showAutomaticTransfersModal}
                    hideModal={hideAutomaticTransferModal}
                />
            }
        </div>
    );
};

export default SecondStep;
