import React, {memo} from 'react';
import ServerImage from "../../shared/components/serverImage/ServerImage";

const ClientAvatar = ({photo, type, fbLogo = true, className = ''}) => {

	return (
		<figure className={`user-avatar ${className}`}>
			{photo && photo !== '0' ?
				<div className="fb-pic w-100 h-100">
					<ServerImage src={photo}/>
				</div> :
				<img src="/assets/images/default/fb-default.jpeg"/>
			}
			{fbLogo &&
				<>
					{type === 'facebook' && <img className="msg-logo" src="/assets/images/svg/messenger-icon.svg"/>}
					{type === 'instagram' && <img className="msg-logo" src="/assets/images/svg/instagram-icon.svg"/>}
				</>
			}
		</figure>
	);
};

export default memo(ClientAvatar);
