import React from 'react';
import CheckboxField from "../../../../src_shared/components/form/checkboxField/CheckboxField";
import RegulationModal from "../deliveryAddress/components/RegulationModal";
import {services} from "../../../RestServices";
import {useModal} from "../../shared/hooks/useModal";
import useLang from "../../../../src_shared/hooks/useLang";
import {useParams} from "react-router-dom";
import {useSelector} from "react-redux";
import {createSelector} from "reselect";
import {getStateRoot as getBasketPageStateRoot} from "../page/formReducer";

const AgreementCheckbox = ({getFieldProps, setValue, className}) => {
		const {getLangText} = useLang();
		const {shopName} = useParams();
		const shopId = shopName?.split('-')[shopName.split('-').length - 1];
		const {shopInfo} = useSelector(createSelector(getBasketPageStateRoot, (stateRoot) => stateRoot.data));

		const [openRegulationsModal, setOpenRegulationsModal] = useModal(false);
		const [openShopRegulationsModal, setOpenShopRegulationsModal] = useModal(false);
		const [openPolicyModal, setOpenPolicyModal] = useModal(false);

		const regulationModalButton = () => {
			return (
				shopInfo.has_terms ?
					<button
						style={{position: "relative", zIndex: '9', textDecoration: 'underline'}}
						type="button"
						onClick={() => setOpenShopRegulationsModal(true)}
					>
						{getLangText('customerBasketAgreementLabel2')}
					</button> :
					<button
						style={{position: "relative", zIndex: '9', textDecoration: 'underline'}}
						type="button"
						onClick={() => setOpenRegulationsModal(true)}
					>
						{getLangText('customerBasketAgreementLabel2')}
					</button>
			)
		}

		const policyModalButton = () => (
			<button
				style={{position: "relative", zIndex: '9', textDecoration: 'underline'}}
				type="button"
				onClick={() => setOpenPolicyModal(true)}
			>
				{getLangText('customerBasketAgreementLabel4')}
			</button>
		)


		const checkboxLabel = () => {
			if (shopInfo.has_policy) {
				return (
					<>
						{getLangText('customerBasketAgreementLabel1')}
						&nbsp;
						{regulationModalButton()}
						&nbsp;
						{getLangText('customerBasketAgreementLabel3')}
						&nbsp;
						{policyModalButton()}
					</>
				)
			}
			return (
				<>
					{getLangText('customerBasketAgreementLabel1')}
					&nbsp;
					{regulationModalButton()}
				</>
			)
		}

		return (
			<>
				<CheckboxField
					{...getFieldProps('agreement')}
					LabelComponent={checkboxLabel()}
					setValue={setValue}
					className={className}
				/>
				{openRegulationsModal &&
					<RegulationModal
						modalVisible={openRegulationsModal}
						hideModal={() => setOpenRegulationsModal(false)}
						rest={services.API_CART_TERMS}
						shopId={shopId}
						title={getLangText('customerBasketRegulationsModalTitle')}
					/>
				}
				{openShopRegulationsModal &&
					<RegulationModal
						modalVisible={openShopRegulationsModal}
						hideModal={() => setOpenShopRegulationsModal(false)}
						rest={services.API_CART_SHOP_TERMS}
						shopId={shopId}
						title={`${getLangText('customerBasketShopRegulationsModalTitle')}${shopInfo?.name}`}
					/>
				}
				{openPolicyModal &&
					<RegulationModal
						modalVisible={openPolicyModal}
						hideModal={() => setOpenPolicyModal(false)}
						rest={services.API_CART_SHOP_POLICY}
						shopId={shopId}
						title={`${getLangText('customerBasketShopPolicyModalTitle')}${shopInfo?.name}`}
					/>
				}
			</>
		);
	}
;

export default AgreementCheckbox;
