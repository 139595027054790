import FormActions from "../../../../../../src_shared/form/FormActions";
import {getStateRoot, prefix} from "./formReducer";
import {getStateRoot as getOrderListStateRoot} from "../../listReducer";
import {selmoUrl} from "../../../../../../src_shared/api/api";
import OrdersListActions from "../../ListActions";
import {getGETParamsUrl} from "../../../../../../src_shared/list/ListActions";
import {batch} from "react-redux";

export class ExportFormActions extends FormActions {
	showExportProductModal(toggle) {
		return {
			type: `${this.prefix}SHOW_EXPORT_PRODUCT_MODAL`,
			toggle,
		}
	}


	getParams() {
		return (dispatch, getState) => {
			const params = OrdersListActions.getLoadParams(getState())
			const {selectedItems} = getOrderListStateRoot(getState())
			const {data} = getStateRoot(getState())

			if (data.displayType === 2) {
				params.client_per_page = '1';
			}

			if (!!selectedItems?.length) {
				params['selectedItems[]'] = selectedItems?.map((i) => i.id)
			}

			return getGETParamsUrl(params);
		}
	}

	onSubmit(rest) {
		return async (dispatch, getState) => {
			dispatch(this.waiting.startWaiting());
			const params = OrdersListActions.getLoadParams(getState())
			const {selectedItems} = getOrderListStateRoot(getState())

			try {
				const {data} = getStateRoot(getState())

				if (data.displayType === 2) {
					params.client_per_page = '1';
				}

				if (!!selectedItems?.length) {
					params['selectedItems[]'] = selectedItems?.map((i) => i.id)
				}

				const link = document.createElement('a');
				link.href = `${selmoUrl}/${rest}${getGETParamsUrl(params)}`
				link.dispatchEvent(new MouseEvent('click'));
				batch(() => {
					dispatch(this.modal.hideModal());
					dispatch(this.showExportProductModal(false))
					dispatch(this.data.setValue('productsExportVisible', false));
				})

			} catch (e) {
				console.error('Fail get file pdf /api/export')
			} finally {
				dispatch(this.waiting.stopWaiting());
			}
		}
	}
}

export const getInstance = () => new ExportFormActions({
	getStateRoot,
	prefix,
});

export default getInstance();

