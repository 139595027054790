import React, {useEffect} from 'react';
import useLang from "../../../../../../../src_shared/hooks/useLang";
import useFormLocal from "../../../../../../../src_shared/hooks/useFormLocal";
import {services} from "../../../../../../RestServices";
import Modal from "react-bootstrap/Modal";
import SelmoPaczkaPointActivationModal
    from "../../../../../../../modules/models/selmoPaczka/SelmoPaczkaPointActivationModal";
import SelmoPaczkaShippingToggle from "./components/SelmoPaczkaShippingToggle";
import Loader from "../../../../../../../src_shared/components/loader/Loader";
import {useDispatch} from "react-redux";
import {showAlert} from "../../../../../shared/components/alert/AlertActions";

const fields = [
    {
        id: 0,
        label: 'DPD Pickup',
        activeName: 'dpd_active',
        priceName: 'dpd_price',
        logo: '/assets/images/svg/dpd-pick-up-logo.svg',
    },
    {
        id: 1,
        label: 'Orlen Paczka',
        activeName: 'op_active',
        priceName: 'op_price',
        logo: '/assets/images/svg/orlen-paczka.svg',
    },
]

const SelmoPaczkaCreateShipping = ({showModal, hide, fetchData}) => {
    const {getLangText} = useLang();
    const dispatch = useDispatch();
    const afterSubmit = () => {
        hide();
        dispatch(showAlert('yuhuLabel', 'successfullyAddedAdditionalPickupPoints'))
    }

    const [{getFieldProps, onSave, isWaiting, values}] = useFormLocal({
        model: SelmoPaczkaPointActivationModal,
        rest: services.API_PACKAGE_POINT_ACTIVATION,
        initialAutoLoad: false,
        afterSubmit,
    });

    useEffect(() => {
        return fetchData && fetchData;
    }, [])

    return (
        <Modal
            dialogClassName={`default-modal full-height-mobile small-modal w-420`}
            show={showModal}
            onHide={hide}
        >
            <form
                className="sm:h-auto h-full sm:block flex flex-col"
                onSubmit={onSave}
            >
                <Modal.Header className="align-items-flex-start">
                    <Modal.Title>
                        Dodaj dodatkowe punkty odbioru
                        <div className="sub-title">
                            Wybierz dodatkowe punkty odbioru dla swoich klientów
                        </div>
                    </Modal.Title>
                    <button
                        onClick={hide}
                        type="button"
                        className="button"
                    >
                        <i className="icon-cross"/>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <div className="space-y-3">
                        {fields.map((field) => (
                            <SelmoPaczkaShippingToggle
                                key={field.id}
                                item={field}
                                getFieldProps={getFieldProps}
                                values={values}
                            />
                        ))}
                    </div>
                </Modal.Body>
                <Modal.Footer className="mt-sm-0 mt-auto">
                    <button
                        onClick={hide}
                        type="button"
                        className="button border-button"
                    >
                        {getLangText('cancelButton')}
                    </button>
                    <button
                        type="submit"
                        className={`button primary ${isWaiting ? 'loading-button' : ''}`}
                    >
                        <Loader isLoading={isWaiting}/>
                        Dodaj
                    </button>
                </Modal.Footer>
            </form>
        </Modal>
    );
};

export default SelmoPaczkaCreateShipping;
