import React, {useState} from 'react';
import moment from "../../../../../messenger/helpers/moment.js";
import EmptyList from "../../../../../shared/EmptyList.jsx";
import {Link, useHistory} from "react-router-dom";
import {RouterPaths} from "../../../../../../routes/RouterPath.js";
import CustomDropdown from "../../../../../shared/components/dropdown/CustomDropdown";
import {useUnitPrice} from "../../../../../shared/helpers/Price";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import TimeAgo from "../../../../../../../src_shared/helpers/TimeAgo";
import {useModal} from "../../../../../shared/hooks/useModal";
import axios from "axios";
import {selmoUrl} from "../../../../../../../src_shared/api/api";
import {services} from "../../../../../../RestServices";
import {batch, useDispatch, useSelector} from "react-redux";
import CountDownNotificationActions from "../../../../../shared/components/notification/CountDownNotificationActions";
import {GO_TO_ORDER_NOTIFICATION} from "../../../../../shared/enums/CountDownNotificationsTypes";
import {showAlert} from "../../../../../shared/components/alert/AlertActions";
import ConfirmModal from "../../../../../live/details/default/summary/export/ConfirmModal";
import SendSummaryMessageModal from "../../../../shared/sendSummaryMessageModal/SendSummaryMessageModal";
import useLang from "../../../../../../../src_shared/hooks/useLang";
import ListActions from "../../../productsList/ListActions";
import ProductsReserveListActions from "../../ListActions";
import {getStateRoot} from "../../../productsList/listReducer";
import SelectedRow from "../../../components/selectedRow/SelectedRow";
import {createSelector} from "reselect";
import useCheckboxMultiselect from "../../../../../../../src_shared/list/checkboxMultiselect/useCheckboxMultiselect";
import CheckboxField from "../../../../../../../src_shared/components/form/checkboxField/CheckboxField";
import ClientAvatar from "../../../../../live/shared/Avatar";
import api from "../../../../../../../services/axios/axios";
import ServerImage from "../../../../../shared/components/serverImage/ServerImage";
import {
    PIANO_FASHION_SHOP_ID,
    TEST_ACCOUNT_SHOP_ID,
    TIM_SHOP_ID
} from "../../../../../../../src_shared/enums/TrustedShopsIds";
import {convertToUserTimeZone} from "../../../../../shared/helpers/dateHelpers";

const ProductsTable = ({items, isWaiting, updateSortOptions, getSortClass, loadData, hiddenActions = false}) => {

    const history = useHistory();
    const dispatch = useDispatch();
    const {userData} = useSelector((state) => state.session);

    const goToEdit = (id) => history.push(`${RouterPaths.ProductsList}/edytuj/${id}/`);
    const {getPrice} = useUnitPrice()
    const [createOrderModalOpen, setCreateOrderModalOpen] = useModal(false)
    const [selectedClient, setSelectedClient] = useState(null)
    const [waiting, setWaiting] = useState(false);
    const {getLangText} = useLang();
    const {lang} = useSelector((state) => state.i18n);

    const getParsedPrice = (price) => {
        const preparedPrice = parseFloat(price.toString().replace(',', '.'))
        return getPrice(preparedPrice)
    }

    const {selectedItems} = useSelector(createSelector(getStateRoot, (stateRoot) => stateRoot));

    const {
        selectAllItems,
        toggleSelection,
        checkIfSomeExist,
        isSelected,
        updateSelection,
    } = useCheckboxMultiselect(ListActions.selectedItems, selectedItems, items);

    const colSpan = !!selectedItems.length ? 7 : 1

    const [sendSummaryMessageModalOpen, setSendSummaryMessageModalOpen] = useModal(false);
    const [defaultTemplateMessage, setDefaultTemplateMessage] = useState('')

    const afterSubmit = (shop_order_id, sentMessage = false) => {
        batch(() => {
            dispatch(CountDownNotificationActions.setNotificationVisibility(true, GO_TO_ORDER_NOTIFICATION, {shop_order_id: shop_order_id}))
            dispatch(showAlert('yuhuLabel', 'successfulCreatedLabel'))
        })
        if (sentMessage) {
            dispatch(showAlert('yuhuLabel', 'successfulOrderCreatedAndSentSummaryLabel'))
        } else {
            dispatch(showAlert('yuhuLabel', 'successfulCreatedLabel'))
        }
    }

    const createOrder = async () => {
        setWaiting(true)
        try {
            const response = await api.post(`${selmoUrl}/${services.API_PRODUCT_ORDER_FROM_RESERVE_LIST}/${selectedClient.id}`)
            loadData();
            setCreateOrderModalOpen(false)
            if (response.data.summary_message) {
                setDefaultTemplateMessage({
                    ...defaultTemplateMessage,
                    message: response.data.summary_message,
                    order_id: response.data.message,
                    shop_order_id: response.data.shop_order_id,
                });
                setSendSummaryMessageModalOpen(true);
                return;
            }
            afterSubmit(response.data.shop_order_id);
        } catch (e) {
            if (e.response.data.message !== 'Something went wrong') {
                dispatch(showAlert(e.response.data.message, '', 'alert-fail'))
                return;
            }
            dispatch(showAlert('sthWentWrongWarning', '', 'alert-fail'))
        } finally {
            setWaiting(false)
        }
    }

    const onCreateOrderButtonClick = (user) => {
        setSelectedClient(user)
        setDefaultTemplateMessage({
            ...defaultTemplateMessage,
            recipient: user.user_id,
            instagramMessage: user.social_type === 'instagram'
        })
        setCreateOrderModalOpen(true)
    }

    return (
        <EmptyList
            items={items}
            isLoading={isWaiting}
            className="with-padd"
            imgSrc="/assets/images/empty/products-empty.svg"
            title={getLangText('emptyHereLabel')}
            text={getLangText('changeSearchOrAddProductsLabel')}
        >
            {(+userData.id === TEST_ACCOUNT_SHOP_ID || +userData.id === PIANO_FASHION_SHOP_ID || +userData.id === TIM_SHOP_ID) &&
                <div className="sm:hidden">
                    <SelectedRow
                        ListActions={ProductsReserveListActions}
                        getStateRoot={getStateRoot}
                        colSpan={colSpan}
                        selectAllItems={selectAllItems}
                        selectedItems={selectedItems}
                        isSelected={!!selectedItems.length}
                        checkIfSomeExist={checkIfSomeExist}
                        updateSelection={updateSelection}
                        reserveList
                    />
                </div>
            }
            <div className="table-wrapper">
                <div className="table-responsive overflow-visible">
                    <table className="table live-table smaller-padd">
                        <thead>
                        <tr>
                            {!hiddenActions &&
                                <SelectedRow
                                    ListActions={ProductsReserveListActions}
                                    getStateRoot={getStateRoot}
                                    colSpan={colSpan}
                                    selectAllItems={selectAllItems}
                                    selectedItems={selectedItems}
                                    isSelected={!!selectedItems.length}
                                    checkIfSomeExist={checkIfSomeExist}
                                    updateSelection={updateSelection}
                                    reserveList
                                />
                            }
                            {!selectedItems.length &&
                                <>
                                    <th
                                        name="selmo_code"
                                        onClick={updateSortOptions}
                                        className={getSortClass('selmo_code')}
                                    >
							<span name="selmo_code">
								{getLangText('productCodeLabel')}
							</span>
                                    </th>
                                    <th
                                        name="products.name"
                                        onClick={updateSortOptions}
                                        className={getSortClass('products.name')}
                                    >
							<span name="products.name">
								{getLangText('productLabel')}
							</span>
                                    </th>
                                    <th
                                        name="selmo_clients.name"
                                        onClick={updateSortOptions}
                                        className={getSortClass('selmo_clients.name')}
                                    >
							<span name="selmo_clients.name">
								{getLangText('clientLabel')}
							</span>
                                    </th>
                                    <th
                                        name="products.price"
                                        onClick={updateSortOptions}
                                        className={getSortClass('products.price')}
                                    >
							<span name="products.price">
								{getLangText('priceLabel')}
							</span>
                                    </th>
                                    <th
                                        name="quantity"
                                        onClick={updateSortOptions}
                                        className={getSortClass('quantity')}
                                    >
							<span name="quantity">
								{getLangText('quantityLabel')}
							</span>
                                    </th>
                                    <th
                                        name="date"
                                        onClick={updateSortOptions}
                                        className={`text-right ${getSortClass('date')}`}
                                    >
							<span name="date">
								{getLangText('dateLabel')}
							</span>
                                    </th>
                                    <th/>
                                </>
                            }
                        </tr>
                        </thead>
                        <tbody>
                        {items.map((item) => (
                            <tr
                                onClick={() => goToEdit(item.product_id)}
                                key={item.id}
                            >
                                {!hiddenActions &&
                                    <td className="checkbox-col mobile-visible">
                                        <CheckboxField
                                            showLabel={false}
                                            name="selectedItems"
                                            onClick={(e) => e.stopPropagation()}
                                            className="mb-0"
                                            value={isSelected(item.id)}
                                            setValue={(field, value) => toggleSelection(item, field, value)}
                                        />
                                    </td>
                                }
                                <td
                                    data-thead="Kod:"
                                    className="number with-mobile-thead inline-thead order-lg-0 order-1 my-lg-0 my-1"
                                >
                                    {item.selmo_code}
                                </td>
                                <td className="with-photo w-lg-auto w-100">
                                    <div className="d-flex align-items-lg-center mt-sm-0 mt-2">
                                        <div>
                                            <figure>
                                                {item.product_photo && item.product_photo !== '0' ?
                                                    <ServerImage src={item.product_photo} alt={item.name}/> :
                                                    <img src="/assets/images/default/product.svg" alt=""/>
                                                }
                                            </figure>
                                        </div>
                                        <div>
                                            <div className="product-name">
                                                {item.name}
                                                {item.manufacturer_id &&
                                                    <div className="text-xs text-desc">{item.manufacturer_name}</div>
                                                }
                                                <span className="d-lg-none">
                                                {(item.price || item.sizes_price) ?
                                                    (item.sizes_price ? `${getParsedPrice(+item.sizes_price)}` : `${getParsedPrice(+item.price)}`) :
                                                    `${getPrice(0)}`
                                                }
                                                </span>
                                                <span
                                                    className="d-lg-none">{` • ${item.quantity} ${getLangText('piecesShortLabel')}`}</span>
                                                <div className="small">{item.size_name && `${item.size_name}`}</div>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td className="with-photo small-client full-radius w-lg-auto w-100">
                                    <div className="d-flex align-items-lg-center mt-sm-0 mt-2">
                                        <ClientAvatar
                                            className="d-lg-block d-none"
                                            photo={item.client_avatar}
                                            type={item.social_type}
                                        />
                                        <div>
                                            <div className="product-name">
                                                {item.client_name}
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td className="w-lg-auto w-100 d-lg-table-cell d-none">
                                    {(item.price || item.sizes_price) ?
                                        (item.sizes_price ? `${getParsedPrice(item.sizes_price)}` : `${getParsedPrice(item.price)}`) :
                                        `${getPrice(0)}`
                                    }
                                </td>
                                <td className="d-lg-table-cell d-none">
                                    {item.quantity}
                                </td>
                                <td
                                    data-thead={`${getLangText('addedLabel')}:`}
                                    className="text-sm-right date w-lg-auto w-100 order-lg-0 order-2 with-mobile-thead inline-thead mb-lg-0 mb-2 text-nowrap"
                                >
                                    <OverlayTrigger
                                        placement='top'
                                        overlay={
                                            <Tooltip
                                                className="small-size"
                                                id="time"
                                            >
                                                {convertToUserTimeZone(item.date).format('DD.MM.YYYY HH:mm')}
                                            </Tooltip>
                                        }
                                    >
                                        <TimeAgo
                                            datetime={convertToUserTimeZone(item.date).format('YYYY-MM-DD HH:mm')}
                                            locale={lang}
                                        />
                                    </OverlayTrigger>
                                </td>
                                <td className="text-right absolute-dropdown-dots">
                                    {!hiddenActions &&
                                        <CustomDropdown
                                            icon="icon-dots"
                                            onClick={(e) => e.stopPropagation()}
                                        >
                                            <Link
                                                to={`${RouterPaths.ClientsList}/${item.shop_client_id}`}
                                                className="custom-option"
                                            >
                                                {getLangText('goToClientButton')}
                                            </Link>
                                            <button
                                                className="custom-option"
                                                onClick={() => onCreateOrderButtonClick(item)}
                                            >
                                                {getLangText('createOrderButton')}
                                            </button>
                                        </CustomDropdown>
                                    }
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            </div>
            <ConfirmModal
                title={getLangText('createOrderButton')}
                SubTitle={() => <span>{getLangText('askForCreateOrderLabel')}</span>}
                saveButton={getLangText('createButton')}
                show={createOrderModalOpen}
                isWaiting={waiting}
                onSubmit={createOrder}
                hide={() => setCreateOrderModalOpen(false)}
            />
            {sendSummaryMessageModalOpen &&
                <SendSummaryMessageModal
                    defaultValue={defaultTemplateMessage}
                    afterSubmit={() => afterSubmit(defaultTemplateMessage.shop_order_id)}
                    modalVisible={sendSummaryMessageModalOpen}
                    hideModal={(withSent = false) => {
                        setSendSummaryMessageModalOpen(false)
                        afterSubmit(defaultTemplateMessage.shop_order_id, withSent)
                    }}
                />
            }
        </EmptyList>
    );
};

export default ProductsTable;
