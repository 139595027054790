import React, {useState} from 'react';
import ServerImage from "../../../../../shared/components/serverImage/ServerImage";
import {Link} from "react-router-dom";
import {RouterPaths} from "../../../../../../routes/RouterPath";
import moment from "moment";
import {useSelector} from "react-redux";
import useLang from "../../../../../../../src_shared/hooks/useLang";
import AssignToPlace from "../../../assignToPlace/AssignToPlace";
import {services} from "../../../../../../RestServices";
import Note from "./Note";
import UnpackOrderButton from "./UnpackOrderButton";
import {CHIQUE_SHOP_ID, TEST_ACCOUNT_SHOP_ID, TIM_SHOP_ID} from "../../../../../../../src_shared/enums/TrustedShopsIds";
import {convertToUserTimeZone} from "../../../../../shared/helpers/dateHelpers";

const OrderInfo = ({values, fetchData, fetchProducts}) => {
    const {userData} = useSelector((state) => state.session);
    const {getLangText} = useLang();
    const [showModal, setShowModal] = useState(false);

    return (
        <div className="mt-3">
            <div className="flex items-center mb-3">
                <figure className="user-avatar mr-3" style={{width: 'auto', height: 'auto'}}>
                    {values.client?.photo && values.client?.photo !== '0' ?
                        <div className="w-[40px] h-[40px] rounded-full overflow-hidden">
                            <ServerImage className="w-full h-full object-cover"
                                         src={values.client?.photo}/>
                        </div> :
                        <img src="/assets/images/default/fb-default.jpeg"/>
                    }
                    {(userData.facebook_id && userData.instagram_id) &&
                        <>
                            {values.client?.social_type === 'facebook' &&
                                <img className="msg-logo" src="/assets/images/svg/messenger-icon.svg"/>}
                            {values.client?.social_type === 'instagram' &&
                                <img className="msg-logo" src="/assets/images/svg/instagram-icon.svg"/>}
                        </>
                    }
                </figure>
                <div className="name">
                    <Link
                        className="font-bold"
                        to={`${RouterPaths.ClientsList}/${values.client?.shop_client_id}/`}
                    >
                        {values.client?.name}
                    </Link>
                    {values.client?.shop_client_id &&
                        <div className="client-number sm:text-sm text-xs text-desc">
                            {getLangText('clientNumberFullLabel')}: <strong>{values.client?.shop_client_id}</strong>
                        </div>
                    }
                </div>
                <div className="ml-auto sm:block hidden">
                    <Link
                        to={`${RouterPaths.OrdersList}/${values.shop_order_id}/`}
                        className="button border-button small-size"
                    >
                        {getLangText('goToOrderButton')}
                    </Link>
                </div>
            </div>
            <Note data={values}/>
            <div className="font-medium text-sm">
                {getLangText('customerBasketOrderLabel')}:&nbsp;
                <strong>{values.shop_order_id}</strong>
            </div>
            {(values.shelf_name) &&
                <div className="my-1 font-medium text-sm">
                    {getLangText('localizationLabel')}:&nbsp;
                    <strong>{values.shelf_name}</strong>
                </div>
            }
            <div className="font-medium text-sm">
                                <span
                                    className="d-lg-inline-block d-none">{getLangText('createdDateLabel')}:&nbsp;</span>
                <span className="d-lg-none d-inline-block">{getLangText('createdLabel')}:&nbsp;</span>
                <strong>{convertToUserTimeZone(values.date).format('DD.MM.YYYY HH:mm')}</strong>
            </div>
            <div className="mt-3 mb-2 sm:hidden w-full">
                <Link
                    style={{paddingTop: 11, paddingBottom: 11}}
                    to={`${RouterPaths.OrdersList}/${values.shop_order_id}/`}
                    className="button border-button small-size w-full"
                >
                    {getLangText('goToOrderButton')}
                </Link>
            </div>
            <button
                onClick={() => setShowModal(true)}
                type="button"
                className="button primary w-full"
            >
                {getLangText('assignPlace')}
            </button>
            {(+userData.id === TIM_SHOP_ID || +userData.id === TEST_ACCOUNT_SHOP_ID || +userData.id === CHIQUE_SHOP_ID) &&
                <UnpackOrderButton
                    values={values}
                    fetchProducts={fetchProducts}
                />
            }
            {showModal &&
                <AssignToPlace
                    modalVisible={showModal}
                    hideModal={() => setShowModal(false)}
                    rest={services.API_WAREHOUSE_ORDER}
                    fetchData={fetchData}
                    withScanner={false}
                />
            }
        </div>
    );
};

export default OrderInfo;
