import React, {useState} from 'react';
import useLang from "../../../../../../src_shared/hooks/useLang";
import moment from "../../../../shared/moment";
import PostNotificationModal from "./PostNotificationModal";
import ConfirmModal from "../../../../orders/list/components/export/ConfirmModal";
import useSubmitData from "../../../../../../src_shared/hooks/useSubmitData";
import {services} from "../../../../../RestServices";
import WaitingForSend from "../../../../../../src_shared/components/ui/waitingForSend/WaitingForSend";
import {convertToUserTimeZone} from "../../../../shared/helpers/dateHelpers";

const PostNotification = ({values, loadData}) => {
    const {getLangText} = useLang();
    const [showModal, setShowModal] = useState(false);
    const [showCancelModal, setShowCancelModal] = useState(false);


    const [{onSave: cancelPost, isWaiting: waitingForCancel}] = useSubmitData(
        {
            rest: `${services.API_VIDEO_CANCEL_POST_NOTIFICATIONS}/${values.id}`,
            afterSubmit: () => {
                loadData(values.id)
                setShowCancelModal(false);
            }
        });

    if (!+values.notifications_clients_total) return null;

    return (
        <section className="bg-[#fff] rounded-[5px] p-[25px] mb-3">
            <div className="flex">
                <div>
                    <div
                        className={`font-bold text-lg`}>{getLangText('notifyInSelmoApp')}</div>
                    <div
                        className="font-medium text-sm text-desc mb-3">{getLangText('sendNotificationInThisPost')}</div>
                </div>
                <img
                    className={`w-[24px] h-[24px] min-w-[24px] ml-auto mt-1`}
                    src="/assets/images/svg/selmo-circle-blue.svg"
                    alt="selmo"
                />
            </div>
            {!!values?.notifications_history?.length &&
                <div className="mb-3">
                    <div className="font-bold text-sm mb-2">
                        {getLangText('historyLabel')}
                    </div>
                    <div className="space-y-3">
                        {values?.notifications_history?.map((item) => (
                            <div
                                key={item.id}
                                className="flex text-xs items-center"
                            >
                                <img className="w-[12px] h-[12px] min-w-[12px] mr-1" src="/assets/images/svg/sent.svg"
                                     alt=""/>
                                <div className="font-bold">{getLangText('sentNotification')}</div>
                                <div className="text-desc font-medium ml-2">
                                    {convertToUserTimeZone(item.date).format('DD.MM.YYYY HH:mm:ss')}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            }
            {(!!+values.waitingForSend && !!values.notifications_send_date) ?
                <div className="p-3 rounded-[7px] bg-[#FFF7F1] mt-3">
                    <div className="flex">
                        <div className="mr-2 mt-1">
                            <img className="min-w-[18px] h-[18px]" src="/assets/images/svg/time-orange.svg"
                                 alt="time"/>
                        </div>
                        <div>
                            <div
                                className="font-bold text-sm text-[#994700]">{getLangText('scheduledNotification')}</div>
                            <div className="text-xs font-medium text-[#C8640F]">
                                {getLangText({key: 'willSendIn', data: [values.notifications_send_date]})}
                            </div>
                        </div>
                    </div>
                    <button
                        onClick={() => setShowCancelModal(true)}
                        className="self-center ml-auto button border-button w-full mt-3">
                        {getLangText('cancelSending')}
                    </button>
                </div> :
                !!+values.waitingForSend ?
                    <WaitingForSend
                        small
                        title="notificationsAreBeingSentLabel"
                        field="waitingForSendMobileNotifications"
                        fetchData={() => loadData(values.id)}
                        rest={`${services.API_VIDEO_SEND_NOTIFICATIONS_STATUS}/${values.id}`}
                    /> :
                    <div className="tooltip-parent">
                        {!values.video_url &&
                            <div
                                style={{whiteSpace: 'pre'}}
                                className="tooltip-content small-size leading-[16px] text-center">
                                {getLangText('notificationsWillBeVisibleAfterVideoProcessing')}
                            </div>
                        }
                        <button
                            disabled={!values.video_url}
                            onClick={() => setShowModal(true)}
                            type="button"
                            className={`button primary small-size w-full ${!values.video_url ? 'not-active' : ''}`}
                            style={{fontSize: 14}}
                        >
                            {getLangText({
                                key: 'notifyClients',
                                data: [values.notifications_clients_total]
                            })}
                        </button>
                    </div>
            }
            {showModal &&
                <PostNotificationModal
                    modalVisible={showModal}
                    hideModal={() => setShowModal(false)}
                    data={values}
                    loadData={loadData}
                />
            }
            {showCancelModal &&
                <ConfirmModal
                    title={getLangText('wantToCancelPost')}
                    saveButton={getLangText('yesButton')}
                    show={showCancelModal}
                    isWaiting={waitingForCancel}
                    onSubmit={cancelPost}
                    hide={() => setShowCancelModal(false)}
                />
            }
        </section>
    );
};

export default PostNotification;
