import React from 'react';

const Skeleton = () => {
    return (
        <>
            <div className="flex">
                <div
                    className="animate-pulse h-[12px] bg-gray-200 rounded-xl dark:bg-gray-700 w-[12px] mr-1"/>
                <div className="grow">
                    <div
                        className="animate-pulse h-[15px] mb-1 bg-gray-200 rounded-xl dark:bg-gray-700 w-full"/>
                    <div className="animate-pulse h-[10px] bg-gray-200 rounded-xl dark:bg-gray-700 w-[80px]"/>
                </div>
            </div>
            <div className="flex">
                <div
                    className="animate-pulse h-[12px] bg-gray-200 rounded-xl dark:bg-gray-700 w-[12px] mr-1"/>
                <div className="grow">
                    <div
                        className="animate-pulse h-[15px] mb-1 bg-gray-200 rounded-xl dark:bg-gray-700 w-full"/>
                    <div className="animate-pulse h-[10px] bg-gray-200 rounded-xl dark:bg-gray-700 w-[120px]"/>
                </div>
            </div>
        </>
    );
};

export default Skeleton;