import React from 'react';

const PhotoPreviewButton = ({onClick = () => {}, className = '', src = ''}) => {
    return (
        <div
            onClick={onClick}
            className={`relative cursor-pointer group rounded-md overflow-hidden after:content-[''] after:bg-[rgba(0,0,0,0.2)] after:absolute after:top-0 after:left-0 after:right-0 after:bottom-0 after:w-full after:h-full ${className}`}>
            <img
                className="sm:h-full w-full sm:w-auto sm:object-contain object-cover"
                src={src}
                alt="preview"
            />
            <img
                className="pointer-events-none absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 sm:w-[18px] w-[14px] z-10 group-hover:scale-[115%] transition-all"
                src="/assets/images/svg/play.svg"
                alt="play"
            />
        </div>
    );
};

export default PhotoPreviewButton;
