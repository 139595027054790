import React, {useCallback, useEffect, useState} from 'react';
import Group from "../group/Group";
import Loader from "../../../../components/app/shared/Loader";
import {selmoUrl} from "../../../api/api";
import {services} from "../../../../components/RestServices";
import debounce from "lodash.debounce";
import {useDispatch, useSelector} from "react-redux";
import EditProductFormActions from "../../../../components/app/products/form/edit/FormActions";
import {getStateRoot} from "../../../../components/app/products/form/edit/formReducer";
import {createSelector} from "reselect";
import useLang from "../../../hooks/useLang";
import CopyButton from "../copyButton/CopyButton";
import api from "../../../../services/axios/axios";

const CodeField = (props) => {

    const {
        name,
        setValue,
        value,
        disabled,
        extraProps,
        type,
        inModal,
        placeholder,
        values,
    } = props;

    const dispatch = useDispatch();
    const {getLangText} = useLang();

    const {uniqueProductCodeError} = useSelector(createSelector(getStateRoot, (stateRoot) => stateRoot));
    const {product_code} = useSelector((state) => state.session.userData);

    const checkSelmoCode = async (code) => {
        try {
            await api.get(`${selmoUrl}/${services.API_PRODUCT_SELMO_CODE_SEARCH}?search=${code}&id=${values.id}`)
            dispatch(EditProductFormActions.setUniqueProductCodeError(null))
        } catch (e) {
            dispatch(EditProductFormActions.setUniqueProductCodeError(e.response.data.message))
        }
    }

    const onChange = (e) => {
        setValue(name, e.target.value)
        debouncedSave(e)
    }

    const debouncedSave = useCallback(
        debounce((e) => checkSelmoCode(e.target.value), 700),
        [values.id]
    );

    const [waitingForCode, setWaitingForCode] = useState(false);

    const generateCode = async () => {
        setWaitingForCode(true)
        dispatch(EditProductFormActions.setUniqueProductCodeError(null))
        try {
            const {data} = await api.get(`${selmoUrl}/${services.API_PRODUCT_CODE}`,)
            setValue('selmo_code', data.code)
        } catch (e) {

        } finally {
            setWaitingForCode(false);
        }
    }

    useEffect(() => {
        return () => dispatch(EditProductFormActions.setUniqueProductCodeError(null));
    }, [])

    return (
        <Group
            {...props}
            style={{maxWidth: 'none'}}
            className={`code-form with-copy-btn with-group-buttons mb-0 ${uniqueProductCodeError ? 'error-group' : ''} ${inModal ? 'prefix-m-top' : ''}`}
        >
            <Loader className="small-loader" isLoading={waitingForCode}/>
            <div className="input-with-prefix sm:pb-0 pb-2.5 pl-sm-2 pl-0">
                <div className="flex items-center grow sm:w-auto w-full sm:border-b-0 border-b-[1px] sm:pl-0 pl-2.5 sm:mb-0 mb-2.5 ">
                    <span className="code-prefix">{product_code}</span>
                    <input
                        type={type}
                        className="form-control"
                        onChange={onChange}
                        value={value}
                        disabled={disabled}
                        placeholder={placeholder}
                        {...extraProps}
                    />
                </div>
                <div className="flex items-center sm:grow-0 grow sm:px-0 px-2.5">
                    <button
                        type='button'
                        className="button border-button small-size left-icon sm:grow-0 grow"
                        onClick={generateCode}
                    >
                        <i className="icon-refresh"/>
                        <span className="sm:hidden">{getLangText('generateNewLabel')}</span>
                        <span className="sm:inline-block hidden">{getLangText('generateNewProductCodeLabel')}</span>
                    </button>
                    <CopyButton
                        copyText={`${product_code} ${value}`}
                        copyButtonText={getLangText('copyButton')}
                        copiedButtonText={getLangText('copiedLabel')}
                        buttonClassName="button border-button small-size copy-btn copied left-icon sm:grow-0 grow"
                        withoutTooltip={true}
                    />
                </div>
            </div>
            {uniqueProductCodeError &&
                <div className="error-text">
                    {uniqueProductCodeError}
                </div>
            }
        </Group>
    );
}

CodeField.defaultProps = {
    setValue: () => {
    },
    inModal: false,
    value: '',
    required: false,
    name: '',
    disabled: false,
    extraProps: {},
    type: 'text',
    waiting: false,
}

export default CodeField;
