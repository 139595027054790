import React, {useState} from 'react';
import Field from "../../../src_shared/components/form/field/Field";
import useLang from "../../../src_shared/hooks/useLang";
import {selmoUrl} from "../../../src_shared/api/api";
import {services} from "../../RestServices";
import {showAlert} from "../shared/components/alert/AlertActions";
import {batch, useDispatch} from "react-redux";
import {Link, useHistory} from "react-router-dom";
import {RouterPaths} from "../../routes/RouterPath";
import {Helmet} from "react-helmet";
import SessionActions from "../shared/session/SessionActions";
import api from "../../../services/axios/axios";
import Button from "../../../src_shared/components/ui/Button/Button";
import SocialMediaButtons from "./components/socialMediaButtons/SocialMediaButtons";

const LoginPage = () => {
    const {getLangText} = useLang();
    const dispatch = useDispatch();
    const history = useHistory();
    const [waiting, setWaiting] = useState(false);
    const [error, setError] = useState(null);
    const [values, setValues] = useState({
            email: '',
            password: '',
        }
    );
    const setValue = (field, value) => setValues({...values, [field]: value})

    const onSubmit = async (e) => {
        e.preventDefault();
        setWaiting(true);
        try {
            const {data} = await api.post(`${selmoUrl}/${services.API_LOGIN}`, {
                email: values.email,
                password: values.password,
            })

            batch(() => {
                dispatch(SessionActions.setAndSaveInStorageToken(data.token))
                dispatch(showAlert('successfullyLoginLabel', ''));
                if (!data.hasShop) {
                    dispatch(SessionActions.setTutorial(true))
                    history.push(RouterPaths.Tutorial)
                } else {
                    dispatch(SessionActions.sessionUnChecked());
                    dispatch(SessionActions.setLogged(true))
                    history.push(RouterPaths.Dashboard)
                }
            })

        } catch (e) {
            setError(e?.response?.data?.message)
            dispatch(showAlert(e?.response?.data?.message, '', 'alert-fail'))
        } finally {
            setWaiting(false)
        }
    }

    return (
        <>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{getLangText('loginButton')} - Selmo</title>
            </Helmet>
            <a href="https://selmo.io">
                <img alt="selmo" width={107} src="/assets/images/logo.svg"/>
            </a>
            <h1 className="text-3xl font-bold mt-6 mb-8"
                dangerouslySetInnerHTML={{__html: getLangText('loginButton')}}/>
            <form
                id="login-form"
                onSubmit={onSubmit}>
                {error &&
                    <div className="form-info-box alert-style red w-100 mb-2">
                        <div>
                            <i className="icon-info-c"/>
                        </div>
                        <div className="title">
                            {getLangText(error)}
                        </div>
                    </div>
                }
                <Field
                    value={values.email}
                    label={getLangText('emailLabel')}
                    setValue={setValue}
                    placeholder={getLangText('textEmailLabel')}
                    type="email"
                    name="email"
                    extraProps={{required: true}}
                    className="big-size"
                    floatedLabel
                />
                <Field
                    value={values.password}
                    label={getLangText('passwordLabel')}
                    setValue={setValue}
                    placeholder={getLangText('passwordLabel')}
                    type="password"
                    name="password"
                    extraProps={{required: true}}
                    className="big-size"
                    floatedLabel
                />
                <div className="text-center">
                    <Link to={RouterPaths.ForgetPassword}
                          className="special-link">{getLangText('forgetPasswordButton')}</Link>
                </div>
                <div className="button-place">
                    <Button
                        type="submit"
                        isWaiting={waiting}
                        title="loginButton"
                        className="button primary btn-proceed w-full"
                    />
                </div>
                <div className="relative border-t border-[#E5E7EB] mt-8 mb-8">
                        <span
                            className="text-lowercase bg-white px-2 absolute left-1/2 top-1/2 -mt-0.5 -translate-y-1/2 -translate-x-1/2 text-[#9CA3AF] text-[10px] font-bold">{getLangText('separatorOrLabel')}</span>
                </div>
                <SocialMediaButtons/>
            </form>
            <div className="change-page">
                {getLangText('dontHaveAnAccountLabel')}
                <Link to={RouterPaths.Register} className="special-link">{getLangText('signInButton')}</Link>
            </div>
        </>
    );
};

export default LoginPage;
