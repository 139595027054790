import React from 'react';
import SwitchField from "../../../../src_shared/components/form/switchField/SwitchField";
import Loader from "../../shared/Loader";
import useLang from "../../../../src_shared/hooks/useLang";

const ShopVisibilityForm = ({getFieldProps, onSave, isWaiting}) => {
    const {getLangText} = useLang();

    return (
        <div className="default-shadow-box bigger-y-padd sm:relative sticky bottom-0">
            <SwitchField
                {...getFieldProps('mobile_app_orders')}
                label={getLangText('orderHistoryVisible')}
                subLabel={getLangText('orderHistoryVisibleSubLabel')}
                id="mobile_app_orders"
                className="switch-form inline-label big-label align-items-start light-version mb-3"
            />
            <SwitchField
                {...getFieldProps('mobile_app_reserve_list')}
                label={getLangText('reserveListVisible')}
                subLabel={getLangText('reserveListVisibleSubLabel')}
                id="mobile_app_reserve_list"
                className="switch-form inline-label align-items-start big-label light-version mb-3"
            />
            <div className="flex items-center w-full">
                <SwitchField
                    {...getFieldProps('mobile_app')}
                    label={getLangText('shopVisibleInAppLabel')}
                    id="mobile_app"
                    className="switch-form inline-label  big-label light-version mb-0"
                />
                <button
                    type="button"
                    className={`button primary ml-auto small-size ${isWaiting ? 'loading-button' : ''}`}
                    onClick={onSave}
                >
                    {isWaiting && <Loader isLoading/>}
                    {getLangText('saveButton')}
                </button>
            </div>
        </div>
    );
};

export default ShopVisibilityForm;
