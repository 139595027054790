import React, {useState} from 'react';
import axios from "axios";
import {useDispatch} from "react-redux";
import StylesLoader from "../../loader/Loader";
import useLang from "../../../hooks/useLang";
import {selmoUrl} from "../../../api/api";
import {showAlert} from "../../../../components/app/shared/components/alert/AlertActions";
import api from "../../../../services/axios/axios";

const WaitingForSent = ({loadData, rest, small = false}) => {
	const {getLangText} = useLang();
	const dispatch = useDispatch();
	const [waiting, setWaiting] = useState(false);

	const checkStatus = async () => {
		setWaiting(true)
		try {
			const {data} = await api.get(`${selmoUrl}/${rest}`)
			if (!data.waitingForSend) {
				loadData()
			} else {
				dispatch(showAlert('stillSendingMessagesLabel', 'tryAgainInWhileLabel', 'alert-info'))
			}

		} catch (e) {
			console.warn('Cannot check status')
		} finally {
			setWaiting(false)
		}
	}

	return (
		<div className={`default-shadow-box summary-box mb-3 loading-box ${small ? 'small-size' : ''}`}>
			<div className="d-flex align-items-center w-100">
				<StylesLoader className="static-loader w-auto h-auto mr-3" isLoading={true}/>
				<div className="mr-2">
					<div className="title small-size mb-0">{getLangText('summariesAreBeingSentLabel')}</div>
					<div className="sub-title extra-small-text">{getLangText('itMakeTakeUpFiveMinLabel')}</div>
				</div>
				<button
					onClick={checkStatus}
					type="button"
					className={`button border-button small-size ml-auto ${waiting ? 'loading-button' : ''}`}
				>
					<StylesLoader isLoading={waiting}/>
					{getLangText('refreshLabel')}
				</button>
			</div>
		</div>
	);
};

export default WaitingForSent;
