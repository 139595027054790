import React, {useState} from 'react';
import {Link, useHistory} from "react-router-dom";
import {RouterPaths} from "../../../../routes/RouterPath";
import {services} from "../../../../RestServices";
import useLang from "../../../../../src_shared/hooks/useLang";
import useFormLocal from "../../../../../src_shared/hooks/useFormLocal";
import SwitchField from "../../../../../src_shared/components/form/switchField/SwitchField";
import Loader from "../../../../app/shared/Loader";
import Wrapper from "../Wrapper";
import Field from "../../../../../src_shared/components/form/field/Field";
import ConfirmModal from "../../../../app/orders/list/components/export/ConfirmModal";
import Model from "../../../../../src_shared/modules/model/Model";
import {useDispatch} from "react-redux";
import {showAlert} from "../../../../app/shared/components/alert/AlertActions";
import SettingsShopifyModel from "../../../../../modules/models/settings/SettingsShopifyModel";
import ConnectModal from "./ConnectModal";

const ShopifyConfiguration = () => {
    const {getLangText} = useLang();
    const [showModal, setShowModal] = useState(false);
    const [showConnectModal, setShowConnectModal] = useState(false);
    const dispatch = useDispatch();

    const [{onSave, isWaiting, isLoading, values}] = useFormLocal({
        rest: services.API_SETTINGS_SHOPIFY,
        model: SettingsShopifyModel,
        afterSubmit: (response) => {
            if (response.url) {
                window.location.href = response.url
            } else {
                dispatch(showAlert())
            }
        }
    });

    const [{onSave: syncProducts, isWaiting: waitingForProducts}] = useFormLocal({
        rest: services.API_SETTINGS_SYNC_SHOPIFY,
        initialAutoLoad: false,
        model: new Model(),
        method: 'get',
        afterSubmit: () => {
            setShowModal(false)
            dispatch(showAlert('syncProductsSuccess', 'productsWillShowInFewMinutes'))
        }
    });

    return (
        <>
            <div className="header-bar breadcrumbs-in-react-app">
                <ul className="breadcrumbs">
                    <li>
                        <Link to={RouterPaths.Settings}>
                            {getLangText('asideSettingsLabel')}
                        </Link>
                    </li>
                    <li>
                        <Link to={RouterPaths.SettingsIntegration}>{getLangText('settings_nav_integrations')}</Link>
                    </li>
                    <li className="active">
                        Shopify
                    </li>
                </ul>
            </div>
            <Wrapper
                isConnected={!!+values.is_connected}
                setShowModal={setShowModal}
            >
                <div className="loader-parent">
                    <Loader isLoading={isLoading}/>
                    <div className="settings-form pt-4">
                        <div className="font-bold text-sm">Shopify</div>
                        <div className="text-desc text-sm mb-3 font-medium">{getLangText('connectYourAccountWithShopify')}</div>
                        {!!+values.is_connected &&
                            <div
                                style={{maxWidth: '100%'}}
                                className="form-info-box small-line-height align-items-center w-full green w-100 mb-3">
                                <div>
                                    <i className="icon-tick-c"/>
                                </div>
                                <div className="title">
                                    {getLangText('accountHasBeenConnectedLabel')}
                                </div>
                            </div>
                        }
                        {/*<div className="steps-wrapper">*/}
                        {/*    <div className="step">*/}
                        {/*        <div className="step-header align-items-start">*/}
                        {/*            <div className="title d-block flex-grow-1">*/}
                        {/*                <Field*/}
                        {/*                    {...getFieldProps('shop')}*/}
                        {/*                    disabled={!!+values.is_connected}*/}
                        {/*                    label={getLangText('shopUrl')}*/}
                        {/*                />*/}
                        {/*                <SwitchField*/}
                        {/*                    placeholder="yourstore.myshopify.com"*/}
                        {/*                    {...getFieldProps('active')}*/}
                        {/*                    label={getLangText('realizeOrdersInMyShop')}*/}
                        {/*                    className="switch-form inline-label big-label light-version mt-0 mb-0"*/}
                        {/*                />*/}
                        {/*                <div className="flex items-center mt-2 justify-end">*/}
                        {/*                    <button*/}
                        {/*                        type="button"*/}
                        {/*                        onClick={onSave}*/}
                        {/*                        className={`button primary ml-auto ${isWaiting ? 'loading-button' : ''}`}*/}
                        {/*                    >*/}
                        {/*                        <Loader isLoading={isWaiting}/>*/}
                        {/*                        {getLangText(!+values.is_connected ? 'connectLabel' : 'saveButton')}*/}
                        {/*                    </button>*/}
                        {/*                </div>*/}
                        {/*            </div>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        <button
                            type="button"
                            onClick={() => setShowConnectModal(true)}
                            className={`button w-full primary`}
                        >
                            {getLangText('connectWithShopify')}
                        </button>
                    </div>
                </div>
                {showConnectModal &&
                    <ConnectModal
                        hide={() => setShowConnectModal(false)}
                    />
                }
                {showModal &&
                    <ConfirmModal
                        dialogClassName="small-modal w-420"
                        title={getLangText('wantToSyncProductsWithShopify')}
                        SubTitle={() => <div className="mt-2"
                                             dangerouslySetInnerHTML={{__html: getLangText('wantToSyncProductsWithShopifySubLabel')}}/>}
                        saveButton={getLangText('yesButton')}
                        show={showModal}
                        isWaiting={waitingForProducts}
                        onSubmit={syncProducts}
                        hide={() => setShowModal(false)}
                    />
                }
            </Wrapper>
        </>
    );
};

export default ShopifyConfiguration;
