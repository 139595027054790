import moment from "moment/moment";
import React from "react";
import {RouterPaths} from "../../../../routes/RouterPath";
import useTableRowClick from "../../../shared/hooks/useTableRowClick";
import useLang from "../../../../../src_shared/hooks/useLang";
import EmptyList from "../../../shared/EmptyList";
import {useSelector} from "react-redux";
import ServerImage from "../../../shared/components/serverImage/ServerImage";
import {convertToUserTimeZone} from "../../../shared/helpers/dateHelpers";

const Table = ({
                   items,
                   isWaiting,
                   updateSortOptions,
                   getSortClass,
                   askForRemoveItem,
               }) => {

    const {getLangText} = useLang();
    const onTableRowClick = useTableRowClick(RouterPaths.SalesPost);
    const {userData} = useSelector((state) => state.session);

    return (
        <EmptyList
            items={items}
            isLoading={isWaiting}
            className="with-padd functionality-purchase"
            imgSrc="/assets/images/default/shop-logo.svg"
            title={getLangText('emptyHereLabel')}
            text={getLangText('changeSearchOrAddReelsLabel')}
        >
            <div className="table-wrapper">
                <div className="table-responsive">
                    <table className="table live-table smaller-padd">
                        <thead>
                        <tr>
                            <th>
                                {getLangText('numberShortLabel')}
                            </th>
                            <th className="whitespace">
                                {getLangText('postDescription')}
                            </th>
                            <th
                                name="selmo_app_active"
                                onClick={updateSortOptions}
                                className={`text-right whitespace-nowrap ${getSortClass('selmo_app_active')}`}
                            >
                                    <span name="selmo_app_active">
                                        {getLangText('productSourceMobileAppOnSelmoLabel')}
                                    </span>
                            </th>
                            <th
                                name="facebook_active"
                                onClick={updateSortOptions}
                                className={`text-right whitespace ${getSortClass('facebook_active')}`}
                            >
                                    <span name="facebook_active">
                                       Facebook
                                    </span>
                            </th>
                            <th
                                name="sales_overall"
                                onClick={updateSortOptions}
                                className={`text-right whitespace ${getSortClass('sales_overall')}`}
                            >
                                    <span name="sales_overall">
                                        {getLangText('salesValue')}
                                    </span>
                            </th>
                            <th
                                name="selmo_app_publish_date"
                                onClick={updateSortOptions}
                                className={`text-right whitespace ${getSortClass('selmo_app_publish_date')}`}
                            >
                                    <span name="selmo_app_publish_date">
                                        {getLangText('publishDateInApp')}
                                    </span>
                            </th>
                            <th
                                name="date"
                                onClick={updateSortOptions}
                                className={`text-right whitespace ${getSortClass('date')}`}
                            >
                                    <span name="date">
                                        {getLangText('addedDateLabel')}
                                    </span>
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {items.map((item) => (
                            <tr
                                {...onTableRowClick(item.id)}>
                                <td
                                    data-thead={`${getLangText('numberShortLabel')}`}
                                    className="number with-mobile-thead inline-thead mb-lg-0 mb-1">
                                    <div className="text-xs font-bold text-desc mr-2">#{item.id}</div>
                                </td>
                                <td
                                    style={{color: 'black'}}
                                    className="text-black sm:mb-0 mb-1 sm:w-auto w-full"
                                >
                                    <div className="flex items-center">
                                        <div
                                            className="w-[32px] h-[32px] rounded-[5px] overflow-hidden mr-2">
                                            {item.poster_url ?
                                                <img
                                                    className="w-full h-full object-cover"
                                                    src={item.poster_url} alt={item.poster_url}/> :
                                                <img
                                                    className="w-full h-full object-cover"
                                                    src="/assets/images/default/product.svg" alt=""/>
                                            }
                                        </div>
                                        <div
                                            className="max-w-[240px] sm:max-w-[300px] whitespace-nowrap overflow-ellipsis overflow-hidden">
                                            {item.selmo_app_description || getLangText('noDescription')}
                                        </div>
                                        <div className="sm:hidden text-xs ml-auto">{item.sales_overall}&nbsp;{userData?.currency_info?.text}</div>
                                    </div>
                                </td>
                                <td
                                    data-thead={`${getLangText('productSourceMobileAppOnSelmoLabel')}`}
                                    className="with-mobile-thead icon-status text-sm-right order-lg-0 order-4 sm:mr-0 mr-1"
                                >
                                    {!!+item.selmo_app_active ?
                                        <img
                                            src="/assets/images/svg/paid.svg"
                                            className="sm:w-[18px] ml-auto w-[14px] sm:mr-0 mr-1.5"
                                            alt=""
                                        /> :
                                        <img
                                            src="/assets/images/svg/cross-grey.svg"
                                            className="sm:w-[18px] ml-auto w-[14px] sm:mr-0 mr-1.5"
                                            alt=""
                                        />
                                    }
                                </td>
                                <td
                                    data-thead={"Facebook"}
                                    className="with-mobile-thead icon-status text-sm-right order-lg-0 order-4 sm:mr-0 mr-1"
                                >
                                    {!!item.facebook_published_date ?
                                        <div className="tooltip-parent inline-block">
                                            <div className="tooltip-content small-size leading-[16px] text-left">
                                                <div
                                                    className="text-[10px] font-medium">{getLangText('publishedDate')}</div>
                                                <div
                                                    className="font-bold">{convertToUserTimeZone(item.facebook_published_date).format('DD.MM.YYYY HH:mm')}</div>
                                            </div>
                                            <img
                                                src="/assets/images/svg/paid.svg"
                                                className="sm:w-[18px] ml-auto w-[14px] sm:mr-0 mr-1.5"
                                                alt=""
                                            />
                                        </div> :
                                        (!item.facebook_published_date && !!+item.facebook_active) ?
                                            <div className="inline-block tooltip-parent">
                                                <div
                                                    className="tooltip-content font-bold small-size leading-[16px] text-left">
                                                    <div
                                                        className="text-[10px] font-medium">{getLangText('publishDate')}</div>
                                                    <div
                                                        className="font-bold">{convertToUserTimeZone(item.facebook_publish_date).format('DD.MM.YYYY HH:mm')}</div>
                                                </div>
                                                <img
                                                    src="/assets/images/svg/cross-orange.svg"
                                                    className="sm:w-[18px] ml-auto w-[14px] sm:mr-0 mr-1.5"
                                                    alt=""
                                                />
                                            </div>
                                            :
                                            <img
                                                src="/assets/images/svg/cross-grey.svg"
                                                className="sm:w-[18px] ml-auto w-[14px] sm:mr-0 mr-1.5"
                                                alt=""
                                            />
                                    }
                                </td>
                                <td className="text-right">
                                    <div className="sm:block hidden">{item.sales_overall}&nbsp;{userData?.currency_info?.text}</div>
                                </td>
                                <td
                                    data-thead={`${getLangText('publishDateInApp')}`}
                                    className="text-sm-right date w-lg-auto w-100 with-mobile-thead mb-lg-0 text-nowrap"
                                >
                                    {item.selmo_app_publish_date ?
                                        convertToUserTimeZone(item.selmo_app_publish_date).format('DD.MM.YYYY HH:mm') : '-'
                                    }
                                </td>
                                <td
                                    data-thead={`${getLangText('addedDateLabel')}`}
                                    className="text-sm-right date w-lg-auto w-100 with-mobile-thead mb-lg-0 text-nowrap mb-sm-0 mb-2"
                                >
                                    {/*{moment.tz(item.date, 'YYYY-MM-DD HH:mm:ss', 'Europe/Warsaw').tz('America/New_York').format('DD.MM.YYYY HH:mm')}*/}
                                    {convertToUserTimeZone(item.date).format('DD.MM.YYYY HH:mm')}
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </EmptyList>
    );
};

export default Table;
