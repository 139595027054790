import React, {useEffect} from 'react';
import Modal from "react-bootstrap/Modal";
import {services} from "../../../../../../../RestServices";
import useFormLocal from "../../../../../../../../src_shared/hooks/useFormLocal";
import {useParams} from "react-router-dom";
import useLang from "../../../../../../../../src_shared/hooks/useLang";
import ExpendituresModel from "../../../../../../../../modules/models/warehouse/ExpendituresModel";
import ExpendituresModalForm from "./ExpendituresModalForm";
import ComponentLoader from "../../../../../../shared/components/componentLoader/ComponentLoader";

const ExpendituresModal = ({modalVisible, hideModal, fetchData, product}) => {

    const {id, variant_id} = useParams();
    const {getLangText} = useLang();

    const afterSubmit = async () => {
        await fetchData();
        hideModal();
    }

    const [{isWaiting, onSave, updateValues, getFieldProps}] = useFormLocal({
        rest: services.API_WAREHOUSE_EXPENDITURE,
        model: ExpendituresModel,
        afterSubmit,
        initialAutoLoad: false,
    });


    useEffect(() => {
        updateValues({
            product_id: id,
            product_size_id: variant_id,
        })
    }, [])

    return (
        <Modal
            dialogClassName="default-modal h-full"
            contentClassName="md:h-auto h-full"
            show={modalVisible}
            onHide={hideModal}
        >
            <Modal.Header>
                <Modal.Title>
                    {getLangText('selectExpendituresReasonLabel')}
                </Modal.Title>
                <button
                    onClick={hideModal}
                    type="button"
                    className="button"
                >
                    <i className="icon-cross"/>
                </button>
            </Modal.Header>
            <ExpendituresModalForm
                getFieldProps={getFieldProps}
                isWaiting={isWaiting}
                onSave={onSave}
                hideModal={hideModal}
                maxQuantity={product.size_squ || product.squ}
            />
        </Modal>
    );
};

export default ExpendituresModal;
