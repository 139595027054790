import React from 'react';
import SwitchField from "../../../../../../../../src_shared/components/form/switchField/SwitchField";
import Field from "../../../../../../../../src_shared/components/form/field/Field";

const SelmoPaczkaShippingToggle = ({item, getFieldProps, values}) => {
    return (
        <div className="border rounded-[5px]">
            <div className="flex items-center p-3">
                <SwitchField
                    {...getFieldProps([item.activeName])}
                    label={item.label}
                    className="switch-form inline-label big-label light-version d-flex mb-0"
                />
                <img
                    className="ml-auto h-[24px]"
                    src={item.logo} alt={item.label}/>

            </div>
            {!!+values[item.activeName] &&
                <div className="sm:flex items-end border-t border-dashed pl-[60px] py-3 pr-3">
                    <Field
                        {...getFieldProps([item.priceName])}
                        label="Cena dla klienta"
                        className="mb-0 sm:w-[125px] w-[180px] small-size hidden-arrows"
                        type="number"
                        addon="PLN"
                        extraProps={{autoFocus: true}}
                    />
                    <div className="sm:ml-3 text-[10px] leading-[14px] sm:mt-0 mt-1.5 sm:mb-2 text-[#6B7280]">
                        Twoja opłata <br className="sm:block hidden"/>
                        za przesyłkę: <strong>9.50 zł</strong>
                    </div>
                </div>
            }
        </div>
    );
};

export default SelmoPaczkaShippingToggle;
