import createReducer from "../../../../shared/helpers/createReducer";
import history from "../../../../../history";
import {parseParams} from "../../../../../../../../src_shared/list/ListActions";
import DataReducer from "../../../../../../../../src_shared/form/data/DataReducer";
import {getReduceMap} from "../../../../../../../../src_shared/filters/filterReducer";

const queryParams = history.location.search;
const parsedParams = parseParams(queryParams)

const pipe = +parsedParams.pipe || 'all'
const status = +parsedParams.status || 'all'

export const getStateRoot = (state) => state.admin.sales.messages.filters;
export const prefix = 'ADMIN_SALES_MESSAGES_FILTERS_';

export const getInitState = () => DataReducer.getInitState({
	pipe,
	status,
}, {
	pipe: 'all',
	status: 'all',
})


export const getInstance = () => createReducer(
	getInitState(),
	getReduceMap(),
	prefix,
);

export default getInstance();
