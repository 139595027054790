import React, {useState} from 'react';
import moment from "moment";
import StatusSelect from "../../../../messenger/addOrderModal/components/StatusSelect";
import {selmoUrl} from "../../../../../../src_shared/api/api";
import {services} from "../../../../../RestServices";
import {useDispatch, useSelector} from "react-redux";
import {showAlert} from "../../../../shared/components/alert/AlertActions";
import PackedButton from "../../../list/components/packedButton/PackedButton";
import PrintOrderButton from "../printOrder/PrintOrder";
import AssignCustomer from "../../assignCustomer/AssignCustomer";
import AssignCustomerModal from "../../assignCustomer/AssignCustomerModal";
import {createSelector} from "reselect";
import {getStateRoot} from "../../assignCustomer/formReducer";
import useLang from "../../../../../../src_shared/hooks/useLang";
import api from "../../../../../../services/axios/axios";
import CancelStatusConfirmModal from "../../../../shared/cancelStatusConfirmModal/CancelStatusConfirmModal";
import {convertToUserTimeZone} from "../../../../shared/helpers/dateHelpers";

const OrderHeader = ({values, setValue, userData, fetchData}) => {
    const dispatch = useDispatch();
    const {modalVisible} = useSelector(createSelector(getStateRoot, (stateRoot) => stateRoot));
    const {getLangText} = useLang();
    const [showCancelConfirmModal, setShowCancelConfirmModal] = useState(false);

    const setStatus = async (value) => {
        try {
            await api.post(`${selmoUrl}/${services.API_ORDER_STATUS}/${values.id}`, {
                status: value,
            })
            setValue('status', value);
            if (+value === 9) {
                setShowCancelConfirmModal(true);
            }
        } catch (e) {
            dispatch(showAlert('sthWentWrongWarning', '', 'alert-fail'))
            console.warn('submit orderStatus failed')
        }
    }

    const setOrderPackingStatus = async () => {
        const {shop_order_id, is_packed} = values;

        const oppositePacked = is_packed === '1' ? '0' : '1';

        try {
            await api.post(`${selmoUrl}/api/orderPacked`, {
                shop_order_id,
                is_packed,
            })
            setValue('is_packed', oppositePacked)
        } catch (e) {
            console.error(e)
        }
    }

    return (
        <div className="status-header">
            <div className="d-flex align-items-center flex-grow-1">
                <div className="flex-grow-1">
                    <h1 className="title">{getLangText('customerBasketOrderLabel')}&nbsp;{values.shop_order_id}</h1>
                    <div className="text-[#6B7280] font-medium text-sm">
                        <span className="d-lg-inline-block d-none">{getLangText('createdDateLabel')}:</span>
                        <span className="d-lg-none d-inline-block">{getLangText('createdLabel')}:</span>
                        &nbsp;<strong className="text-[#000]">{convertToUserTimeZone(values.date).format('DD.MM.YYYY HH:mm')}</strong>
                    </div>
                    {+userData.selmo_boss === 69 &&
                        <div className="flex items-center space-x-2">
                            <a
                                className="button primary small-size"
                                href={`https://app.selmo.io/admin/order-products/${userData.id}/${values.shop_order_id}`}
                                target="_blank"
                            >
                                Sprawdź historię
                            </a>
                        </div>
                    }
                </div>
                <div className="d-flex ml-auto align-items-center d-lg-none">
                    <AssignCustomer/>
                    <PrintOrderButton shop_order_id={values.shop_order_id}/>
                    <PackedButton
                        setValue={setOrderPackingStatus}
                        item={values}
                        className="mr-2 ml-2"
                    />
                </div>
            </div>
            <div className="d-flex-packed d-flex align-items-center ml-auto">
                <div className="d-lg-flex align-items-center d-none">
                    <AssignCustomer/>
                    <PrintOrderButton shop_order_id={values.shop_order_id}/>
                    <PackedButton
                        setValue={setOrderPackingStatus}
                        item={values}
                        className="mr-2 ml-2"
                    />
                </div>
                <StatusSelect
                    setValue={setStatus}
                    statusId={values.status}
                    className="w-sm-auto w-100"
                />
            </div>
            {modalVisible &&
                <AssignCustomerModal/>
            }
            {showCancelConfirmModal &&
                <CancelStatusConfirmModal
                    hideModal={() => setShowCancelConfirmModal(false)}
                    modalVisible={showCancelConfirmModal}
                    orderId={values.id}
                    fetchData={fetchData}
                />
            }
        </div>
    );
};

export default OrderHeader;
