import Model from "../../../src_shared/modules/model/Model";

class NotificationsModel extends Model {
	getModel() {
		return {
			history: [],
			total: 0,
			mobile_app: '1',
			waitingForSend: false,
			mobile_app_orders: '0',
			mobile_app_reserve_list: '0',
		};
	}

	buildDTO(data) {
		return {
			mobile_app: data.mobile_app,
			mobile_app_orders: data.mobile_app_orders,
			mobile_app_reserve_list: data.mobile_app_reserve_list,
		}
	}
}

export default new NotificationsModel();
