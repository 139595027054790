import Model, {convertObjectProperties} from "../../../src_shared/modules/model/Model";
import ValidationError from "../../../src_shared/form/validation/ValidationError";

class SettingsMessengerModel extends Model {
	getModel() {
		return {
			comments_quantity_limit: '0',
			send_reserve_list_message: '0',
			message_instead_of_comment: '',
		};
	}

	parseDTO(data) {
		return convertObjectProperties(
			data,
			{

			},
			{
				comments_quantity_limit: '0',
			},
		);
	}

	getValidators() {
		return {
			bot_comments_enabled: ValidationError.skip,
			like_instead_of_comment: ValidationError.skip,
			automatic_first_message: ValidationError.skip,
			send_reserve_list_message: ValidationError.skip,
			comments_quantity_enabled: ValidationError.skip,
			cart_text: ValidationError.notEmpty,
			summary: ValidationError.notEmpty,
			notification_message: ValidationError.notEmpty,
			add_to_cart_1: ValidationError.notEmpty,
			add_to_cart_2: ValidationError.notEmpty,
			add_to_cart_3: ValidationError.notEmpty,
			reserve_list_1: ValidationError.notEmpty,
			reserve_list_2: ValidationError.notEmpty,
			reserve_list_3: ValidationError.notEmpty,
			product_has_variants_1: ValidationError.notEmpty,
			product_has_variants_2: ValidationError.notEmpty,
			product_has_variants_3: ValidationError.notEmpty,
			wrong_code_1: ValidationError.notEmpty,
			wrong_code_2: ValidationError.notEmpty,
			wrong_code_3: ValidationError.notEmpty,
			inactive_product_1: ValidationError.notEmpty,
			limit_exceeded_1: ValidationError.notEmpty,
			message_instead_of_comment: ValidationError.skip,
		};
	}
}

export default new SettingsMessengerModel();
