import {
	USER_PERMISSIONS_PRODUCTS_BOUGHT_TAB_VISIBILITY,
	USER_PERMISSIONS_PRODUCTS_CURRENT_TAB_VISIBILITY,
	USER_PERMISSIONS_PRODUCTS_GALLERY_TAB_VISIBILITY,
	USER_PERMISSIONS_PRODUCTS_MENU_VISIBILITY,
	USER_PERMISSIONS_PRODUCTS_RESERVE_LIST_TAB_VISIBILITY, USER_PERMISSIONS_PRODUCTS_STATISTICS_VISIBILITY
} from "./UserPermissionsTypes";

export const EDIT_PRODUCT_VIEW = 'EDIT';
export const CURRENT_BASKET_PRODUCT_VIEW = 'CURRENT_BASKET';
export const GALLERY_PRODUCT_VIEW = 'GALLERY_PRODUCT_VIEW';
export const RESERVE_LIST_PRODUCT_VIEW = 'RESERVE_LIST';
export const FINISHED_CART_PRODUCT_VIEW = 'FINISHED_CART';
export const EXPENDITURES_LIST_PRODUCT_VIEW = 'EXPENDITURES_LIST';
export const REMOVED_LIST_PRODUCT_VIEW = 'REMOVED_LIST';
export const ACTIVITY_HISTORY_LIST_PRODUCT_VIEW = 'ACTIVITY_HISTORY_LIST';

const EditProductViews = [
	{
		id: EDIT_PRODUCT_VIEW,
		label: 'editProductCapitalLabel',
		totalField: null,
		permission: USER_PERMISSIONS_PRODUCTS_MENU_VISIBILITY,
	},
	{
		id: ACTIVITY_HISTORY_LIST_PRODUCT_VIEW,
		label: 'activityHistory',
		totalField: null,
		permission: USER_PERMISSIONS_PRODUCTS_STATISTICS_VISIBILITY,
	},
	{
		id: GALLERY_PRODUCT_VIEW,
		label: 'galleryProductCapitalLabel',
		totalField: null,
		permission: USER_PERMISSIONS_PRODUCTS_GALLERY_TAB_VISIBILITY,
	},
	{
		id: CURRENT_BASKET_PRODUCT_VIEW,
		label: 'currentInBasketLabel',
		totalField: 'currentCartTotal',
		permission: USER_PERMISSIONS_PRODUCTS_CURRENT_TAB_VISIBILITY,
	},
	{
		id: RESERVE_LIST_PRODUCT_VIEW,
		label: 'reserveListLabel',
		totalField: 'reserveListTotal',
		permission: USER_PERMISSIONS_PRODUCTS_RESERVE_LIST_TAB_VISIBILITY,
	},
	{
		id: FINISHED_CART_PRODUCT_VIEW,
		label: 'boughtLabel',
		totalField: 'finishedCartTotal',
		permission: USER_PERMISSIONS_PRODUCTS_BOUGHT_TAB_VISIBILITY,
	},
];



export default EditProductViews;
