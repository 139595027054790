import React from 'react';
import {NavLink} from "react-router-dom";
import useLang from "../../../src_shared/hooks/useLang";
import {useSelector} from "react-redux";

const SettingsAsideItem = ({item, closeMobileMenu}) => {

    const {path, title, origins, shopIds, permission} = item;

    const {getLangText} = useLang();
    const {userData} = useSelector((state) => state.session);

    if (!!permission && !+userData[permission]) return null;

    if (!!origins?.length || !!shopIds?.length) {
        if (!!origins?.length && !origins?.includes(userData.origin)) {
            if (!shopIds?.length) return null
        }
        if (!!shopIds?.length && !shopIds?.includes(+userData.id)) {
            if (!origins?.length) return null
        }
        if (!shopIds?.includes(+userData.id) && !origins?.includes(userData.origin)) return null;
    }

    return (
        <li>
            <NavLink
                onClick={closeMobileMenu}
                activeClassName="active"
                to={path}
            >
                {getLangText(title) || title}
            </NavLink>
        </li>
    );
};

export default SettingsAsideItem;
