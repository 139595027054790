import React from 'react';
import File from "./File";
import useFetchData from "../../../../../../../src_shared/hooks/useFetchData";
import {services} from "../../../../../../RestServices";
import {filesUpdateFiles} from "../FilesActions";
import {useDispatch} from "react-redux";
import Skeleton from "./Skeleton";

const Dropdown = ({items}) => {

    const dispatch = useDispatch();

    const [{isLoading}] = useFetchData({
        rest: services.API_EXPORT_EXPORT_FILES,
        afterSubmit: (res) => dispatch(filesUpdateFiles(res)),
    });

    return (
        <div
            className="absolute z-10 top-[calc(100%+10px)] space-y-1 right-0 bg-[white] p-[12px] rounded-[5px] shadow-default min-w-[240px]">
            {isLoading ?
                <Skeleton/> :
                !!items.length ?
                    items.map((item) => (
                        <File key={item.id} item={item}/>
                    )) :
                    <div className="font-bold text-xs flex items-center flex-col justify-center p-3">
                        <i className="icon-orders"/>
                        <div className="mt-2">Brak plików</div>
                    </div>
            }
        </div>
    );
};

export default Dropdown;