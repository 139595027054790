import {prefix} from "./formReducer";
import {getStateRoot} from "./formReducer";
import FormActions from "../../../../src_shared/form/FormActions";
import BasketDeliveryAddressModel from "../../../../modules/models/basket/BasketDeliveryAddressModel";
import {services} from "../../../RestServices";
import BasketPageFormActions from "../page/FormActions";
import {CUSTOMER_BASKET_DELIVERY_METHODS_VIEW} from "../../shared/enums/CustomerBasketViews";
import {showAlert} from "../../shared/components/alert/AlertActions";
import {selmoUrl} from "../../../../src_shared/api/api";
import {ERROR_NOT_FOUND} from "../../../../src_shared/enums/ErrorEnums";
import {getGETParamsUrl} from "../../../../src_shared/list/ListActions";
import BasketDeliveryMethodsFormActions from "../deliveryMethods/FormActions";
import api from "../../../../services/axios/axios";
import {getStateRoot as getBasketFormActionsStateRoot} from '../page/formReducer'

export class BasketDeliveryAddressFormActions extends FormActions {

	loadData(magicUrl, shopId) {
		return async (dispatch) => {
			dispatch(this.waiting.startWaiting());
			const params = {
				magic_url: magicUrl,
				shop_id: shopId,
			};
			try {
				const {data} = await api.get(`${selmoUrl}/${this.restService}${getGETParamsUrl(params)}`)
				const parsedItem = dispatch(this.parseData(data.item));
				dispatch(this.data.updateValues(parsedItem))
			} catch (e) {
				if (e.response?.data?.message === ERROR_NOT_FOUND || e.response?.status === 500) {
					dispatch(this.data.setValue('notFound', true));
				}
				console.error('Error loadData')
			} finally {
				dispatch(this.waiting.stopWaiting());
			}
		}
	}

	submitEditForm(values, history) {
		return async (dispatch, getState) => {
			const {id} = values;
			const basketPageData = getBasketFormActionsStateRoot(getState()).data;

			dispatch(this.validation.activateValidation());


			if (!this.dataModel.validateData(values)) {
				dispatch(showAlert('fillRequireFieldWarning', '', 'alert-fail'))
				console.error('Validation failed!');
				return;
			}

			const filteredData = Object.entries(values).filter(([key]) => key !== 'id');
			const finalData = Object.fromEntries(filteredData)

			const preparedData = this.dataModel.buildDTO(finalData)

			dispatch(this.waiting.startWaiting());

			try {
				const {data} = await api.put(`${selmoUrl}/${this.restService}`, preparedData)
				dispatch(this.afterSubmit(id))
				dispatch(showAlert())
				dispatch(BasketDeliveryMethodsFormActions.updateShippingMethods(data.shippingMethods))
				dispatch(BasketPageFormActions.data.updateValues({
					...basketPageData,
					shippingAddress: {
						...basketPageData.shippingAddress,
						country_id: preparedData.country,
						zip_code: preparedData.zip_code,
						city: preparedData.city,
					}
				}))
				dispatch(this.validation.deactivateValidation());
				dispatch(BasketDeliveryMethodsFormActions.validation.deactivateValidation());
			} catch (e) {
				dispatch(showAlert(e?.response?.data?.message || 'sthWentWrongWarning', '', 'alert-fail'))
				console.error('Error submitEditForm')
			} finally {
				dispatch(this.waiting.stopWaiting());
			}
		}
	}

	afterSubmit(id) {
		return (dispatch) => {
			dispatch(BasketPageFormActions.setBasketView(CUSTOMER_BASKET_DELIVERY_METHODS_VIEW))

		};
	}
}

export const getInstance = () => new BasketDeliveryAddressFormActions({
	prefix,
	getStateRoot,
	model: BasketDeliveryAddressModel,
	restService: services.API_CART_SHIPPING_ADDRESS,
});

export default getInstance();

