import React from 'react';
import useLang from "../../../../../../src_shared/hooks/useLang";
import {useSelector} from "react-redux";
import {
    AILEMA_SHOP_ID,
    AUX_MEUBLES_JOLIS_SHOP_ID, BUTIK_MADAME_PLUS_SIZE_SHOP_ID,
    BUTIK_MADAME_SHOP_ID, CELEBRE_SHOP_SHOP_ID, CRISTAL_BIZU_SHOP_ID, E_MODO_SHOP_ID,
    KROLOWA_PLUS_SIZE_SHOP_ID,
    LE_DUPLEX_SHOP_ID,
    LES_BONBONS_DE_MANDY_SHOP_ID, MELU_SHOP_ID,
    STILL_MODA_STYL_SHOP_ID,
    TEST_ACCOUNT_SHOP_ID,
    WIKA_EWA_FASHION_ID
} from "../../../../../../src_shared/enums/TrustedShopsIds";
import Field from "../../../../../../src_shared/components/form/field/Field";
import {getCurrenciesByShortcut} from "../../../../stats/list/List";
import {
    USER_PERMISSIONS_PRODUCT_PURCHASE_PRICE,
} from "../../../../shared/enums/UserPermissionsTypes";
import UserPermissionsWrapper from "../../../../../routes/UserPermissionsWrapper";

const PurchasePriceAndWeight = ({getFieldProps, setValue, currency_info, inModal}) => {
    const {getLangText} = useLang();
    const {userData} = useSelector((state) => state.session);

    if (+userData.id !== TEST_ACCOUNT_SHOP_ID && +userData.id !== BUTIK_MADAME_SHOP_ID && +userData.id !== AILEMA_SHOP_ID && +userData.id !== LES_BONBONS_DE_MANDY_SHOP_ID && +userData.id !== LE_DUPLEX_SHOP_ID && +userData.id !== STILL_MODA_STYL_SHOP_ID && +userData.id !== AUX_MEUBLES_JOLIS_SHOP_ID && +userData.id !== WIKA_EWA_FASHION_ID && +userData.id !== KROLOWA_PLUS_SIZE_SHOP_ID && +userData.id !== CELEBRE_SHOP_SHOP_ID && +userData.id !== E_MODO_SHOP_ID && +userData.id !== BUTIK_MADAME_PLUS_SIZE_SHOP_ID && +userData.id !== CRISTAL_BIZU_SHOP_ID && +userData.id !== MELU_SHOP_ID) {
        return null;
    }

    return (
        <div className={`flex space-x-2 sm:space-x-4 ${inModal ? '' : 'mt-3'}`}>
            <UserPermissionsWrapper allowedPermissions={[USER_PERMISSIONS_PRODUCT_PURCHASE_PRICE]}>
                <div className="flex-1">
                    <Field
                        {...getFieldProps('purchase_price')}
                        setValue={setValue}
                        label={getLangText('purchasePriceLabel')}
                        addon={getCurrenciesByShortcut(currency_info.text)}
                        type="number"
                        optional
                        placeholder="0.00"
                        labelClassName="whitespace-nowrap"
                    />
                </div>
            </UserPermissionsWrapper>
            <div className="flex-1">
                <Field
                    {...getFieldProps('weight')}
                    setValue={setValue}
                    label={getLangText('weightLabel')}
                    addon="KG"
                    type="number"
                    optional
                    placeholder="0.00"
                    labelClassName="whitespace-nowrap"
                />
            </div>
        </div>
    );
};

export default PurchasePriceAndWeight;
