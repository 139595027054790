import React from 'react';
import {Link} from "react-router-dom";
import useLang from "../../../src_shared/hooks/useLang";
import {useSelector} from "react-redux";

const SettingsBox = ({item}) => {

    const {path, title, desc, imgSrc, origins, shopIds, permission, imgStyle = {}} = item;

    const {getLangText} = useLang();
    const {userData} = useSelector((state) => state.session);

    if (!!permission && !+userData[permission]) return null;

    if (!!origins?.length || !!shopIds?.length) {
        if (!!origins?.length && !origins?.includes(userData.origin)) {
            if (!shopIds?.length) return null
        }
        if (!!shopIds?.length && !shopIds?.includes(+userData.id)) {
            if (!origins?.length) return null
        }
        if (!shopIds?.includes(+userData.id) && !origins?.includes(userData.origin)) return null;
    }


    return (
        <div className="settings-box">
            <Link to={path}>
                <div className="title">
                    {!!imgSrc &&
                        <img
                            style={imgStyle}
                            src={imgSrc}
                            alt="icon"
                        />
                    }
                    {getLangText(title) || title}
                </div>
                <div className="desc">
                    {getLangText({
                        key: desc,
                        data: [title]
                    })}
                </div>
                <div className="button-place">
                    <div className="button border-button">
                        {getLangText('button_go_to')}
                        <i className="icon-arrows"/>
                    </div>
                </div>
            </Link>
        </div>
    );
};

export default SettingsBox;
