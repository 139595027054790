import React from 'react';
import Field from "../../../../src_shared/components/form/field/Field";
import SwitchField from "../../../../src_shared/components/form/switchField/SwitchField";
import Loader from "../../../app/shared/Loader";
import useLang from "../../../../src_shared/hooks/useLang";
import {useSelector} from "react-redux";
import {TEST_ACCOUNT_SHOP_ID} from "../../../../src_shared/enums/TrustedShopsIds";

const DiscountCodeForm = ({getFieldProps, onSave, isWaiting, values}) => {
    const {getLangText} = useLang();
    const {userData} = useSelector((state) => state.session);

    return (
        <>
            <div className="d-flex flex-sm-nowrap flex-wrap mb-4 shipping-form-flex">
                <Field
                    {...getFieldProps('name')}
                    className="mb-sm-0 mb-3 mr-3"
                    label={getLangText('input_label_discount_name')}
                    type="text"
                />
                <Field
                    {...getFieldProps('value')}
                    className="mb-0 min-width discount-percent-group"
                    label={getLangText('input_label_discount_percent_value')}
                    type="number"
                    extraProps={{
                        min: 0,
                        max: 100,
                        maxLength: 3,
                    }}
                />
            </div>
            <SwitchField
                {...getFieldProps('free_shipping')}
                label={`${getLangText('settings_discount_text_4')} <strong class="ml-1">${getLangText('settings_discount_text_5')}</strong>`}
                className="switch-form inline-label big-label light-version mb-0 mt-0"
                labelClassName="with-light-text"
            />
            {(!+values.free_shipping && (+userData.id === TEST_ACCOUNT_SHOP_ID || +userData.id === 5316)) &&
                <div className="mt-3">
                    <SwitchField
                        {...getFieldProps('discount_without_shipping')}
                        label={getLangText('selectedDiscountWithoutShippingLabel')}
                        className="switch-form inline-label big-label light-version mb-0 mt-0"
                        labelClassName="with-light-text"
                    />
                </div>
            }
            <div className="mt-3">
                <SwitchField
                    {...getFieldProps('is_active')}
                    label={getLangText('activeLabel')}
                    className="switch-form inline-label big-label light-version mb-0 mt-0"
                    labelClassName="with-light-text"
                />
            </div>
            <button
                type="submit"
                onClick={onSave}
                className={`button primary mt-3 ${isWaiting ? 'loading-button' : ''}`}
            >
                <Loader isLoading={isWaiting}/>
                {getLangText('saveButton')}
            </button>
        </>
    );
};

export default DiscountCodeForm;
