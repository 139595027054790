import React, {useState} from 'react';
import {Link, useLocation, useParams} from "react-router-dom";
import useFormLocal from "../../../../../../src_shared/hooks/useFormLocal";
import Model from "../../../../../../src_shared/modules/model/Model";
import {services} from "../../../../../RestServices";
import useLang from "../../../../../../src_shared/hooks/useLang";
import {RouterPaths} from "../../../../../routes/RouterPath";
import Completing from "./components/completing/Completing";
import {parseParams} from "../../../../../../src_shared/list/ListActions";

const OrderCard = () => {
    const {id} = useParams();
    const {getLangText} = useLang();
    const {search} = useLocation();
    const query = parseParams(search);

    const [{values, fetchData, isLoading}] = useFormLocal({
        rest: `${services.API_WAREHOUSE_ORDER}/${id}?clickAction=${query.clickAction || '0'}`,
        model: new Model(),
    });

    return (
        <div className="">
            <div className="header-bar breadcrumbs-in-react-app">
                <Link
                    to={!!+query.fromShelf ? `${RouterPaths.Storage}/${values.shelf_id || values.size_shelf_id}` : RouterPaths.StorageScanner}
                    className="button back-to-panels mb-lg-3 mt-lg-0">
                    <i className="icon-arrow-left"/>
                    {getLangText('backButton')}
                </Link>
            </div>
            <div className="grid sm:grid-cols-5 sm:gap-4">
                <div className="sm:col-span-3">
                    <div className="bg-[white] rounded-[5px] px-6 relative">
                        <Completing
                            fetchData={fetchData}
                            isLoadingOrder={isLoading}
                            values={values}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OrderCard;
