import Model, {convertObjectProperties} from "../../../src_shared/modules/model/Model";
import ValidationError from "../../../src_shared/form/validation/ValidationError";
import moment from "moment/moment";
import {convertToUserTimeZone, convertToWarsawTimeZone} from "../../../components/app/shared/helpers/dateHelpers";

class SalesPostModel extends Model {
    getModel() {
        return {
            video_id: null,
            products: [],
            selmo_app_active: '1',
            selmo_app_publish_later: '0',
            facebook_post_description: '',
            facebook_publish_later: '0',
            comments_disabled: '0',
            reserve_list_enabled: '0',
            facebook_post_url: null,
            facebook_published_date: null,
            facebook_publishing: false,
        };
    }

    getValidators() {
        return {
            video_id: ValidationError.notEmpty,
            products: ValidationError.arrayNotEmpty,
            selmo_app_active: ValidationError.skip,
            selmo_app_publish_later: ValidationError.skip,
            facebook_active: ValidationError.skip,
            facebook_publish_later: ValidationError.skip,
            comments_disabled: ValidationError.skip,
            reserve_list_enabled: ValidationError.skip

        };
    }

    parseDTO(data) {
        return convertObjectProperties(
            data,
            {
                selmo_app_publish_date: (selmo_app_publish_date) => selmo_app_publish_date ? convertToUserTimeZone(selmo_app_publish_date).toDate() : convertToUserTimeZone().toDate(),
                facebook_publish_date: (facebook_publish_date) => facebook_publish_date ? convertToUserTimeZone(facebook_publish_date).toDate() : convertToUserTimeZone().toDate(),
                selmo_app_active: (selmo_app_active) => !data.products.length && !+selmo_app_active ? '1' : selmo_app_active,
            },
            {
                products: [],
                selmo_app_publish_date: convertToUserTimeZone().toDate(),
                facebook_publish_date: convertToUserTimeZone().toDate(),
                selmo_app_publish_time: !!+data.selmo_app_publish_later ? convertToUserTimeZone(data.selmo_app_publish_date).format('HH:mm') : convertToUserTimeZone().format('HH:mm'),
                facebook_publish_time: !!+data.facebook_publish_later ? convertToUserTimeZone(data.facebook_publish_date).format('HH:mm') : convertToUserTimeZone().format('HH:mm'),
                facebook_publishing: false,
            },
        );
    }

    buildDTO(data) {
        const preparedDateTime = (date, time) => {
            const preparedDate = moment(date).format('YYYY-MM-DD');
            return convertToWarsawTimeZone({date: preparedDate, time}).format('YYYY-MM-DD HH:mm');
        };

        return {
            video_id: data.video_id,
            products: data.products,
            selmo_app_active: data.selmo_app_active,
            selmo_app_description: data.selmo_app_description,
            selmo_app_publish_later: data.selmo_app_publish_later,
            selmo_app_publish_date: preparedDateTime(data.selmo_app_publish_date, data.selmo_app_publish_time),
            facebook_active: data.facebook_active,
            facebook_post_description: data.facebook_post_description,
            facebook_publish_later: data.facebook_publish_later,
            facebook_publish_date: preparedDateTime(data.facebook_publish_date, data.facebook_publish_time),
            comments_disabled: data.comments_disabled,
            reserve_list_enabled: data.reserve_list_enabled,
        }
    }
}

export default new SalesPostModel();
