import React, {useEffect, useState} from 'react';
import {Link, useLocation, useParams} from "react-router-dom";
import useFormLocal from "../../../../../../src_shared/hooks/useFormLocal";
import Model from "../../../../../../src_shared/modules/model/Model";
import {services} from "../../../../../RestServices";
import {getGETParamsUrl, parseParams} from "../../../../../../src_shared/list/ListActions";
import {RouterPaths} from "../../../../../routes/RouterPath";
import useLang from "../../../../../../src_shared/hooks/useLang";
import ServerImage from "../../../../shared/components/serverImage/ServerImage";
import Variants from "./components/variants/Variants";
import ComponentLoader from "../../../../shared/components/componentLoader/ComponentLoader";
import AssignToPlace from "../../assignToPlace/AssignToPlace";
import {useUnitPrice} from "../../../../shared/helpers/Price";
import ExpendituresModal from "./components/expendituresModal/ExpendituresModal";
import CustomDropdown from "../../../../shared/components/dropdown/CustomDropdown";

const ScannerCard = () => {
    const {id, variant_id} = useParams();
    const {getLangText} = useLang();
    const [selectedVariants, setSelectedVariants] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [showExpendituresModal, setShowExpendituresModal] = useState(false);
    const {getPrice} = useUnitPrice();
    const {search} = useLocation();
    const query = parseParams(search);
    const preparedParams = () => {
        const params = {}
        if (variant_id) {
            params.product_size_id = variant_id;
        }
        return getGETParamsUrl(params);
    }

    const rest = `${services.API_WAREHOUSE_PRODUCT}/${id}${preparedParams()}`;

    const [{isLoading, values, fetchData}] = useFormLocal({
        rest: rest,
        model: new Model(),
    });

    useEffect(() => {
        if (!!+values.has_sizes && variant_id) {
            setSelectedVariants([variant_id])
        }
    }, [variant_id, values.has_sizes])

    if (isLoading) {
        return <ComponentLoader/>
    }

    return (
        <>
            <div className="header-bar breadcrumbs-in-react-app">
                <Link
                    to={!!+query.fromShelf ? `${RouterPaths.Storage}/${values.shelf_id || values.size_shelf_id}` : RouterPaths.StorageScanner}
                    className="button back-to-panels mb-lg-3 mt-lg-0">
                    <i className="icon-arrow-left"/>
                    {getLangText('backButton')}
                </Link>
            </div>
            <div className="bg-[white] rounded-[5px] p-6 pb-0">
                <div>
                    <div className="flex items-center">
                        <figure className="product-avatar">
                            {values.photo_small && values.photo_small !== '0' ?
                                <ServerImage src={values.photo_small}/> :
                                <img src="/assets/images/default/product.svg"/>
                            }
                        </figure>
                        <div>
                            <div className="font-bold text-sm">
                                {values.name}
                            </div>
                            <div className="text-xs text-desc">
                                {variant_id &&
                                    <>
                                        {getLangText('variantLabel')}: <strong>{values.size_name}</strong>
                                        <span className="text-[8px] mx-1">{' • '}</span>
                                    </>
                                }
                                {getLangText('codeLabel')}: <strong>{values.selmo_code}</strong>
                            </div>
                        </div>
                        <div className="ml-auto font-bold text-sm">
                            {getPrice(values.price)}
                        </div>
                        <CustomDropdown
                            buttonClassName="rotate-[90deg] ml-[10px] top-[-4px]"
                            icon="icon-dots"
                            onClick={(e) => e.stopPropagation()}
                        >
                            <button
                                className="custom-option"
                                type="button"
                                onClick={() => setShowExpendituresModal(true)}
                            >
                                {getLangText('expenditureLabel')}
                            </button>
                        </CustomDropdown>
                    </div>
                    <div className="flex items-center justify-between">
                        {(values.shelf_name || values.size_shelf_name) &&
                            <div className="mt-2 text-sm">
                                <div className="font-bold">
                                    {getLangText('localizationLabel')}
                                </div>
                                <div>
                                    {values.shelf_name || values.size_shelf_name}
                                </div>
                            </div>
                        }
                        {!+values.has_sizes &&
                            <>
                                <div className="mt-2 text-sm text-right">
                                    <div className="font-bold">
                                        {getLangText('quantityLabel')}
                                    </div>
                                    <div>
                                        {values.squ}
                                    </div>
                                </div>
                                <div className="mt-2 text-sm text-right">
                                    <div className="font-bold">
                                        {getLangText('globalQuantityLabel')}
                                    </div>
                                    <div>
                                        {values.squ_warehouse}
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                </div>
                <div className="sm:mb-0 mb-[47px]">
                    <Variants
                        fetchData={fetchData}
                        values={values}
                        setSelectedVariants={setSelectedVariants}
                        selectedVariants={selectedVariants}
                    />
                </div>
                <div className="sm:sticky fixed left-0 right-0 bottom-0 sm:py-3 space-x-3 flex items-center bg-[white]">
                    <button
                        onClick={() => setShowModal(true)}
                        type="button"
                        disabled={(!!+values.has_sizes && !selectedVariants.length)}
                        className={`button primary w:sm-auto w-full assign-in-product ${(!!+values.has_sizes && !selectedVariants.length) ? 'not-active' : ''}`}
                    >
                        {getLangText('assignPlace')}
                    </button>
                    {/*<button*/}
                    {/*    onClick={() => setShowModal(true)}*/}
                    {/*    type="button"*/}
                    {/*    className="button rounded-[5px] text-xs h-[39px] px-2 font-bold bg-[#FEF2F2] grow text-[#EF4444]"*/}
                    {/*>*/}
                    {/*    {getLangText('removeFromShelfLabel')}*/}
                    {/*</button>*/}
                </div>
                {showModal &&
                    <AssignToPlace
                        selectedVariants={selectedVariants}
                        modalVisible={showModal}
                        hideModal={() => setShowModal(false)}
                        fetchData={fetchData}
                        setSelectedVariants={setSelectedVariants}
                    />
                }
                {showExpendituresModal &&
                    <ExpendituresModal
                        modalVisible={showExpendituresModal}
                        hideModal={() => setShowExpendituresModal(false)}
                        fetchData={fetchData}
                        product={values}
                    />
                }
            </div>
        </>
    );
};

export default ScannerCard;
