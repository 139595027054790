import React, {useMemo} from 'react';
import useLang from "../../../../../src_shared/hooks/useLang";
import {RouterPaths} from "../../../../routes/RouterPath";
import {Link} from "react-router-dom";
import Loader from "../../../../../src_shared/components/loader/Loader";

const steps = [
    {id: 1, title: 'salesPostFirstPage1', desc: 'salesPostFirstPage1Desc'},
    {id: 2, title: 'salesPostFirstPage2', desc: 'salesPostFirstPage2Desc'},
    {id: 3, title: 'salesPostFirstPage3', desc: 'salesPostFirstPage3Desc'},
];

const FirstStepPage = ({createPost, isWaitingForPost}) => {
    const {getLangText} = useLang();

    return (
        <div className="md:space-y-4">
            <div className="default-shadow-box bigger-y-padd">
                <div className="md:grid grid-cols-5 gap-10 md:px-20 md:py-20">
                    <div className="md:col-span-2 sm:mb-0 mb-4">
                        <img className="sm:w-[44px] mb-3" src="/assets/images/svg/posts.svg" alt="icon"/>
                        <div className="sm:text-3xl text-2xl font-bold">
                            {getLangText('salesPost')}
                        </div>
                        <div className="text-sm text-desc font-medium mt-3.5">
                            {getLangText('publishSalesVideosInSelmoApp')}
                        </div>
                        <button
                            onClick={createPost}
                            type="button"
                            className={`button primary mt-4 ${isWaitingForPost ? 'loading-button' : ''}`}
                        >
                            {isWaitingForPost && <Loader isLoading={true}/>}
                            {getLangText('addPost')}
                        </button>
                    </div>
                    <div className="col-span-3 md:pl-20">
                        <div className="bg-[#F6FCFF] rounded-xl border border-[rgba(95,165,250,0.2)] p-4">
                            <div className="font-bold">{getLangText('howPurchasesLookLikeInSelmoApp')}</div>
                            <img
                                className="rounded-[8px] mt-2 mb-2"
                                src="/assets/images/selmo-app-first-page.webp"
                                alt=""
                            />
                            <div className="space-y-4 mt-3 mb-4">
                                {steps?.map((i) => (
                                    <div
                                        key={i.id}
                                        className={`flex items-start`}
                                    >
                                        <div>
                                            <i className="icon-tick-c text-[18px] min-w-[18px] mr-2 text-[#5FA5FA]"/>
                                        </div>
                                        <div>
                                            <div
                                                className="text-[#101827] font-bold text-sm"
                                                dangerouslySetInnerHTML={{__html: getLangText(i.title)}}/>
                                            <div className="text-desc text-sm font-medium">{getLangText(i.desc)}</div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FirstStepPage;
